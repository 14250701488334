import { useState, useEffect } from 'react';
import { Box, Card, Stat, StatLabel, Image, StatNumber, StatHelpText, Text, Spinner } from '@chakra-ui/react';
import helpers from '../utils/helpers';

import {
  faPlay,
  faPercent,
  faEye,
  faHeart,
  faUserGroup,
  faVenusMars,
  faChartSimple,
  faEarthAmericas,
  faChildReaching,
  faCheckCircle,
  faBell,
  faAddressCard
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StatCard = (props) => {
  const { panel, colors = { primary: 'blue', secondary: 'green'}, mediaKitAgency, totalReach, primaryPlatform = {}, connection, totalCards, panelIndex } = props;
  const [icon, setIcon] = useState(faPlay);
  const [dataValue, setDataValue] = useState();
  const [helperKey, setHelperKey] = useState();
  const [helperValue, setHelperValue] = useState(0);
  const [label, setLabel] = useState('Loading...');

  const parseValue = (value) => {
    if (parseInt(value) >= 0) {
      return parseFloat(value).toLocaleString() || '0';
    } else {
      return '0';
    }
  };

  useEffect(() => {
    switch (panel?.label) {
      case 'agency':
        setLabel('Management');
        setIcon(faAddressCard);
        setDataValue(
          mediaKitAgency?.logo ? (
            <Image
              my="1"
              borderRadius="lg"
              boxSize="135px"
              height="80px"
              width="auto"
              objectFit="cover"
              src={mediaKitAgency?.logo}
            ></Image>
          ) : (
            mediaKitAgency.name
          )
        );
        // setHelperValue(parseInt(primaryPlatform?.stats[0]?.followers || primaryPlatform?.stats[0]?.subscribers));
        // setHelperKey(mediaKitAgency.name);
        break;
      case 'platform_primary':
        const platform = primaryPlatform?.platform || primaryPlatform?.name;
        setLabel('Primary Platform');
        setIcon(faCheckCircle);
        setDataValue(helpers.getSocialName(platform));
        if (primaryPlatform?.stats) {
          setHelperValue(parseInt(primaryPlatform?.stats[0]?.followers || primaryPlatform?.stats[0]?.subscribers));
          setHelperKey('followers');
        }
        break;
      case 'total_reach':
        setLabel('Total Reach');
        setIcon(faChildReaching);
        setDataValue(parseValue(totalReach));
        break;
      case 'impressions_28':
        setLabel('Impressions');
        setIcon(faEye);
        setDataValue(parseValue(connection?.insights?.impressions?.value));
        setHelperKey('Last 28 days');
        break;
      case 'reach_28':
        setLabel('Unique Reach');
        setIcon(faChildReaching);
        setDataValue(parseValue(connection?.insights?.reach?.value));
        setHelperKey('Last 28 days');
        break;
      case 'average_views':
        setLabel('Average Views');
        setIcon(faPlay);
        setDataValue(parseValue(connection?.stats[0]?.views));
        setHelperKey('Based on previous live streams');
        break;
      case 'total_views':
        setLabel('Total Views');
        setIcon(faPlay);
        setDataValue(parseValue(connection?.stats[0]?.views));
        break;
      case 'followers':
        setLabel('Followers');
        setIcon(faUserGroup);
        setDataValue(parseValue(connection?.stats[0]?.followers));
        break;
      case 'fans':
        setLabel('Fans');
        setIcon(faUserGroup);
        setDataValue(parseValue(connection?.stats[0]?.fans));
        break;
      case 'age_dist':
        setLabel('Age Distribution');
        setIcon(faChartSimple);
        break;
      case 'gender_dist':
        setLabel('Gender Distribution');
        setIcon(faVenusMars);
        break;
      case 'country_dist':
        setLabel('Country Distribution');
        setIcon(faEarthAmericas);
        break;
      case 'subscribers':
        setLabel('Subscribers');
        setIcon(faHeart);
        setDataValue(parseValue(connection?.stats[0]?.subscribers));
        break;
      case 'total_likes':
        setLabel('Likes');
        setIcon(faHeart);
        setDataValue(parseValue(connection?.stats[0]?.likes));
        break;
      case 'avg_engagement':
        setLabel('Avg Engagement');
        setIcon(faPercent);
        let sumContentStats = 0;
        connection?.content?.map((content) => {
          Object.keys(content?.stats).map((stat) => {
            sumContentStats += content?.stats[stat];
          });
        });

        let avgEngagement =
          (parseFloat(sumContentStats / connection?.content.length) /
            parseInt(connection?.stats[0]?.followers || connection?.stats[0]?.subscribers)) *
          100;

        if (isNaN(avgEngagement) || avgEngagement < 1) {
          avgEngagement = '<1';
        } else {
          avgEngagement = avgEngagement.toFixed(2);
        }
        setDataValue(avgEngagement + '%');
        setHelperKey('Based on last 4 posts');
        break;
      case 'total_posts':
        setLabel(panel.title || panel.label.replace('_', ' '));
        setIcon(faBell);
        setDataValue(connection?.stats[0]?.posts);
        break;
      default:
        setLabel(panel.title || panel.label.replace('_', ' '));
        break;
    }
  }, []);

  let panelWidth = '345px';
  if (
    totalCards === 1 ||
    totalCards === 2 ||
    totalCards === 4 ||
    (totalCards === 5 && panelIndex <= 2) ||
    (totalCards === 7 && panelIndex <= 4)
  ) {
    panelWidth = '525px';
  }

  return (
    <Card
      minH="125px"
      minW={panelWidth}
      width="100%"
      height="100%"
      as="span"
      p={4}
      color={`gray.1000`}
      position="relative"
    >
      <Stat>
        <StatLabel fontSize="md">{label}:</StatLabel>
        <StatNumber
          fontSize="3xl"
          color={`${colors.primary}.800`}
        >
          {label === 'Primary Platform' ? (
            <Box
              mr="2"
              as="span"
            >
              {helpers.getSocialIcon(dataValue?.toLowerCase())}
            </Box>
          ) : null}
          {dataValue === undefined ? <Spinner /> : dataValue}
        </StatNumber>
        {helperKey && (
          <StatHelpText>
            {Boolean(helperValue) && <Text as="span">{parseInt(helperValue).toLocaleString()}</Text>}
            <Text
              fontSize="xs"
              as="span"
              ml="1"
            >
              {helperKey}
            </Text>
          </StatHelpText>
        )}
      </Stat>
      {icon && (
        <Box
          position="absolute"
          color={`${colors.primary}.300`}
          top="3"
          right="5"
          fontSize="xl"
        >
          <FontAwesomeIcon icon={icon} />
        </Box>
      )}
    </Card>
  );
};

export default StatCard;
