import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { Box, Card, Stat, StatLabel, StatNumber, StatHelpText, Text, useTheme } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import helpers from '../utils/helpers';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip);

const LineChartMultiCard = (props) => {
  const {
    label = 'Loading...',
    helperLabel,
    dataSet = [],
    labels = [],
    colors = { primary: 'blue', secondary: 'red' }
  } = props;

  const [icon, setIcon] = useState(props.icon || faPlay);
  const [labelValues, setLabelValues] = useState([]);
  const [dataValues, setDataValues] = useState([]);
  const [chartData, setChartData] = useState({});
  const theme = useTheme();

  useEffect(() => {
    let datasets = [];
    dataSet.map((set, i) => {
      datasets.push({
        label: set.label,
        data: set.data,
        fill: false,
        borderColor: theme.colors.platform[set.platform],
        backgroundColor: theme.colors.platform[set.platform],
        yAxisID: 'y'
      });
    });
    setDataValues(datasets);
  }, []);

  return (
    <Card
      width="100%"
      height="100%"
      as="span"
      p={4}
      color={`gray.1000`}
      position="relative"
    >
      <Stat>
        <StatLabel mb="3">{label}</StatLabel>
        <StatNumber
          fontSize="3xl"
          color={`${colors.primary}.800`}
        >
          <Line
            height="320px"
            data={{
              labels: labels,
              datasets: dataValues
            }}
            options={{
              interaction: {
                mode: 'index',
                intersect: false
              },
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                y: {
                  ticks: {
                    precision: 0
                  }
                }
              },
              plugins: {
                legend: {
                  position: 'top',
                  display: true
                }
              }
            }}
          />
        </StatNumber>
        {helperLabel && (
          <StatHelpText>
            <Text
              fontSize="xs"
              as="span"
              ml="1"
            >
              {helperLabel}
            </Text>
          </StatHelpText>
        )}
      </Stat>
      {icon && (
        <Box
          position="absolute"
          color={`${colors.primary}.300`}
          top="3"
          right="5"
          fontSize="xl"
        >
          <FontAwesomeIcon icon={icon} />
        </Box>
      )}
    </Card>
  );
};

export default LineChartMultiCard;
