import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Center, Text } from '@chakra-ui/react';
import { useAppContext } from '../../hooks/useAppContext';
import Loader from '../../components/Loader';
import DashboardLayout from '../../containers/DashboardLayout';
import PageHeader from '../../containers/PageHeader';
import { getUserList } from '../../api/user';
import SortedTable from '../../components/SortingTable';
import helpers from '../../utils/helpers';

const AdminUsers = () => {
  const { user } = useAppContext();
  const isAdmin = user?.isMediasweetAdmin || false;
  const [userList, setUserList] = useState([]);
  const [tableHeaders, setTableHeaders] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!userList.length) {
      getUserList().then((users) => {
        users = users.map((user) => {
          return {
            actions: (
              <Link to={`/admin/users/${user._id}`}>
                <Text as="u">Edit</Text>
              </Link>
            ),
            name: user.name,
            email: user.email,
            admin: String(user.isMediasweetAdmin),
            agency: user.agencyId ? (
              <Link to={`/admin/agencies/${user.agencyId}`}>
                <Text as="u">{String(user.agencyId)}</Text>
              </Link>
            ) : (
              <></>
            ),
            createdAt: user.createdAt ? helpers.formatDate(user.createdAt) : ''
          };
        });

        setUserList(users);
        setIsLoading(false);
      });
    }
  }, []);

  return (
    <DashboardLayout page="users">
      <PageHeader title="Users" />
      <Loader
        text="Loading users..."
        isLoaded={!isLoading && userList?.length}
      >
        <SortedTable rows={userList} />
      </Loader>
    </DashboardLayout>
  );
};

export default AdminUsers;
