import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from '../../hooks/useAppContext';
import { useToastHook } from '../../components/Toast';
import { invite, getPublisherInvites, getAllAgencyInvites, getAgencyList, deleteAgencyInvite } from '../../api/agency';
import helpers from '../../utils/helpers';
import DashboardLayout from '../../containers/DashboardLayout';
import PageHeader from '../../containers/PageHeader';
import Loader from '../../components/Loader';
import SortedTable from '../../components/SortingTable';

const Invites = () => {
  const { user, userAgency } = useAppContext();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  const [emailError, setEmailError] = useState('');
  const [nameError, setNameError] = useState('');

  const [existingInvites, setExistingInvites] = useState([]);

  const [agencyId, setAgencyId] = useState('');
  const [agencyList, setAgencyList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [state, newToast] = useToastHook();

  const [tableData, setTableData] = useState([]);

  const isAdmin = user?.isMediasweetAdmin;
  let isSubmitDisabled = true;

  if ((!isAdmin && name && email) || (isAdmin && name && email && agencyId)) {
    isSubmitDisabled = false;
  } else {
    isSubmitDisabled = true;
  }

  useEffect(() => {
    fetchData();
  }, [userAgency, user]);

  const fetchData = () => {
    if (isAdmin) {
      getAgencyList().then((agencys) => {
        setAgencyList(agencys);
      });
      getAllAgencyInvites().then((invites) => {
        setExistingInvites(invites);
      });
    } else if (userAgency?._id) {
      getPublisherInvites(userAgency?._id).then((invites) => {
        setExistingInvites(invites);
      });
    }
  };

  useEffect(() => {
    const invites = existingInvites.map((row) => {
      return {
        name: row.name,
        email: row.email,
        status: row.status,
        created: helpers.formatDate(row.createdAt),
        'last updated': helpers.formatDate(row.updatedAt),
        delete: (
          <Button
            onClick={() => deleteInvite(row._id)}
            border={'1px'}
            bg="gray.100"
            color="blue.800"
            boxShadow="base"
          >
            Delete
          </Button>
        )
      };
    });
    setTableData(invites);
  }, [existingInvites]);

  const selectDropdown = (agencyId) => {
    if (agencyId) {
      setAgencyId(agencyId);
      if (email && name) {
        isSubmitDisabled = false;
      }
    }
  };

  const submitForm = async () => {
    setIsLoading(true);

    if (!email) {
      setEmailError("Email can't be blank");
    }

    if (!name) {
      setNameError("Name can't be blank");
    }

    const inviteResult = await invite(email, name, agencyId);
    if (inviteResult?.success) {
      setTimeout(fetchData(), 100);
      newToast({
        status: 'success',
        message: `Invite to ${email} sent!`
      });
      setEmail('');
      setName('');
    } else {
      newToast({
        status: 'error',
        message: 'Error occurred sending email!'
      });
    }
    setIsLoading(false);
  };

  const deleteInvite = async (agencyInviteId) => {
    await deleteAgencyInvite(agencyInviteId);
    setTimeout(fetchData(), 100);
  };

  return (
    <DashboardLayout page="invites">
      <PageHeader title="Invites" />

      <Loader
        text="Loading Invites..."
        isLoaded={!isLoading && tableData.length}
      >
        <SortedTable rows={tableData} />
      </Loader>

      <Menu>
        <MenuButton
          padding="5"
          size="sm"
          as={Button}
          bg="gray.100"
          color="gray.1000"
        >
          Agencies&nbsp;&nbsp;&nbsp;&nbsp;
          <FontAwesomeIcon
            icon={faChevronDown}
            size="sm"
          />
        </MenuButton>
        <MenuList>
          {agencyList.map((agency) => {
            return (
              <MenuItem
                onClick={() => selectDropdown(agency._id)}
                value={agency.name}
              >
                {agency.name}
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
      <Box>{`Agency Selected: ${agencyId}`}</Box>
      <Box mb={8}>
        Name:
        <FormControl isInvalid={nameError}>
          <InputGroup width={'md'}>
            <Input
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="name"
            />
          </InputGroup>
          <FormErrorMessage
            float="right"
            mt={0}
          >
            {nameError}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Box mb={8}>
        Email:
        <FormControl isInvalid={emailError}>
          <InputGroup width={'md'}>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
            />
          </InputGroup>
          <FormErrorMessage
            float="right"
            mt={0}
          >
            {emailError}
          </FormErrorMessage>
        </FormControl>
      </Box>
      <Box mt={6}>
        <Button
          isLoading={isLoading}
          onClick={submitForm}
          background="blue.500"
          color="white"
          isDisabled={isSubmitDisabled}
        >
          Submit
        </Button>
      </Box>
    </DashboardLayout>
  );
};

export default Invites;
