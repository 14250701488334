import { Center, Box, Button, Text } from '@chakra-ui/react';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditModeFooter = (props) => {
  const { editMode, hasChanged, setEditMode, setHasChanged } = props;

  return (
    <Center
      zIndex="20"
      position="relative"
    >
      <Box
        top="0px"
        position="fixed"
      >
        {!editMode && (
          <Button
            color="green.900"
            px="2rem"
            py=".5rem"
            boxShadow="base"
            bg="green.100"
            borderRadius="0 0 20px 20px"
            onClick={() => setEditMode(true)}
          >
            <Text
              mr="2"
              as="span"
            >
              Switch to edit mode{' '}
            </Text>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        )}
        {editMode && (
          <Button
            color="yellow.900"
            px="2rem"
            py=".5rem"
            bg="yellow.100"
            boxShadow="base"
            borderRadius="0 0 20px 20px"
            onClick={() => {
              setHasChanged(false);
              setEditMode(false);
            }}
          >
            <Text
              mr="2"
              as="span"
            >
              {hasChanged ? 'Discard Changes' : 'Back to View Mode'}
            </Text>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        )}
      </Box>
    </Center>
  );
};

export default EditModeFooter;
