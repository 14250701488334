import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { loginAttempt, getMe } from '../api/user';
import { getAgencyById } from '../api/agency';
import { getProductById } from '../api/product';
import { getAgencyConnections } from '../api/connection';

import { useAppContext } from '../hooks/useAppContext';

import {
  Card,
  Container,
  Box,
  Button,
  Divider,
  Image,
  FormControl,
  FormErrorMessage,
  Stack,
  Text,
  Input,
  Heading
} from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import logoPng from '../assets/logos/media-sweet-logo.png';

const Login = () => {
  const navigate = useNavigate();
  const { user, setUser, userAgency, setUserAgency, setUserAgencyProduct, setUserAgencyConnections } = useAppContext();

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginEmailAddress, setLoginEmailAddress] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [emailError, setEmailError] = useState(undefined);
  const [passwordError, setPasswordError] = useState(undefined);
  const [loginError, setLoginError] = useState(undefined);

  const checkLogin = (e) => {
    e.preventDefault();
    setLoginLoading(true);
    setLoginError(undefined);
    setEmailError(undefined);
    setPasswordError(undefined);

    let error = false;
    if (!loginEmailAddress) {
      setEmailError('Email address can not be empty');
      error = true;
    } else if (!loginEmailAddress.includes('@')) {
      setEmailError('Email address must contain an "@"');
      error = true;
    }

    if (!loginPassword) {
      setPasswordError('Password can not be empty');
      error = true;
    }

    if (!error) {
      loginAttempt(loginEmailAddress, loginPassword)
        .then(async (login) => {
          if (login) {
            const user = await getMe(login.token);
            localStorage.setItem('jwt', login.token);

            if (user) {
              setUser(user);

              if (user?.agencyId) {
                const userAgency = await getAgencyById(user.agencyId);
                if (userAgency) {
                  setUserAgency(userAgency);
                  if (userAgency.product) {
                    const product = await getProductById(userAgency.product);
                    setUserAgencyProduct(product);
                  }
                  const agencyConnections = await getAgencyConnections(user.agencyId);
                  setUserAgencyConnections(agencyConnections);
                }
              }

              navigate('/', { replace: true });
            } else {
              setLoginError('User not found');
            }
          } else {
            // bad username/password combo results in 404 from backend
            setLoginError('Bad username/password combo');
          }
        })
        .catch((err) => {
          setLoginError('There was an error logging in. Please try again.');
        })
        .finally(() => setLoginLoading(false));
    } else {
      setLoginLoading(false);
    }
  };

  useEffect(() => {
    if (user?._id) {
      navigate('/', { replace: true });
    }
  });

  return (
    <Container mt="50">
      <Card style={{ textAlign: 'center' }}>
        <Box padding="4">
          <Image
            src={logoPng}
            m="15"
            objectFit="cover"
            width="400px"
            display="inline-block"
          />
          {/*<Heading
            as="h2"
            size="md"
            mb={7}
          >
            <Text>
              a <Text as="i">suite</Text> of tools for the{' '}
              <Text
                color="blue.500"
                as="span"
              >
                creator economy
              </Text>
            </Text>
          </Heading> */}
          <Container mb="4">
            <Divider />

            <Box my="2">
              <Text>Don't have an account?</Text>
              <Text fontSize="sm">
                Get started at{' '}
                <Link to="http://trymediasweet.com/">
                  <Text
                    as="span"
                    color="blue.500"
                  >
                    trymediasweet.com
                  </Text>
                </Link>
              </Text>
            </Box>

            <Divider />
          </Container>

          <Container maxW="sm">
            <Stack
              direction="column"
              spacing={2}
              align="center"
            >
              <form>
                <FormControl
                  isInvalid={typeof emailError !== 'undefined' || typeof loginError !== 'undefined'}
                  mt={0}
                >
                  <FormErrorMessage
                    fontSize="sm"
                    color="red.500"
                    mt={0}
                  >
                    {emailError || loginError}
                  </FormErrorMessage>
                  <Input
                    id="email"
                    placeholder="Email Address"
                    variant="outline"
                    type="email"
                    mb={2}
                    onChange={(e) => setLoginEmailAddress(e.target.value)}
                  />
                </FormControl>

                <FormControl isInvalid={typeof passwordError !== 'undefined' || typeof loginError !== 'undefined'}>
                  <FormErrorMessage
                    fontSize="sm"
                    color="red.500"
                    mt={0}
                  >
                    {passwordError}
                  </FormErrorMessage>
                  <Input
                    id="password"
                    placeholder="Password"
                    type="password"
                    variant="outline"
                    mb={2}
                    onChange={(e) => setLoginPassword(e.target.value)}
                  />
                </FormControl>

                <Box>
                  <Button
                    colorScheme="blue"
                    my={2}
                    type="submit"
                    isDisabled={loginLoading}
                    isLoading={loginLoading}
                    onClick={(evt) => checkLogin(evt)}
                  >
                    <FontAwesomeIcon icon={faKey} />
                    {/* <Text ml={2}>Sign in with email and password</Text> */}
                    <Text ml={2}>Log in</Text>
                  </Button>
                </Box>
              </form>

              <Divider />

              {/*
              <Button
                bg="platform.twitter"
                color="white"
              >
                <FontAwesomeIcon icon={faTwitter} />
                <Text ml={2}>Sign in with Twitter</Text>
              </Button>

              <Button
                bg="platform.facebook"
                color="white"
              >
                <FontAwesomeIcon icon={faFacebook} />
                <Text ml={2}>Sign in with Facebook</Text>
              </Button>

              <Button
                bg="platform.youtube"
                color="white"
              >
                <FontAwesomeIcon icon={faYoutube} />
                <Text ml={2}>Sign in with YouTube</Text>
              </Button>

              <Button
                bg="platform.tiktok"
                color="white"
              >
                <FontAwesomeIcon icon={faTiktok} />
                <Text ml={2}>Sign in with TikTok</Text>
              </Button>

              <Button
                bg="platform.twitch"
                color="white"
              >
                <FontAwesomeIcon icon={faTwitch} />
                <Text ml={2}>Sign in with Twitch</Text>
              </Button>

              */}
            </Stack>
            <Text
              mt={5}
              fontSize="xs"
            >
              By using <b>Media Sweet Inc</b> products you agree to our <br />
              <Link
                to="http://trymediasweet.com/termsofservice.php"
                target="_blank"
              >
                <Text
                  as="span"
                  color="blue.500"
                >
                  Terms of Service
                </Text>
              </Link>
              &nbsp;and our{' '}
              <Link
                to="http://trymediasweet.com/privacypolicy.php"
                target="_blank"
              >
                <Text
                  as="span"
                  color="blue.500"
                >
                  Privacy Policy
                </Text>
              </Link>
            </Text>
          </Container>
        </Box>
      </Card>
    </Container>
  );
};

export default Login;
