import { useState, useEffect } from 'react';
import { useAppContext } from '../hooks/useAppContext';
import { Box, Button, Divider, Center, HStack, Text, Heading } from '@chakra-ui/react';
import ConnectionsTable from '../containers/ConnectionsTableContainer';
import CreateNewConnectionButtons from '../containers/CreateNewConnectionButtons';
import { fetchConnections } from '../actions/connections';
import PlatformsCreateRequestModal from '../containers/PlatformsCreateRequestModal';
import DashboardLayout from '../containers/DashboardLayout';
import PageHeader from '../containers/PageHeader';
import Loader from '../components/Loader';
import { useToastHook } from '../components/Toast';

const Connections = () => {
  const [_, newToast] = useToastHook();
  const { user, userAgency, userAgencyConnections, setUserAgencyConnections } = useAppContext();
  const agencyId = userAgency?._id || false;
  const [isPlatformRequestModalOpen, setIsPlatformRequestModalOpen] = useState();

  const [loadingConnections, setLoadingConnections] = useState(true);
  const [connectionList, setConnectionList] = useState([]);

  useEffect(() => {
    setLoadingConnections(true);
    if (userAgencyConnections) {
      setConnectionList(userAgencyConnections);
      setLoadingConnections(false);
    } else {
      getConnections({ userAgency });
    }
  }, [userAgencyConnections, userAgency]);

  const getConnections = ({ userAgency }) => {
    fetchConnections({ userAgency })
      .then((connections) => {
        setUserAgencyConnections([...connections]);
        setConnectionList([...connections]);
      })
      .catch((err) => {
        newToast({
          status: 'error',
          message: 'Something went wrong fetching connections',
          duration: 5000
        });
      });
  };

  return (
    <DashboardLayout page="connections">
      <PageHeader
        title="Connections"
        body={
          <Box>
            <Box my={3}>
              <Text as="b">
                These are the social media connections you have access to. Media Sweet interfaces directly with
                providers to ensure accurate metrics. To accurately obtain access to your talent's social media
                connections and stats, they <Text as="u">must</Text> provide you access.
              </Text>
            </Box>
            <Box>
              <Button
                onClick={() => setIsPlatformRequestModalOpen(true)}
                variant="solid"
                colorScheme="blue"
                mb="2"
              >
                Request talent connections
              </Button>
            </Box>
          </Box>
        }
      />

      <Loader
        height="auto"
        text="Loading connections..."
        isLoaded={!loadingConnections}
      >
        <ConnectionsTable
          connections={connectionList}
          connectionsLength={connectionList.length}
        />

        <Divider />

        <Center>
          <Heading
            mt="28px"
            mb="14px"
            fontSize={'xl'}
          >
            Already logged in? Connect an account:
          </Heading>
        </Center>

        <CreateNewConnectionButtons
          userAgency={userAgency}
          fetchConnections={getConnections}
        />
      </Loader>

      <PlatformsCreateRequestModal
        isOpen={isPlatformRequestModalOpen}
        onClose={() => setIsPlatformRequestModalOpen(false)}
      />
    </DashboardLayout>
  );
};

export default Connections;
