// import { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  HStack,
  Checkbox,
  CheckboxGroup,
  SimpleGrid,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Center,
  Text,
  Tooltip,
  GridItem
} from '@chakra-ui/react';
import { CONSTANTS } from '../constants';
import helpers from '../utils/helpers';

const MediaKitSectionDataPanels = (props) => {
  const {
    sectionPanels,
    setSectionPanels,
    hasChanged,
    setHasChanged,
    pageDisplay,
    setPageDisplay,
    section,
    colors,
    order,
    removeConnection
  } = props;

  const toggleDataPanel = (idx, panel, change, eIdx) => {
    if (!hasChanged) {
      setHasChanged(true);
    }
    if (!change) {
      sectionPanels.splice(eIdx, 1, {});
    } else {
      sectionPanels.splice(eIdx, 1, panel);
    }
    pageDisplay.secondary[idx].panels = sectionPanels;
    setSectionPanels([...sectionPanels]);
  };

  return (
    <Box zIndex="2">
      <Popover
        onClose={() => {
          setPageDisplay({ ...pageDisplay });
        }}
      >
        <PopoverTrigger>
          <Button boxShadow="base">
            <Tooltip label="Click to edit data panels">
              <Heading fontSize="xl">
                <HStack>
                  <Box>{helpers.getSocialName(section?.platform)}</Box>{' '}
                  <Box height="23px">{helpers.getSocialIcon(section?.platform)}</Box>
                </HStack>
              </Heading>
            </Tooltip>
          </Button>
        </PopoverTrigger>
        <PopoverContent width="2xl">
          <PopoverArrow />
          <PopoverBody>
            <Box>
              <Center>
                <Text
                  as="b"
                  as="u"
                  fontSize="md"
                  my="3"
                >
                  Add or remove data panels
                </Text>
              </Center>
              <SimpleGrid
                columns="3"
                spacing="1"
                mx="auto"
                width="fit-content"
              >
                <CheckboxGroup defaultValue={sectionPanels.map((existing) => existing.label)}>
                  {CONSTANTS.PLATFORM_PANELS[section?.platform]?.panels.map((panel, idx) => {
                    return (
                      <GridItem key={idx}>
                        <Checkbox
                          key={`${panel.platform}-${panel.label}`}
                          value={panel.label}
                          iconColor={`${colors.secondary}.100`}
                          iconSize="1rem"
                          mx="2"
                          onChange={(e) => {
                            toggleDataPanel(order, panel, e.target.checked, idx);
                          }}
                        >
                          <Text fontSize="sm">{panel.title}</Text>
                        </Checkbox>
                      </GridItem>
                    );
                  })}
                </CheckboxGroup>
              </SimpleGrid>
              <Center mt="2">
                <Button
                  bg="transparent"
                  mx="auto"
                  mt="3"
                  mb="1"
                  color="red.500"
                  fontSize="sm"
                  height="25px"
                  onClick={() => removeConnection(section.connectionId)}
                >
                  Remove {helpers.getSocialName(section?.platform)} from media kit
                </Button>
              </Center>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export default MediaKitSectionDataPanels;
