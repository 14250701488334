import { CONSTANTS } from '../constants';

/**
 * @param {string} url -
 * @param {string} token -
 */
export const get = async (url, token) => {
  return new Promise((resolve, reject) => {
    fetch(`${CONSTANTS.REACT_APP_API_BASE_URL}${url}`, {
      method: 'GET',
      cache: 'no-cache',
      credentials: 'same-origin',
      referrerPolicy: 'no-referrer',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        if (response?.result) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @param {string} url -
 * @param {object} data -
 * @param {string} token -
 */
export const post = async (url, data, token) => {
  return new Promise((resolve, reject) => {
    fetch(`${CONSTANTS.REACT_APP_API_BASE_URL}${url}`, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'same-origin',
      referrerPolicy: 'no-referrer',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.result) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @param {string} url -
 * @param {object} data -
 * @param {string} token -
 */
export const patch = async (url, data, token) => {
  return new Promise((resolve, reject) => {
    fetch(`${CONSTANTS.REACT_APP_API_BASE_URL}${url}`, {
      method: 'PATCH',
      cache: 'no-cache',
      credentials: 'same-origin',
      referrerPolicy: 'no-referrer',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(data)
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.result) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @param {string} url -
 * @param {string} token -
 */
export const remove = async (url, token) => {
  return new Promise((resolve, reject) => {
    fetch(`${CONSTANTS.REACT_APP_API_BASE_URL}${url}`, {
      method: 'DELETE',
      cache: 'no-cache',
      credentials: 'same-origin',
      referrerPolicy: 'no-referrer',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.result) {
          resolve(response.data);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
