import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Button,
  Center,
  Container,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Switch
} from '@chakra-ui/react';
import DashboardLayout from '../../containers/DashboardLayout';
import PageHeader from '../../containers/PageHeader';
import { useAppContext } from '../../hooks/useAppContext';
import { update } from '../../api/agency';
import { getUploadUrl } from '../../api/user';
import { uploadS3Image } from '../../api/s3';
import { useToastHook } from '../../components/Toast';
import { getProductById, updateProduct } from '../../api/product';

const EditProduct = () => {
  const [state, newToast] = useToastHook();
  const { user, setUserProduct } = useAppContext();
  const isAdmin = user?.isMediasweetAdmin;
  const productId = window.location.pathname.split('/')[3];

  const [editProduct, setEditProduct] = useState('');
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productMediaKits, setProductMediaKits] = useState(0);
  const [productMembers, setProductMembers] = useState(0);
  const [productBranding, setProductBranding] = useState(false);
  const [productCohorts, setProductCohorts] = useState(5);

  const [productNameErr, setProductNameErr] = useState('');
  const [productDescriptionErr, setProductDescriptionErr] = useState('');
  const [productMediaKitsErr, setProductMediaKitsErr] = useState('');
  const [productMembersErr, setProductMembersErr] = useState('');
  const [productBrandingErr, setProductBrandingErr] = useState('');
  const [productCohortsErr, setProductCohortsErr] = useState('');

  const [productFormErr, setProductFormErr] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getProductById(productId).then((product) => {
      setEditProduct(product);
      setProductName(product.name);
      setProductDescription(product.description);
      setProductMediaKits(product.mediakits);
      setProductMembers(product.members);
      setProductBranding(product.branding);
      setProductCohorts(product.cohorts);
    });
  }, []);

  const submitProduct = async () => {
    setIsLoading(true);
    let error = '';
    if (!productName || productName == '') {
      setProductFormErr('An error occurred');
      setProductNameErr("Name can't be empty");
      setIsLoading(false);
    } else {
      const updateObject = {
        name: productName,
        description: productDescription,
        mediakits: productMediaKits,
        members: productMembers,
        branding: productBranding,
        cohorts: productCohorts
      };

      const product = await updateProduct(productId, updateObject);
      if (product) {
        setEditProduct(product);
        setProductName(product.name);
        setProductDescription(product.description);
        setProductMediaKits(product.mediakits);
        setProductMembers(product.members);
        setProductBranding(product.branding);
        setProductCohorts(product.cohorts);
        newToast({
          status: 'success',
          message: 'Product details have been saved'
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout page="products">
      <PageHeader
        title="Products"
        body={
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/admin/products">
                <Text
                  color="blue.500"
                  as="u"
                >
                  products
                </Text>
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <Link
                color="blue.500"
                to="#"
              >
                <Text
                  color="blue.500"
                  as="u"
                >
                  {productId}
                </Text>
              </Link>
            </BreadcrumbItem>
          </Breadcrumb>
        }
      />

      <Box maxW="650px">
        <Box mb={8}>
          {productFormErr && <Text color="red.500">{productFormErr}</Text>}
          <FormControl
            mt={4}
            isInvalid={productNameErr}
          >
            <FormLabel>Name</FormLabel>
            <Input
              value={productName}
              onChange={(e) => {
                setProductNameErr('');
                setProductName(e.target.value);
              }}
              placeholder="Company Name"
            />
            {productNameErr && <FormErrorMessage>{productNameErr}</FormErrorMessage>}
          </FormControl>

          <FormControl
            mt={4}
            isInvalid={productDescriptionErr}
          >
            <FormLabel>Description</FormLabel>
            <Input
              value={productDescription}
              onChange={(e) => {
                setProductDescriptionErr('');
                setProductDescription(e.target.value);
              }}
              placeholder="Description"
            />
            {productDescriptionErr && <FormErrorMessage>{productDescriptionErr}</FormErrorMessage>}
          </FormControl>

          <FormControl
            mt={4}
            isInvalid={productMediaKitsErr}
          >
            <FormLabel>
              MediaKits{' '}
              <Text
                fontSize="xs"
                as="i"
              >
                (max)
              </Text>
            </FormLabel>
            <NumberInput
              step={1}
              defaultValue={5}
              min={1}
              value={productMediaKits}
              onChange={(e) => {
                setProductMediaKitsErr();
                setProductMediaKits(e);
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {productMediaKitsErr && <FormErrorMessage>{productMediaKitsErr}</FormErrorMessage>}
          </FormControl>
          <FormControl
            mt={4}
            isInvalid={productMembersErr}
          >
            <FormLabel>
              Members
              <Text
                fontSize="xs"
                as="i"
              >
                (max)
              </Text>
            </FormLabel>
            <NumberInput
              step={1}
              defaultValue={5}
              min={1}
              value={productMembers}
              onChange={(e) => {
                setProductMembersErr('');
                setProductMembers(e);
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {productMembersErr && <FormErrorMessage>{productMembersErr}</FormErrorMessage>}
          </FormControl>
          <FormControl
            mt={4}
            isInvalid={productBrandingErr}
          >
            <FormLabel
              htmlFor="product-branding"
              mb="0"
            >
              Branding enabled
            </FormLabel>

            <Switch
              id="product-branding"
              isChecked={productBranding}
              onChange={(e) => {
                setProductBranding(!productBranding);
              }}
            />

            {productBrandingErr && <FormErrorMessage>{productBrandingErr}</FormErrorMessage>}
          </FormControl>
          <FormControl
            mt={4}
            isInvalid={productCohortsErr}
          >
            <FormLabel>
              Cohorts (lists)
              <Text
                fontSize="xs"
                as="i"
              >
                (max)
              </Text>
            </FormLabel>
            <NumberInput
              step={1}
              defaultValue={productCohorts}
              value={productCohorts}
              min={1}
              onChange={(e) => {
                setProductCohortsErr('');
                setProductCohorts(e);
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {productCohortsErr && <FormErrorMessage>{productCohortsErr}</FormErrorMessage>}
          </FormControl>
        </Box>

        <Box mt={6}>
          <Button
            isDisabled={
              productName === editProduct.name &&
              productDescription === editProduct.description &&
              productMediaKits === editProduct.mediakits &&
              productMembers === editProduct.members &&
              productCohorts === editProduct.cohorts &&
              productBranding === editProduct.branding
            }
            isLoading={isLoading}
            onClick={submitProduct}
            background="blue.500"
            color="white"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default EditProduct;
