import { Box, Center, Grid, GridItem, Image, Spinner, Text } from '@chakra-ui/react';
import logoPng from '../assets/logos/mediasweet_logo2.png';
import Header from '../containers/Header';

const VerifyOauth = (props) => {
  const { title, error } = props;
  return (
    <Center>
      <Box
        borderRadius="lg"
        maxW="8xl"
        minW="60rem"
        bg="white"
        m="4"
      >
        <Grid
          templateAreas={`"header header""main main""footer footer"`}
          gridTemplateRows={'75px 1fr 30px'}
          gridTemplateColumns={'250px 1fr'}
          gap="0"
          m="4"
          fontWeight="bold"
        >
          <Header />

          <GridItem
            pl="2"
            bg="brand.white"
            area={'main'}
          >
            <Center>
              <Box mb={3}>
                <Text
                  textAlign={['left']}
                  fontSize={'3xl'}
                >
                  {title}
                </Text>
              </Box>
            </Center>

            <Center>
              <Box>
                {error ? (
                  <Text>{error}</Text>
                ) : (
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                  />
                )}
              </Box>
            </Center>
          </GridItem>
        </Grid>
      </Box>
    </Center>
  );
};

export default VerifyOauth;
