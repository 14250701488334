import { get, post, patch, remove } from '../utils/HttpsHandlers';

export const getAgencyById = (id) => {
  const token = localStorage.getItem('jwt');
  return get(`/agency?id=${id}`, token);
};

export const getAgencyBySlug = (slug) => {
  return get(`/agency?slug=${slug}`);
};

export const getAgencyByToken = (token) => {
  return get(`/agency?token=${token}`);
};

export const update = (id, body) => {
  return patch(`/agency/${id}`, body, localStorage.getItem('jwt'));
};

export const getAgencyList = () => {
  const token = localStorage.getItem('jwt');
  return get(`/agency`, token);
};

export const createAgency = (newAgency) => {
  const token = localStorage.getItem('jwt');
  return post(`/agency`, newAgency, token);
};

export const invite = (email, name, agencyId) => {
  const token = localStorage.getItem('jwt');
  const url = agencyId ? `/agency/invite?agencyId=${agencyId}` : `/agency/invite`;
  return post(url, { email, name }, token);
};

export const getPublisherInvites = (agencyId) => {
  const token = localStorage.getItem('jwt');
  return get(`/agency/invite/${agencyId}`, token);
};

export const getAllAgencyInvites = () => {
  const token = localStorage.getItem('jwt');
  return get(`/agency/invite`, token);
};

export const deleteAgencyInvite = (agencyInviteId) => {
  return remove(`/agency/invite/${agencyInviteId}`, localStorage.getItem('jwt'));
};
