import { Center, Box, Image, Tooltip, Text, Heading, Divider, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../hooks/useAppContext';
import { useIsNavOpen } from '../hooks/navOpen';
import Footer from './Footer';
import logoPng from '../assets/logos/media-sweet-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faIdCard,
  faPaintBrush,
  faCog,
  faAddressBook,
  faExternalLink,
  faFileCode,
  faLineChart,
  faUsd,
  faUserCircle,
  faEnvelopeOpen,
  faCaretSquareLeft,
  faCaretSquareRight
} from '@fortawesome/free-solid-svg-icons';

const Nav = (props) => {
  const { user, userAgency } = useAppContext();
  const { isNavOpen, toggleNav } = useIsNavOpen();
  const isAdmin = user?.isMediasweetAdmin;
  const { page } = props;

  const adminLinks = [
    {
      to: '/admin/agencies',
      text: 'Agencies',
      page: 'agencies',
      icon: faAddressBook
    },
    {
      to: '/admin/products',
      text: 'Products',
      page: 'products',
      icon: faUsd
    },
    {
      to: '/admin/users',
      text: 'Users',
      page: 'users',
      icon: faUserCircle
    },
    {
      to: '/admin/invites',
      text: 'Invites',
      page: 'invites',
      icon: faEnvelopeOpen
    },
    {
      to: '/admin/connections',
      text: 'Connections',
      page: 'connections',
      icon: faExternalLink
    },
    {
      to: '/admin/products/email',
      text: 'Email Templates',
      page: 'templates',
      icon: faFileCode
    }
  ];

  let navHeading = userAgency?.name || user?.email?.split('.')[0] || 'Account';
  if (isAdmin) {
    navHeading = 'Admin';
  }

  const navContent = [
    {
      heading: navHeading,
      links: [
        {
          to: '/account',
          text: 'Settings',
          page: 'account',
          icon: faCog
        }
      ]
    },
    {
      heading: isAdmin ? 'Entities' : 'Products',
      links: isAdmin
        ? adminLinks
        : [
            {
              to: '/',
              text: 'Media Kits',
              icon: faIdCard,
              page: 'mediakits'
            },
            {
              to: '/connections',
              text: 'Connections',
              page: 'connections',
              icon: faUserCircle
            },
            {
              to: '/reports',
              text: 'Reports',
              page: 'reports',
              icon: faLineChart
            }
          ]
    }
    /*
    {
      heading: 'Contact',
      links: [
        {
          to: '/connections',
          text: 'Support',
          page: 'contact',
          icon: faHandshake
        }
      ]
    }
    */
  ];
  if (!isAdmin) {
    navContent[0].links.push({
      to: '/branding',
      text: 'Branding',
      page: 'branding',
      icon: faPaintBrush
    });
  }

  return (
    <Box
      boxShadow="inner"
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      background="purple.50"
      align="left"
      p="3"
      w={isNavOpen ? '250px' : '82px'}
      height="100vh"
      transition="width 0.1s"
    >
      <Box>
        <Box>
          <Link to="/">
            <Image
              align="left"
              src={logoPng}
              objectFit="cover"
              height="35px"
            />
          </Link>
        </Box>

        <Divider
          mt="4"
          mb={isNavOpen ? '4' : '50px'}
        />
        {navContent.map((content, idx) => {
          return (
            <Box key={content.heading + idx}>
              {isNavOpen && (
                <Box
                  overflow="hidden"
                  width={isNavOpen ? 'auto' : '0px'}
                >
                  <Heading
                    width="200px"
                    color="gray.500"
                    textTransform="uppercase"
                    size="sm"
                    mb={4}
                    overflow="hidden"
                  >
                    {content.heading}
                  </Heading>
                </Box>
              )}
              {content.links.map((link) => {
                return (
                  <Box key={link.to}>
                    <Link
                      key={link.to}
                      to={link.to}
                    >
                      <Tooltip
                        hasArrow
                        label={isNavOpen ? '' : link.text}
                        bg="blue.100"
                        color="black"
                        placement="right"
                      >
                        <Button
                          color="blue.500"
                          bg={page === link.page ? 'blue.100' : 'transparent'}
                          _hover={{ background: 'blue.100' }}
                          variant="ghost"
                          mb="2"
                        >
                          <FontAwesomeIcon
                            size="lg"
                            icon={link.icon}
                          />
                          {isNavOpen && <Text ml="3">{link.text}</Text>}
                        </Button>
                      </Tooltip>
                    </Link>
                  </Box>
                );
              })}
              <Divider my={isNavOpen ? '4' : '34px'} />
            </Box>
          );
        })}
      </Box>

      <Box position="relative">
        <Center>
          <Tooltip
            hasArrow
            label={isNavOpen ? '' : 'Expand'}
            bg="blue.100"
            color="black"
            placement="right"
          >
            <Button
              width="100%"
              variant="ghost"
              color="blue.500"
              _hover={{ background: 'purple.100' }}
              onClick={toggleNav}
              aria-label={isNavOpen ? 'Collapse' : 'Expand'}
            >
              <FontAwesomeIcon
                size="xl"
                icon={isNavOpen ? faCaretSquareLeft : faCaretSquareRight}
              />
              {isNavOpen ? (
                <Text
                  ml="3"
                  size="sm"
                >
                  Collapse
                </Text>
              ) : (
                ''
              )}
            </Button>
          </Tooltip>
        </Center>

        <Box
          h="18px"
          overflow="hidden"
          width={isNavOpen ? 'auto' : '0px'}
        >
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};

export default Nav;
