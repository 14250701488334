// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyInstagramOauth } from '../../api/connection';
import VerifyOauth from '../../components/VerifyOauth';

const VerifyInstagram = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const instagramCode = searchParams.get('code');

  useEffect(() => {
    if (instagramCode && !verified) {
      verifyInstagramOauth(instagramCode).then((instagramResults) => {
        setVerified(true);
        window.opener.postMessage(
          {
            data: instagramResults
          },
          '*'
        );
        window.close();
      });
    }

    if (!instagramCode) {
      navigate('/', { replace: true });
    }
  }, []);

  return <VerifyOauth title="Instagram Verification" />;
};

export default VerifyInstagram;
