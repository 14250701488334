import helpers from '../utils/helpers';
import { useState } from 'react';
import { Avatar, AvatarBadge, Box, Button, Center, Container, Text, Tooltip, Wrap } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { deleteConnectionByUser, deleteConnectionByToken } from '../api/connection';
import AlertPopOver from '../components/AlertDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faLineChart } from '@fortawesome/free-solid-svg-icons';
import SortedTable from '../components/SortingTable';

const AuthorizedPlatforms = (props) => {
  const {
    userConnections,
    setUserConnections,
    selectedConnections,
    setSelectedConnections,
    fetchConnections,
    disabledConnections = [],
    selectable,
    view,
    token
  } = props;

  const [connectionToDelete, setConnectionToDelete] = useState();
  const [deleteDialog, setDeleteDialog] = useState();

  const handleAuthPlatformSelect = (connectionId) => {
    if (selectedConnections.includes(connectionId)) {
      const newArray = [...selectedConnections];
      const indexToRemove = newArray.indexOf(connectionId);
      newArray.splice(indexToRemove, 1);
      setSelectedConnections(newArray);
    } else {
      const newConnections = [...selectedConnections, connectionId];
      setSelectedConnections(newConnections);
    }
  };

  const discardConnectionDelete = () => {
    setConnectionToDelete();
    setDeleteDialog(false);
  };

  const deleteConnection = (connectionId) => {
    if (token) {
      deleteConnectionByToken(connectionId, token).then(() => {
        fetchConnections(token);
      });
    } else {
      deleteConnectionByUser(connectionId).then(() => {
        fetchConnections(token);
      });
    }
    const newConnections = userConnections.filter((connection) => connection._id !== connectionId);
    setUserConnections(newConnections);
    discardConnectionDelete();
  };

  const renderIconView = () => {
    return (
      <Wrap
        p="2"
        spacing="3"
        justify="center"
      >
        {userConnections.map((connection, idx) => {
          const isSelected = selectedConnections.includes(connection._id);
          const borderColor = isSelected ? 'blue.500' : 'white';
          const tooltipText = !isSelected ? 'Please select a connection to proceed' : '';
          return (
            <Box key={`${connection.platform}-${idx}`}>
              <Tooltip
                isDisabled={isSelected}
                hasArrow
                label={tooltipText}
                bg="gray.300"
                color="black"
                placement="top"
              >
                <Button
                  isDisabled={disabledConnections?.includes(connection._id)}
                  height="35px"
                  bg="gray.100"
                  border="2px"
                  boxShadow="base"
                  borderRadius="10px"
                  borderColor={borderColor}
                  _hover={{
                    background: 'blue.100'
                  }}
                  px={3}
                  onClick={() => (selectable ? handleAuthPlatformSelect(connection._id) : null)}
                >
                  <Box>
                    <Text
                      fontSize="sm"
                      color={`platform.${connection.platform}`}
                    >
                      {helpers.getSocialIcon(connection.platform, 'grey.500')}
                    </Text>
                  </Box>
                  <Text m="2">{connection?.userName || connection?.displayName}</Text>
                </Button>
              </Tooltip>
            </Box>
          );
        })}
      </Wrap>
    );
  };

  const renderTableView = () => {
    const tableData = userConnections.map((connection) => {
      return {
        '': (
          <Center>
            <Link
              to={'/connections/' + connection._id}
              padding="0"
              variant="outline"
              color="blue.500"
            >
              <Tooltip
                hasArrow
                label={'View reports'}
                bg="gray.300"
                color="black"
                placement="top"
              >
                <FontAwesomeIcon icon={faLineChart} />
              </Tooltip>
            </Link>
          </Center>
        ),
        username: connection.userName,
        profile: (
          <Link
            to={connection.profileUrl}
            target="_blank"
          >
            <Text
              mr="8px"
              fontSize="lg"
              height="15px"
              position="relative"
              display="inline-block"
            >
              {helpers.getSocialIcon(connection.platform)}
            </Text>
            <Text
              as="span"
              decoration="underline"
              fontSize="xs"
            >
              {connection.profileUrl}
            </Text>
          </Link>
        ),
        followers: Number(connection?.stats[0]?.followers)
          ? Number(connection?.stats[0]?.followers).toLocaleString()
          : Number(0),
        subscribers: Number(connection.stats[0]?.subscribers)
          ? Number(connection?.stats[0]?.subscribers).toLocaleString()
          : Number(0),
        views: Number(connection?.stats[0]?.views) ? Number(connection?.stats[0]?.views).toLocaleString() : Number(0),
        //'connected on': helpers.formatDate(connection.createdAt),
        'data updated': helpers.formatDate(connection.updatedAt),
        delete: (
          <Button
            onClick={() => {
              setDeleteDialog(true);
              setConnectionToDelete(connection);
            }}
            p="1"
            height="auto"
            alt="close button"
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faClose} />
          </Button>
        )
      };
    });

    return (
      <>
        <AlertPopOver
          title={`Delete Connection: ${connectionToDelete?.userName}`}
          type="delete"
          isOpen={deleteDialog}
          onClose={() => discardConnectionDelete()}
          onConfirm={async () => await deleteConnection(connectionToDelete?._id)}
          dialogContent={
            <Container>
              <Center mb="6">
                <Avatar
                  size="xl"
                  name={`${connectionToDelete?.userName}`}
                  src={connectionToDelete?.thumbnailUrl}
                  borderWidth="5px"
                >
                  <AvatarBadge
                    borderColor="white"
                    bg={`platform.${connectionToDelete?.platform}`}
                    boxSize="1.25em"
                  >
                    <Text fontSize="lg"> {helpers.getSocialIcon(connectionToDelete?.platform, '#FFFFFF')}</Text>
                  </AvatarBadge>
                </Avatar>
              </Center>
              <Box
                mx="0"
                my="3"
              >
                <Text as="paragraph">
                  Deleting this connection will remove it from all media kits, and permanently delete all historical
                  stats.
                </Text>
              </Box>
              <Box
                mx="0"
                my="3"
              >
                <Text
                  as="block"
                  color="red.500"
                >
                  If there are any media kits where this is the <Text as="u">only</Text> connection, those media kits
                  will be deleted.
                </Text>
              </Box>
            </Container>
          }
        />
        {tableData.length !== 0 && <SortedTable rows={tableData} />}
      </>
    );
  };

  const determineRender = () => {
    if (view == 'icons') {
      return renderIconView();
    } else if (view === 'table') {
      return renderTableView();
    }
  };

  return (
    <Box
      width="100%"
      spacing="3"
      overflow="scroll"
      mb="3"
    >
      {!userConnections && (
        <Center>
          <Text>You do not have any platforms connected.</Text>
        </Center>
      )}
      {!!userConnections && determineRender()}
    </Box>
  );
};

export default AuthorizedPlatforms;
