import { Box, Heading, Divider } from '@chakra-ui/react';

const PageHeader = ({ title, body }) => {
  return (
    <Box bg="white">
      <Box>
        <Heading size={'lg'}>{title}</Heading>
      </Box>

      <Divider my="3" />

      {body && (
        <Box>
          {body}
          <Divider my="3" />
        </Box>
      )}
    </Box>
  );
};

export default PageHeader;
