import { Box, Image } from '@chakra-ui/react';

const Background = (props) => {
  return (
    <Box>
      <Box
        position="absolute"
        zIndex="0"
        top="0px"
        left="0px"
        bottom="0"
        height="100%"
        width="100%"
        style={{
          maskImage: 'linear-gradient(black, transparent)'
        }}
      >
        {props.newBackgroundType === 'image' && props.newBackgroundImg && (
          <Image
            src={props.newBackgroundImg || props.background.source}
            width="100%"
            height="fit-content"
            opacity=".45"
            style={{ maskImage: 'linear-gradient(black,transparent)' }}
          />
        )}
        {props.newBackgroundType === 'video' && props.newBackgroundVideo && (
          <iframe
            style={{
              position: 'relative',
              top: '-15px',
              width: '100%',
              pointerEvents: 'none',
              height: '115%',
              opacity: '.45',
              maskImage: 'linear-gradient(black, transparent)'
            }}
            src={`${
              props.newBackgroundVideo || props.background.source
            }?controls=0&amp;modestbranding=1&amp;playsinline=1&amp;autoplay=1&amp;showinfo=0&amp;disablekb=1&amp;mute=1&amp;rel=0&amp;fs=0&amp;loop=1`}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
          />
        )}
      </Box>
    </Box>
  );
};

export default Background;
