import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../hooks/useAppContext';
import {
  Box,
  Container,
  Divider,
  Input,
  FormControl,
  FormErrorMessage,
  Switch,
  HStack,
  Text,
  UnorderedList,
  ListItem
} from '@chakra-ui/react';
import AlertPopOver from '../components/AlertDialog';

const CreateListModal = (props) => {
  const { userAgency, userMediaKits } = useAppContext();
  const { isOpen, onClose, selectedMediaKits, newListName } = props;
  const [listPasscode, setListPasscode] = useState('');

  return (
    <AlertPopOver
      title={`${newListName}`}
      type="create"
      isOpen={isOpen}
      onClose={() => onClose(false)}
      onConfirm={() => {
        props.onConfirm(
          newListName,
          newListName.toLowerCase().replaceAll(' ', '-'),
          Array.from(selectedMediaKits),
          listPasscode
        );
      }}
      dialogContent={
        <Container>
          <Box mb="5">
            <Text
              fontSize="lg"
              mb="2"
            >
              Creating a list of media kits:
            </Text>
            <Box align="left">
              <UnorderedList>
                {userMediaKits.map((mediaKit) => {
                  if (selectedMediaKits.has(mediaKit._id)) {
                    return (
                      <ListItem
                        key={'createList-' + mediaKit._id}
                        color="blue.800"
                      >
                        /{mediaKit.slug}
                      </ListItem>
                    );
                  }
                })}
              </UnorderedList>
            </Box>
          </Box>
          <Box>
            <Divider />
            <Text
              fontSize="lg"
              my="3"
            >
              Sharing {newListName} List:
            </Text>

            <HStack mb="4">
              <Text>Passcode:</Text>
              <Input
                id="search"
                placeholder={'Leave blank to make this list public' || `${userAgency?.slug}${new Date().getFullYear()}`}
                variant="outline"
                type="email"
                fontSize="sm"
                px="2"
                my={2}
                width="100%"
                mx={4}
                value={listPasscode}
                onChange={(e) => setListPasscode(e.target.value)}
              />
            </HStack>
            <HStack mb="3">
              <Text>URL:</Text>
              <Input
                id="search"
                variant="outline"
                type="email"
                fontSize="xs"
                px="2"
                my={2}
                width="100%"
                mx={4}
                value={`mediasweet.com/agency/${userAgency?.slug}/${newListName?.replaceAll(' ', '-')}`}
                isDisabled
              />
            </HStack>
          </Box>
        </Container>
      }
    />
  );
};
export default CreateListModal;
