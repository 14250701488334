import { useState, useEffect } from 'react';
import {
  Box,
  Center,
  Container,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Divider,
  Button,
  HStack,
  Stack,
  InputGroup,
  FormControl,
  FormErrorMessage,
  Input,
  ModalFooter,
  Table,
  TableContainer,
  Th,
  Tbody,
  Tr,
  Td,
  TableCaption,
  Thead,
  Text,
  Heading
} from '@chakra-ui/react';
import { faAdd, faMinus, faCheck, faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from '../hooks/useAppContext';
import { invite, getPublisherInvites } from '../api/agency';
import { useToastHook } from '../components/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import helpers from '../utils/helpers';

const PlatformsCreateRequestModal = (props) => {
  const { user, userAgency } = useAppContext();
  const { isOpen = false, setIsOpen = () => {}, onClose = () => {} } = props;
  let emptyGroup = { name: '', email: '' };

  const [influencerGroups, setInfluencerGroups] = useState([emptyGroup]);
  const [existingInvites, setExistingInvites] = useState([]);

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isResendLoading, setIsResendLoading] = useState(false);

  const [state, newToast] = useToastHook();

  var validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  let hasFormError = false;

  useEffect(() => {
    if (userAgency?._id) {
      getPublisherInvites(userAgency?._id).then((invites) => {
        setExistingInvites(invites);
      });
    }
  }, [userAgency, user]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const validateInput = () => {
    influencerGroups.map((group) => {
      if (!group.name || group.name === '') {
        group.nameError = 'can not be blank';
        hasFormError = true;
      } else {
        hasFormError = false;
      }
      if (!group.email || group.email === '') {
        group.emailError = 'can not be blank';
        hasFormError = true;
      } else if (!group.email.includes('@')) {
        group.emailError = 'must include an @';
        hasFormError = true;
      } else if (!group.email.match(validEmailRegex)) {
        group.emailError = 'invalid email';
        hasFormError = true;
      } else {
        hasFormError = false;
      }
      return group;
    });
    setInfluencerGroups([...influencerGroups]);
  };

  const resendInvite = async (name, email) => {
    setIsResendLoading(true);
    try {
      const inviteResult = await invite(email, name, userAgency?._id);
      if (inviteResult?.success) {
        newToast({
          status: 'success',
          message: `Invite to ${email} re-sent!`
        });
      } else {
        newToast({
          status: 'error',
          message: `Error occurred sending email invite to: ${email}!`
        });
      }
    } catch (err) {
      newToast({
        status: 'error',
        message: `${err?.message || err}, please try again later`
      });
    } finally {
      setIsResendLoading(false);
    }
  };

  const submitForm = async () => {
    setIsSubmitLoading(true);
    validateInput();

    if (!hasFormError) {
      let successfulInvites = [];
      let failedInvites = [];
      for await (const group of influencerGroups) {
        const { email, name } = group;

        try {
          const inviteResult = await invite(email, name, userAgency?._id);
          if (inviteResult?.success) {
            newToast({
              status: 'success',
              message: `Invite to ${email} sent!`
            });

            successfulInvites.push({
              name,
              email,
              status: 'PENDING',
              createdAt: new Date().getTime(),
              updatedAt: new Date().getTime()
            });
          } else {
            newToast({
              status: 'error',
              message: 'Error occurred sending email!'
            });
            failedInvites.push({ name, email });
          }
        } catch (error) {
          const message = error?.message || 'Error occurred sending email!';
          newToast({
            status: 'error',
            message
          });
          setInfluencerGroups([emptyGroup]);
        }
      }
      setExistingInvites([...existingInvites, ...successfulInvites]);
      if (failedInvites.length) {
        setInfluencerGroups(failedInvites);
      } else {
        setInfluencerGroups([emptyGroup]);
      }
    }
    setIsSubmitLoading(false);
  };

  return (
    <Modal
      width="2xl"
      size="4xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Request Connection Access</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb="2">Request that your creator(s) provide you access to their connection stats.</Text>
          <Divider my="4" />
          <Box
            display="block"
            maxH="300px"
            overflow="scroll"
            right="0"
            left="0"
            position="relative"
            borderSize="1px"
          >
            {influencerGroups.map((group, i) => {
              return (
                <HStack
                  key={`hstack-${i}`}
                  mx={3}
                  mb={3}
                >
                  <Stack
                    width="100%"
                    spacing={4}
                  >
                    <FormControl
                      mb="3"
                      position="relative"
                      isInvalid={influencerGroups[i].nameError}
                    >
                      <InputGroup>
                        <Input
                          width="100%"
                          onChange={(e) => {
                            delete influencerGroups[i].nameError;
                            influencerGroups[i].name = e.target.value;
                            setInfluencerGroups([...influencerGroups]);
                          }}
                          value={influencerGroups[i].name}
                          type="text"
                          alt="creator name"
                          placeholder="Creator Name"
                        />
                      </InputGroup>
                      <FormErrorMessage
                        position="absolute"
                        bottom="-20px"
                      >
                        {influencerGroups[i].nameError}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <Stack
                    width="100%"
                    spacing={4}
                  >
                    <FormControl
                      mb="3"
                      position="relative"
                      isInvalid={influencerGroups[i].emailError}
                    >
                      <InputGroup>
                        <Input
                          width="100%"
                          onChange={(e) => {
                            delete influencerGroups[i].emailError;
                            influencerGroups[i].email = e.target.value;
                            setInfluencerGroups([...influencerGroups]);
                          }}
                          value={influencerGroups[i].email}
                          type="text"
                          alt="creator email"
                          placeholder="creator@email.com"
                        />
                      </InputGroup>
                      <FormErrorMessage
                        position="absolute"
                        bottom="-20px"
                      >
                        {influencerGroups[i].emailError}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <HStack>
                    <Button
                      mb="3"
                      alt=""
                      colorScheme="red"
                      onClick={() => {
                        influencerGroups.splice(i, 1);
                        setInfluencerGroups([...influencerGroups]);
                      }}
                    >
                      <FontAwesomeIcon icon={faMinus} />
                    </Button>
                  </HStack>
                </HStack>
              );
            })}
          </Box>

          <Button
            isDisabled={
              influencerGroups.length >= 1 &&
              (!influencerGroups[influencerGroups?.length - 1]?.email ||
                !influencerGroups[influencerGroups?.length - 1].name)
            }
            alt=""
            width="100%"
            colorScheme="gray"
            onClick={() => {
              setInfluencerGroups([...influencerGroups, emptyGroup]);
            }}
          >
            <FontAwesomeIcon icon={faAdd} />
          </Button>

          <Container
            mx="0"
            minW="100%"
            align="right"
            mt="3"
          >
            <Button
              colorScheme="blue"
              mr={3}
              variant="outline"
              onClick={onClose}
            >
              Discard
            </Button>
            <Button
              isLoading={isSubmitLoading}
              colorScheme="blue"
              onClick={() => {
                //validateInput();
                submitForm();
              }}
            >
              Send emails
            </Button>
          </Container>

          <Divider my="4" />

          <Container
            maxH="300px"
            maxW="100%"
            overflowY="scroll"
            mx="0"
            p="0"
          >
            <Heading
              fontSize="xl"
              my="4"
            >
              Existing Invitations
            </Heading>
            <TableContainer width="100%">
              <Table
                size="sm"
                variant="striped"
              >
                <TableCaption></TableCaption>
                <Thead>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Status</Th>
                    <Th>First Sent</Th>
                    <Th>Updated</Th>
                    <Th>Resend</Th>
                    {/*<Th>Delete</Th>*/}
                  </Tr>
                </Thead>
                <Tbody>
                  {existingInvites.length !== 0 &&
                    existingInvites.map((invite, idx) => {
                      return (
                        <Tr key={`tr-${idx}`}>
                          <Td>{invite.name}</Td>
                          <Td>{invite.email}</Td>
                          <Td>
                            {invite.status === 'CONNECTED' && (
                              <Center>
                                <Box
                                  bg="green.500"
                                  borderRadius="20px"
                                  width="23px"
                                  height="23px"
                                  align="center"
                                  pt="3px"
                                >
                                  <FontAwesomeIcon
                                    size="lg"
                                    position="relative"
                                    color="white"
                                    icon={faCheck}
                                  />
                                </Box>
                              </Center>
                            )}
                            {invite.status === 'PENDING' && (
                              <Center>
                                <Box
                                  bg="purple.400"
                                  borderRadius="20px"
                                  width="23px"
                                  height="23px"
                                  align="center"
                                  pt="3px"
                                >
                                  <FontAwesomeIcon
                                    size="lg"
                                    position="relative"
                                    color="white"
                                    icon={faEllipsis}
                                  />
                                </Box>
                              </Center>
                            )}
                          </Td>
                          <Td>{helpers.formatDate(invite.createdAt, false)}</Td>
                          <Td>{helpers.formatDate(invite.updatedAt, false)}</Td>
                          <Td>
                            <Box>
                              <Button
                                isLoading={isResendLoading}
                                onClick={() => resendInvite(invite.name, invite.email)}
                                border={'1px'}
                                fontSize="sm"
                                p="2"
                                height="auto"
                                bg="gray.100"
                                color="red.500"
                                boxShadow="base"
                              >
                                Resend
                              </Button>
                            </Box>
                          </Td>
                          {/*<Td>
                          <Box>
                            <Button
                              // onClick={() => deleteInvite(connection._id)}
                              border={'1px'}
                              fontSize="sm"
                              p="2"
                              height="auto"
                              bg="gray.100"
                              color="red.500"
                              boxShadow="base"
                            >
                              Delete
                            </Button>
                          </Box>
                        </Td>*/}
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </TableContainer>
          </Container>
          <Divider my="4" />

          <Text
            mt="4"
            fontSize="sm"
          >
            We will send an email to your creator(s) that will request them to authenticate their social media platforms
            and authorize your access to their stats.
          </Text>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PlatformsCreateRequestModal;
