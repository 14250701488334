import CreateNewConnectionButtons from './CreateNewConnectionButtons';
import AuthorizedPlatforms from './AuthorizedPlatforms';
import { Box, Center, Divider, Heading } from '@chakra-ui/react';

const PlatformsContainer = (props) => {
  const {
    setUserConnections,
    userConnections = [],
    selectedConnections = [],
    setSelectedConnections,
    selectable,
    disabledConnections,
    view,
    fetchConnections = () => {},
    token
  } = props;

  return (
    <Box>
      <AuthorizedPlatforms
        disabledConnections={disabledConnections}
        selectedConnections={selectedConnections}
        setSelectedConnections={setSelectedConnections}
        userConnections={userConnections}
        setUserConnections={setUserConnections}
        fetchConnections={fetchConnections}
        selectable={selectable}
        view={view}
        token={token}
      />

      <Divider />

      <Center>
        <Box m={3}>
          <Heading fontSize={'3xl'}>Connect new account</Heading>
        </Box>
      </Center>
      <CreateNewConnectionButtons
        fetchConnections={fetchConnections}
        token={token}
      />
    </Box>
  );
};

export default PlatformsContainer;
