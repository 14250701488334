import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  InputGroup,
  Image,
  Text,
  Tooltip,
  Skeleton
} from '@chakra-ui/react';
import { getUploadUrl } from '../api/user';
import { uploadS3Image } from '../api/s3';
import { useAppContext } from '../hooks/useAppContext';
import { useToastHook } from '../components/Toast';
import { update } from '../api/agency';
import DashboardLayout from '../containers/DashboardLayout';
import PageHeader from '../containers/PageHeader';
import Loader from '../components/Loader';

const Branding = () => {
  const { userAgency, userAgencyProduct, setUserAgency } = useAppContext();

  const [agencyName, setAgencyName] = useState(userAgency?.name || '');
  const [agencySlug, setAgencySlug] = useState(userAgency?.slug || '');
  const [agencyLogoBlob, setAgencyLogoBlob] = useState('');
  const [agencyLogoObject, setAgencyLogoObject] = useState();

  const [agencyNameError, setAgencyNameError] = useState('');
  const [agencySlugError, setAgencySlugError] = useState('');
  const [agencyLogoError, setAgencyLogoError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [state, newToast] = useToastHook();

  useEffect(() => {
    if (userAgency) {
      setAgencyName(userAgency.name);
      setAgencySlug(userAgency.slug);
    }
  }, [userAgency]);

  const resetState = () => {
    setAgencyNameError('');
    setAgencySlugError('');
    setAgencyLogoError('');
  };

  const setAgencyLogoImage = (image) => {
    if (image.size / 1000 / 1000 > 25) {
      setAgencyLogoError("Image can't be larger than 25mb");
    } else {
      setAgencyLogoError('');
    }
    setAgencyLogoObject(image);
    setAgencyLogoBlob(URL.createObjectURL(image));
  };

  const uploadImage = async () => {
    if (agencyLogoObject) {
      try {
        const urls = await getUploadUrl(`agencies/${userAgency?._id}/branding`, agencyLogoObject.name);
        const { signedUrl, publicUrl } = urls;
        const uploadImage = await uploadS3Image(signedUrl, agencyLogoObject);
        if (uploadImage) {
          return publicUrl;
        }
      } catch (err) {
        setAgencyLogoError('There was an issue uploading your image');
        setIsLoading(false);
        return false;
      }
    }
  };

  const submitForm = async () => {
    setIsLoading(true);
    let errors = false;

    if (!agencyName || agencyName === '') {
      setAgencyNameError("Agency name can't be blank");
      errors = true;
    }
    if (!agencySlug || !agencySlug === '') {
      setAgencySlugError("Agency slug can't be blank");
      errors = true;
    }

    if (!errors && !agencyLogoError) {
      setIsLoading(false);
      const updateBody = {
        name: agencyName,
        slug: agencySlug
      };
      if (agencyLogoObject) {
        const newLogo = await uploadImage();
        if (newLogo) {
          updateBody.logo = newLogo;
        }
      }

      const result = await update(userAgency?._id, updateBody, localStorage.getItem('jwt'));
      if (result?.acknowledged) {
        setUserAgency({ ...userAgency, ...updateBody });
        newToast({
          status: 'success',
          message: 'Agency details have been saved'
        });
        resetState();
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout page="branding">
      <PageHeader title="Branding" />

      <Box
        width="100%"
        mx="4"
      >
        <Loader isLoaded={userAgency}>
          {(userAgency?.logo.length || userAgencyProduct?.branding) && (
            <Box maxW="500px">
              <Skeleton
                width="500px"
                height="150px"
                isLoaded={userAgency?.logo}
              >
                <Image
                  mx="auto"
                  borderRadius="lg"
                  boxSize="450px"
                  height="150px"
                  objectFit="cover"
                  src={agencyLogoBlob || userAgency?.logo}
                  fallbackSrc="https://via.placeholder.com/450x150"
                ></Image>
              </Skeleton>

              <FormControl isInvalid={agencyLogoError}>
                <Input
                  position="absolute"
                  height="0px"
                  overflow="hidden"
                  width="0px"
                  display="none"
                  type="file"
                  accept="image/png, image/jpeg"
                  name="headshot-image-upload"
                  id="headshot-image-upload"
                  onChange={(e) => {
                    setAgencyLogoImage(e.target.files[0]);
                  }}
                ></Input>
                <Text
                  fontSize="sm"
                  mx="auto"
                  width="fit-content"
                >
                  We suggest a logo with a transparent background that will look good on a white background
                </Text>
                <FormLabel
                  width="fit-content"
                  mx="auto"
                  cursor="pointer"
                  htmlFor="headshot-image-upload"
                  fontWeight="bold"
                  borderRadius="md"
                  boxShadow="inset 0px -1px 7px rgba(93,86,73, 0.06)"
                  background="blue.500"
                  padding="8px 12px"
                  mt="18px"
                  lineHeight="16px"
                  color="gray.50"
                  fontSize="15px"
                >
                  <Text
                    fontWeight="bold"
                    position="relative"
                    bottom="1px"
                  >
                    Change Logo
                  </Text>
                </FormLabel>

                <FormErrorMessage
                  float="right"
                  mt={0}
                >
                  {agencyLogoError}
                </FormErrorMessage>
              </FormControl>
            </Box>
          )}

          <Box mb={8}>
            Company Name:
            <FormControl isInvalid={agencyNameError}>
              <InputGroup width={'md'}>
                <Input
                  bg="white"
                  value={agencyName}
                  onChange={(e) => setAgencyName(e.target.value)}
                  type="agency-name"
                />
              </InputGroup>
              <FormErrorMessage
                float="right"
                mt={0}
              >
                {agencyNameError}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <Box mb={8}>
            URL Slug:
            <Tooltip
              hasArrow
              label={'Please contact us if you wish to update your url slug'}
              bg="gray.300"
              color="black"
              placement="top"
            >
              <FormControl isInvalid={agencySlugError}>
                <InputGroup width={'md'}>
                  <Input
                    bg="white"
                    value={agencySlug}
                    type="agency-name"
                    isDisabled={true}
                  />
                </InputGroup>
                <FormErrorMessage
                  float="right"
                  mt={0}
                >
                  {agencySlugError}
                </FormErrorMessage>
              </FormControl>
            </Tooltip>
          </Box>

          <Box mt={6}>
            <Button
              isLoading={isLoading}
              onClick={submitForm}
              background="blue.500"
              color="white"
            >
              Save changes
            </Button>
          </Box>
        </Loader>
      </Box>
    </DashboardLayout>
  );
};

export default Branding;
