import { useState, useEffect } from 'react';
import { Input, Center, FormControl, FormErrorMessage, Select, Button, Switch, HStack, Text } from '@chakra-ui/react';
import CreateListModal from '../components/CreateListModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare, faSave, faClose, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getMediakitList, createMediaKitList, deleteAgencyListMediakit } from '../api/mediakit';
import { useToastHook } from '../components/Toast';
import { useAppContext } from '../hooks/useAppContext';
const MODES = { CREATE_LIST: 'create-list', VIEW: 'view' };

const MediaKitListSelector = (props) => {
  const [state, newToast] = useToastHook();
  const { userAgency, userAgencyProduct } = useAppContext();
  const [agencyLists, setAgencyLists] = useState([{ name: 'All', slug: 'all' }]);
  const [newListName, setNewListName] = useState();
  const [newListError, setNewListError] = useState();
  const [confirmNewListDialog, setConfirmNewListDialog] = useState(false);

  const { mode, setMode, selectedMediaKits, setSelectedMediaKits, activeList, setActiveList, setMediaKitsFilter } =
    props;

  useEffect(() => {
    getMediakitList().then((lists) => {
      if (lists) {
        setAgencyLists([{ name: 'All', slug: 'all' }, ...lists]);
      }
    });
  }, []);

  const submitMediaKitList = (name, slug, mediakits, passcode) => {
    createMediaKitList({ name, slug, mediakits, passcode }).then((result) => {
      setAgencyLists([...agencyLists, { name, slug, mediakits, passcode }]);
      setSelectedMediaKits(new Set([]));
      setMode(MODES.VIEW);
      setConfirmNewListDialog(false);
      newToast({
        status: 'success',
        message: `Your media kit list ${slug} has been created`,
        duration: 5000
      });
    }).catch(err => {
      newToast({
        status: 'error',
        message: err?.message || "Could not create your media kit list at this time",
        duration: 5000
      });

    });
  };

  const handleListShare = () => {
    navigator.clipboard
      .writeText(window.location.origin + `/${userAgency.slug}/lists/${activeList}`)
      .then(() => {
        newToast({
          status: 'info',
          title: `Copied ${window.location.origin}/${userAgency.slug}/lists/${activeList} to clipboard!`,
          duration: 5000,
          isClosable: true
        });
      })
      .catch((err) => console.error("Couldn't get share link: ", err));
  };

  const handleListDelete = () => {
    deleteAgencyListMediakit(activeList).then((result) => {
      setMediaKitsFilter({});
      newToast({
        status: 'success',
        message: `Your media kit list was deleted`,
        duration: 5000
      });
    });
  };

  return (
    <>
      <HStack my="2">
        <HStack width="100%">
          {mode !== MODES.CREATE_LIST ? (
            <>
              <Text fontSize="sm">Showing</Text>
              <Select
                width="50%"
                mr="20"
                onChange={(e) => {
                  if (e.target.value === MODES.CREATE_LIST && userAgencyProduct?.cohorts > agencyLists?.length - 1) {
                    setActiveList('all');
                    setMode(MODES.CREATE_LIST);
                    setMediaKitsFilter({});
                  } else {
                    setActiveList(e.target.value);
                    setMediaKitsFilter({ ids: agencyLists?.filter((a) => a.slug === e.target.value)[0].mediakits });
                    setMode(MODES.VIEW);
                  }
                }}
              >
                {agencyLists.map((list) => {
                  return (
                    <option
                      key={list.slug}
                      defaultValue={activeList === list.slug}
                      value={list.slug}
                    >
                      {list.name}
                    </option>
                  );
                })}
                <option
                  disabled
                  value="---"
                >
                  ---
                </option>
                <option
                  disabled={userAgencyProduct?.cohorts <= agencyLists?.length - 1}
                  value={MODES.CREATE_LIST}
                >
                  Create List
                </option>
              </Select>
              {activeList !== 'all' && (
                <>
                  <Button
                    py="2"
                    px="3"
                    boxShadow="base"
                    borderRadius="xl"
                    bg="gray.100"
                    color="blue.800"
                    onClick={handleListShare}
                  >
                    <Text mr="3">Share</Text> <FontAwesomeIcon icon={faShare} />
                  </Button>
                  <Button
                    py="2"
                    px="3"
                    boxShadow="base"
                    borderRadius="xl"
                    bg="gray.100"
                    color="blue.800"
                    onClick={handleListDelete}
                  >
                    <Text mr="3">Delete</Text> <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              <FormControl
                width="auto"
                isInvalid={newListError}
              >
                <Input
                  id="listName"
                  placeholder="Name your new list"
                  variant="outline"
                  type="email"
                  width="sm"
                  onChange={(e) => {
                    setNewListError();
                    setNewListName(e.target.value);
                  }}
                />
                <FormErrorMessage position="absolute">{newListError}</FormErrorMessage>
              </FormControl>

              <Button
                py="2"
                px="3"
                boxShadow="base"
                borderRadius="xl"
                bg="gray.100"
                color="blue.800"
                onClick={() => {
                  setNewListName();
                  setSelectedMediaKits(new Set([]));
                  setMode(MODES.VIEW);
                  setNewListError();
                }}
              >
                <Text mr="3">Discard</Text> <FontAwesomeIcon icon={faClose} />
              </Button>

              <Button
                py="2"
                px="3"
                boxShadow="base"
                borderRadius="xl"
                bg="gray.100"
                color="blue.800"
                onClick={() => {
                  setNewListError();
                  if (!newListName) {
                    setNewListError('Name is a required field');
                  } else if (selectedMediaKits.size === 0 || selectedMediaKits.length === 0) {
                    setNewListError('Please select a media kit before continuing!');
                  } else {
                    setConfirmNewListDialog(true);
                  }
                }}
              >
                <Text mr="3">Confirm</Text> <FontAwesomeIcon icon={faSave} />
              </Button>

              <CreateListModal
                isOpen={confirmNewListDialog}
                onClose={() => setConfirmNewListDialog(false)}
                selectedMediaKits={selectedMediaKits}
                newListName={newListName}
                onConfirm={submitMediaKitList}
              />
            </>
          )}
        </HStack>
        {/*<Input
          id="search"
          placeholder="Search for media kits"
          variant="outline"
          type="email"
          my={2}
          mx={4}
          width="55%"
        />*/}
      </HStack>
      {mode === MODES.CREATE_LIST && (
        <Center>
          <Text
            mb="3"
            color="blue.800"
          >
            Select media kits below to add to your list
          </Text>
          <Switch
            m="2"
            bottom="1"
            size="sm"
            isChecked
          />
        </Center>
      )}
    </>
  );
};

export default MediaKitListSelector;
