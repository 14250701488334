import React, { useState } from 'react';

import {
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter
} from '@chakra-ui/react';

const DIALOGS = {
  delete: "Are you sure? You can't undo this action afterwards",
  create: ''
};

const AlertPopOver = (props) => {
  const { title, body, type, isOpen, onOpen, onClose, onConfirm, dialogContent = '' } = props;
  const [disabled, setDisabled] = useState(false);
  const cancelRef = React.useRef();

  const submit = async () => {
    setDisabled(true);
    const isSubmitted = await onConfirm();
    if (isSubmitted) {
      onClose();
    } else {
      setDisabled(false);
    }
  };

  const close = () => {
    setDisabled(false);
    return onClose();
  };

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader
            fontSize="xl"
            fontWeight="bold"
          >
            {title || ''}
          </AlertDialogHeader>

          <AlertDialogBody>
            {dialogContent}
            {DIALOGS[type] || ''}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              isLoading={disabled}
              colorScheme={type === 'delete' ? 'red' : 'blue'}
              onClick={submit}
              ml={3}
            >
              {type === 'delete' ? 'Delete' : 'Save'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertPopOver;
