import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Skeleton,
  Image,
  Switch,
  HStack,
  VStack,
  Text,
  Heading
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faClose } from '@fortawesome/free-solid-svg-icons';
import helpers from '../utils/helpers';
import { deleteUserMediakit } from '../api/mediakit';
import { getConnectionById } from '../api/connection';
import AlertPopOver from '../components/AlertDialog';
import { useToastHook } from '../components/Toast';
import Loader from './Loader';

const MediaKitCard = (props) => {
  const navigate = useNavigate();
  const MODES = { CREATE_LIST: 'create-list', VIEW: 'view' };
  const { mode, mediakit, agencySlug, removeMediaKit, connections, toggleMediaKitSelect } = props;
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [mediaKitToDelete, setMediaKitToDelete] = useState({});
  const [_, newToast] = useToastHook();
  const [platforms, setPlatforms] = useState([]);
  const [platformsLoaded, setPlatformsLoaded] = useState(false);
  const [totalReach, setTotalReach] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const [mediaKitUrl, setMediaKitUrl] = useState(`/${agencySlug || `mediakits`}/${mediakit.slug}`);

  useEffect(() => {
    if (mode === MODES.VIEW) {
      setIsSelected(false);
    }
  }, [mode]);

  useEffect(() => {
    const calcConnections = async () => {
      let connections = {};
      let runningFollowers = 0;
      let totalFollowers = 0;
      let runningPlatforms = [];

      if (props?.connections && Object.keys(props.connections).length) {
        const mediakitPlatforms = mediakit.platforms.map((platform) => platform.id); // return array of platform ids

        for (const connectionId in props.connections) {
          const mediaKitConnection = props.connections[connectionId];

          if (mediakitPlatforms.includes(mediaKitConnection._id)) {
            const reach =
              parseInt(mediaKitConnection.stats[0]?.followers) || parseInt(mediaKitConnection.stats[0]?.subscribers);
            connections[mediaKitConnection._id] = mediaKitConnection;
            if (reach > runningFollowers) {
              runningFollowers = reach;
            }
            totalFollowers += reach || 0;
            runningPlatforms.push({ id: mediaKitConnection._id, name: mediaKitConnection.platform, reach });
          }
        }
        setPlatforms(runningPlatforms);
        setTotalReach(totalFollowers);
        setPlatformsLoaded(true);
      }
    };
    if (!platforms.length) {
      calcConnections();
    }
  }, []);

  const handleMediaKitEdit = (mediakit) => {
    navigate(`${mediaKitUrl}?edit=true`);
  };

  const handleMediaKitShare = (mediakit) => {
    navigator.clipboard
      .writeText(window.location.origin + mediaKitUrl)
      .then(() => {
        newToast({
          status: 'info',
          title: 'Copied to clipboard!',
          duration: 5000,
          isClosable: true
        });
      })
      .catch((err) => {
        newToast({
          status: 'error',
          title: `Couldn't copy share link: ${window.location.origin}${mediaKitUrl}`,
          duration: 5000,
          isClosable: true
        });
      });
  };

  const discardMediaKitDelete = () => {
    setMediaKitToDelete({});
    setDeleteDialog(false);
  };

  const handleMediaKitDeletePrompt = (mediakit) => {
    setDeleteDialog(true);
    setMediaKitToDelete(mediakit);
  };

  const deleteMediaKit = async (mediakit) => {
    deleteUserMediakit(mediakit._id)
      .then((result) => {
        removeMediaKit(mediakit._id);
        setDeleteDialog(false);
        setMediaKitToDelete({});
      })
      .catch((err) => {
        newToast({
          status: 'error',
          title: 'There was an issue deleting your media kit. Try again later',
          duration: 5000,
          isClosable: true
        });
      });
  };

  return (
    <Box
      width="100%"
      minW="300px"
      height="330px"
      borderColor={isSelected ? 'blue.500' : 'gray.200'}
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="base"
      overflow="hidden"
      position="relative"
    >
      <AlertPopOver
        title={`Delete Media Kit: ${mediaKitToDelete?.slug}`}
        type="delete"
        isOpen={deleteDialog}
        onClose={() => discardMediaKitDelete()}
        onConfirm={() => deleteMediaKit(mediakit)}
        dialogContent={
          <Center mb="6">
            <Avatar
              size="xl"
              name={`${mediakit.displayName}`}
              src={mediakit.headshot}
              borderWidth="5px"
            >
              <AvatarBadge
                borderColor="white"
                bg="red.500"
                boxSize="1.25em"
              >
                <FontAwesomeIcon
                  icon={faClose}
                  size="xs"
                  color="white"
                />
              </AvatarBadge>
            </Avatar>
          </Center>
        }
      />

      {mode === MODES.CREATE_LIST && (
        <Box
          zIndex="1"
          position="absolute"
          top="2"
          left="2"
        >
          <Switch
            onChange={(e) => {
              props.toggleMediaKitSelect(mediakit._id);
              setIsSelected(!isSelected);
            }}
            size="lg"
          />
        </Box>
      )}

      {!props.public && (
        <Box
          zIndex="1"
          position="absolute"
          top="2"
          right="2"
        >
          <Menu>
            <MenuButton
              padding="0"
              size="sm"
              as={Button}
              bg="gray.100"
              color="gray.1000"
            >
              <FontAwesomeIcon
                icon={faEllipsis}
                size="sm"
              />
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => handleMediaKitEdit(mediakit)}
                value="edit"
              >
                Edit
              </MenuItem>
              {/* TODO: <MenuItem
              onClick={() => {
                console.log('download');
              }}
              value="download"
            >
              Download as PDF
            </MenuItem> */}
              <MenuItem
                onClick={() => handleMediaKitShare(mediakit)}
                value="share"
              >
                Share
              </MenuItem>
              <MenuItem
                onClick={() => handleMediaKitDeletePrompt(mediakit)}
                value="delete"
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      )}

      <Box
        height="100%"
        bgGradient={`linear(to-b, ${mediakit?.theme.colors.primary}.100, ${mediakit?.theme.colors.secondary}.50, white)`}
        align="center"
      >
        <Box
          height="150"
          mb="4"
        >
          {mediakit.background?.type === 'image' &&
            (mediakit.background?.source ? (
              <Image
                opacity=".65"
                height="135"
                width="100%"
                objectFit="cover"
                src={mediakit?.background?.source}
                alt={mediakit.imageAlt}
              />
            ) : (
              <Box height="135px"></Box>
            ))}
          {mediakit.background?.type === 'video' && (
            <Image
              opacity=".65"
              height="135"
              width="100%"
              objectFit="cover"
              src={`https://i.ytimg.com/vi_webp/${mediakit.background?.source.split('/')[4]}/maxresdefault.webp`}
              alt={mediakit.imageAlt}
            />
          )}
          <Link to={mediaKitUrl}>
            <Avatar
              size="xl"
              name={`${mediakit.displayName}`}
              src={mediakit.headshot}
              top="-45%"
              borderWidth="5px"
            />
          </Link>
        </Box>

        <Box mb="3">
          <Heading
            fontWeight="semibold"
            as="h2"
            fontSize="lg"
            lineHeight="tight"
            noOfLines={1}
          >
            {mediakit.displayName}
          </Heading>

          <Box
            mt="-1"
            fontWeight="semibold"
            as="h6"
            lineHeight="tight"
            noOfLines={1}
            fontSize="sm"
            color="blue.500"
            mb="2"
          >
            <Link to={mediaKitUrl}>{mediaKitUrl}</Link>
          </Box>
        </Box>

        <Loader
          isLoaded={platformsLoaded}
          size="md"
          height=""
        >
          <Box
            color="gray.900"
            fontWeight="semibold"
            alignItems="center"
            display="flex"
            letterSpacing="wide"
            fontSize="md"
            textTransform="uppercase"
          >
            <HStack
              mx="auto"
              spacing={3}
              align="stretch"
            >
              {platforms?.map((platform, p) => {
                return (
                  <VStack
                    key={`card-${platform.name}-${p}`}
                    spacing={-1}
                  >
                    <Box
                      mb="6px"
                      height="18px"
                      as="span"
                    >
                      {helpers.getSocialIcon(platform.name, undefined, 'lg')}
                    </Box>
                    <Box
                      mt="0"
                      as="span"
                      fontSize="xs"
                    >
                      {helpers.formatNumber(platform.reach, true)}
                      <Skeleton
                        isLoaded={typeof platform.reach !== 'undefined'}
                        width={5}
                        height={4}
                        noOfLines={1}
                      ></Skeleton>
                    </Box>
                  </VStack>
                );
              })}
            </HStack>
          </Box>
          {mediakit.platforms && (
            <Box mt={-4}>
              <Box
                as="span"
                color="gray.500"
                fontSize="xs"
                textTransform="uppercase"
              >
                Total reach: &nbsp;
              </Box>
              <Text>{totalReach?.toLocaleString()}</Text>
            </Box>
          )}
        </Loader>
      </Box>
    </Box>
  );
};

export default MediaKitCard;
