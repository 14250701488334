// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyTwitterOauth } from '../../api/connection';
import VerifyOauth from '../../components/VerifyOauth';
import { useToastHook } from '../../components/Toast';

const VerifyTwitter = () => {
  const navigate = useNavigate();
  const [_, newToast] = useToastHook();
  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const twitterOauthToken = searchParams.get('oauth_token');
  const twitterOauthVerifier = searchParams.get('oauth_verifier');

  useEffect(() => {
    if (twitterOauthToken && twitterOauthVerifier && !verified) {
      verifyTwitterOauth(twitterOauthToken, twitterOauthVerifier)
        .then((twitterResults) => {
          setVerified(true);
          window.opener.postMessage(
            {
              data: twitterResults
            },
            '*'
          );
          window.close();
        })
        .catch((err) => {
          newToast({
            status: 'error',
            message: process.env.NODE_ENV !== 'production' ? err.message : 'There was an issue creating this connection'
          });
        });
    }

    if (!twitterOauthToken || !twitterOauthVerifier) {
      navigate('/', { replace: true });
    }
  }, []);

  return <VerifyOauth title="Twitter Verification" />;
};

export default VerifyTwitter;
