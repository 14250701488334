import React, { useState, useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { useAppContext } from '../../hooks/useAppContext';
import DashboardLayout from '../../containers/DashboardLayout';
import PageHeader from '../../containers/PageHeader';
import SortedTable from '../../components/SortingTable';
import Loader from '../../components/Loader';
import { getAllConnections, updateConnectionStatus, initiateSync } from '../../api/connection';
import helpers from '../../utils/helpers';

const AdminConnections = () => {
  const { user } = useAppContext();
  const isAdmin = user?.isMediasweetAdmin || false;
  const [connectionList, setConnectionList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!connectionList.length && isLoading) {
      getAllConnections().then((connections) => {
        connections = connections.map((connection) => {
          return {
            id: connection._id,
            userName: connection.userName,
            platform: connection.platform,
            displayName: connection.displayName,
            agencyName: connection?.agencyName ? connection?.agencyName : connection?.agencyId, // fall back to agency id if we cant find agency name
            createdAt: helpers.formatDate(connection.createdAt),
            updatedAt: helpers.formatDate(connection.updatedAt),
            disable: (
              <UpdateButton
                connection={connection}
                status={connection.status}
              />
            ),
            resync: <ResyncButton connection={connection} />
          };
        });

        setConnectionList(connections);
        setIsLoading(false);
      });
    }
  }, []);

  const updateStatus = (connectionId, status) => {
    updateConnectionStatus(connectionId, status).then(() => {
      getAllConnections().then((connections) => {
        setConnectionList(connections);
      });
    });
  };

  const UpdateButton = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    let statusToChange = '',
      buttonText = '';
    if (!props?.status || props?.status === 'disabled') {
      buttonText = 'Enable';
      statusToChange = 'enabled';
    } else if (props?.status === 'enabled') {
      buttonText = 'Disable';
      statusToChange = 'disabled';
    }

    const startUpdate = (id) => {
      setIsLoading(true);
      updateStatus(id, statusToChange)
        .then((result) => setIsLoading(false))
        .catch((err) => alert(err));
    };

    return (
      <Button
        isLoading={isLoading}
        onClick={() => startUpdate(props.connection._id)}
        border={'1px'}
        padding={3}
        bg="gray.100"
        color="blue.800"
        boxShadow="base"
        height="30px"
      >
        {buttonText}
      </Button>
    );
  };

  const ResyncButton = ({ connection }) => {
    const [isLoading, setIsLoading] = useState(false);

    const startSync = (id) => {
      setIsLoading(true);
      initiateSync(id)
        .then((result) => setIsLoading(false))
        .catch((err) => console.log(err));
      //TODO: Make sure the API returns an error if this is not doable so we can expose this to the admin client
    };

    return (
      <Button
        isLoading={isLoading}
        onClick={() => startSync(connection._id, connection.status)}
        border={'1px'}
        padding={3}
        bg="gray.100"
        color="blue.800"
        boxShadow="base"
        height="30px"
      >
        Resync
      </Button>
    );
  };

  return (
    <DashboardLayout page="connections">
      <PageHeader title="Connections" />

      <Loader
        text="Loading Connections..."
        isLoaded={!isLoading && connectionList.length != 0}
      >
        <SortedTable rows={connectionList} />
      </Loader>
    </DashboardLayout>
  );
};

export default AdminConnections;
