import { Text, Box, Image } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faYoutube,
  faSpotify,
  faSnapchat,
  faTiktok,
  faTwitch,
  faXTwitter
} from '@fortawesome/free-brands-svg-icons';
import kickLogo from '../assets/logos/thirdparty/kick-logo-green-k.png';
import metaLogo from '../assets/logos/thirdparty/meta-logo-blue-infinity.png';

const helpers = {
  getSocialName: (platform) => {
    if (!platform) return;
    platform = platform.toLowerCase();
    switch (platform) {
      case 'youtube':
        return 'YouTube';
      case 'tiktok':
        return 'TikTok';
      case 'twitter':
        return 'X/Twitter';
      default:
        return platform.charAt(0).toUpperCase() + platform.slice(1);
    }
  },
  getSocialIcon: (platform, color, size) => {
    if (!platform) return;
    platform = platform.toLowerCase();
    let imgSize = '28px';

    switch (platform) {
      case 'meta':
        switch (size) {
          case 'xl':
            imgSize = '28px';
            break;
          case 'lg':
            imgSize = '18px';
            break;
          case 'md':
            imgSize = '12px';
            break;
          default:
            imgSize = size;
        }
        return (
          <Image
            src={metaLogo}
            objectFit="cover"
            h={imgSize}
            maxH="48px"
            top={size === 'lg' ? '5px' : '2px'}
            position="relative"
            display="inline-block"
          />
        );

      case 'facebook':
        return (
          <Text
            fontSize={size || 'inherit'}
            as="span"
            color={color || 'platform.facebook'}
          >
            <FontAwesomeIcon icon={faFacebook} />
          </Text>
        );

      case 'instagram':
        return (
          <Text
            fontSize={size || 'inherit'}
            as="span"
            color={color || 'platform.instagram'}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </Text>
        );

      case 'kick':
        switch (size) {
          case 'xl':
            imgSize = '28px';
            break;
          case 'lg':
            imgSize = '18px';
            break;
          case 'md':
            imgSize = '12px';
            break;
        }
        return (
          <Image
            src={kickLogo}
            objectFit="cover"
            h={imgSize}
            maxH="28px"
            top={size === 'lg' ? '5px' : '2px'}
            position="relative"
            display="inline-block"
          />
        );

      case 'snapchat':
        return (
          <Text
            fontSize={size || 'inherit'}
            as="span"
            color={color || 'platform.snapchat'}
          >
            <FontAwesomeIcon icon={(faInstagram, faSnapchat)} />
          </Text>
        );

      case 'spotify':
        return (
          <Text
            fontSize={size || 'inherit'}
            as="span"
            color={color || 'platform.spotify'}
          >
            <FontAwesomeIcon icon={faSpotify} />
          </Text>
        );

      case 'tiktok':
        return (
          <Text
            fontSize={size || 'inherit'}
            as="span"
            color={color || 'platform.tiktok'}
          >
            <FontAwesomeIcon icon={faTiktok} />
          </Text>
        );

      case 'twitch':
        return (
          <Text
            fontSize={size || 'inherit'}
            as="span"
            color={color || 'platform.twitch'}
          >
            <FontAwesomeIcon icon={faTwitch} />
          </Text>
        );

      case 'twitter':
      case 'x':
        return (
          <Text
            fontSize={size || 'inherit'}
            as="span"
            color={color || 'platform.twitter'}
          >
            <FontAwesomeIcon icon={faXTwitter} />
          </Text>
        );

      case 'youtube':
        return (
          <Text
            fontSize={size || 'inherit'}
            as="span"
            color={color || 'platform.youtube'}
          >
            <FontAwesomeIcon icon={faYoutube} />
          </Text>
        );
      default:
        break;
    }
  },
  formatDate: (date, time = true) => {
    date = new Date(date);
    let month = date.getMonth();
    let allMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    month = allMonths[month];
    const day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
    const hours = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours();
    const minutes = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();
    let year = date.getFullYear();
    if (year === new Date().getFullYear()) {
      year = '';
    } else {
      year = date.getFullYear().toString().slice(2, 4);
    }
    let returnString = `${month}/${day}${year ? `/${year}` : year}`;
    if (time) returnString += ` at ${hours}:${minutes}`;
    return returnString;
  },
  formatNumber: (number, short = false) => {
    if (!number) return 0;
    const numberAsString = Number(number).toLocaleString();

    if (!short) {
      return Number(number) ? numberAsString : Number(0);
    }

    const shortNumber =
      numberAsString.split(',')[0] +
      (Number(numberAsString.split(',')[1]) ? '.' + numberAsString.split(',')[1][0] : '');

    if (Number(number).length >= 10) {
      return shortNumber + 'B';
    } else if (String(number).length >= 7) {
      return shortNumber + 'M';
    } else if (String(number).length >= 4) {
      return shortNumber + 'K';
    } else {
      return numberAsString;
    }
  },
  formatTime: (ms) => {
    const seconds = ms / 1000;
    const hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return hours + ':' + minutes + ':' + '00';
  }
};

export default helpers;
