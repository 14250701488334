import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyYoutubeOauth } from '../../api/connection';
import VerifyOauth from '../../components/VerifyOauth';

const VerifyYoutube = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const youtubeCode = searchParams.get('code');

  useEffect(() => {
    if (youtubeCode && !verified) {
      verifyYoutubeOauth(youtubeCode).then((youtubeResults) => {
        setVerified(true);
        window.opener.postMessage(
          {
            data: youtubeResults
          },
          '*'
        );
        window.close();
      });
    }

    if (!youtubeCode) {
      navigate('/', { replace: true });
    }
  }, []);

  return <VerifyOauth title="Youtube Verification" />;
};

export default VerifyYoutube;
