import { get, patch, post, remove } from '../utils/HttpsHandlers';

export const getMediakit = (slug) => {
  return get(`/mediakit?slug=${slug}`, localStorage.getItem('jwt'));
};

export const getMediakitList = (slug) => {
  const token = localStorage.getItem('jwt');
  let url = `/mediakit/list`;
  if (slug) {
    url += `?slug=${slug}`;
  }
  return get(url, token);
};

export const createMediaKitList = (newMediaKitList) => {
  return post(`/mediakit/list`, newMediaKitList, localStorage.getItem('jwt'));
};

export const updateMediaKit = (id, newMediaKit) => {
  return patch(`/mediakit/${id}`, newMediaKit, localStorage.getItem('jwt'));
};

export const createMediaKit = (newMediaKit) => {
  return post(`/mediakit`, newMediaKit, localStorage.getItem('jwt'));
};

export const getUserMediakits = (userId) => {
  return get(`/mediakit?userId=${userId}`, localStorage.getItem('jwt')); // as [];
};

export const getAgencyMediakits = (agencyId) => {
  return get(`/mediakit?agencyId=${agencyId}`, localStorage.getItem('jwt')); // as [];
};

export const getAgencyListMediakits = (agencyId, listSlug) => {
  return get(`/mediakit/list?slug=${listSlug}&agencyId=${agencyId}&data=all`);
};

export const deleteUserMediakit = (mediakitId) => {
  return remove(`/mediakit/${mediakitId}`, localStorage.getItem('jwt'));
};

export const deleteAgencyListMediakit = (mediakitListSlug) => {
  return remove(`/mediakit/list/${mediakitListSlug}`, localStorage.getItem('jwt'));
};
