import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../hooks/useAppContext';

import {
  Box,
  Center,
  Grid,
  GridItem,
  Step,
  Stepper,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepTitle,
  StepStatus,
  StepDescription,
  StepIcon,
  Heading
} from '@chakra-ui/react';
import { getUserConnections, getAgencyConnections } from '../api/connection';
import { createMediaKit } from '../api/mediakit';
import { getUploadUrl } from '../api/user';
import { uploadS3Image } from '../api/s3';
import Header from '../containers/Header';
import Step1 from '../containers/CreateMediaKitStep1';
import Step2 from '../containers/CreateMediaKitStep2';
import Step3 from '../containers/CreateMediaKitStep3';
import { useToastHook } from '../components/Toast';
import { CONSTANTS } from '../constants';

const CreateMediaKit = () => {
  const [state, newToast] = useToastHook();
  const { user, userMediaKits, setUserMediaKits, userAgency, userAgencyConnections } = useAppContext();
  const [activeStep, setActiveStep] = useState(1);
  const navigate = useNavigate();
  const steps = [
    { title: 'About You', description: 'Headshot and bio' },
    { title: 'Platforms', description: 'Add your social media' },
    { title: 'Publish', description: 'Make final edits' }
  ];
  const [newMediaKit, setNewMediaKit] = useState({
    theme: {
      name: 'default',
      colors: {
        primary: 'red',
        secondary: 'pink'
      }
    },
    displayName: '',
    slug: '',
    bio: '',
    tags: [],
    headshot: '',
    pageDisplay: {}
  });
  const [pageDisplay, setPageDisplay] = useState({
    main: [
      {
        section: 'summary',
        title: 'Overview',
        panels: [
          {
            component: 'stat',
            label: 'total_reach',
            title: 'Total Reach'
          },
          {
            component: 'stat',
            label: 'platform_primary',
            title: 'Primary Platform'
          },
          {
            component: 'stat',
            label: 'agency',
            title: 'Management'
          }
        ]
      }
    ],
    secondary: []
  });
  const [headshotBlob, setHeadshotBlob] = useState('');
  const [userConnections, setUserConnections] = useState([]);
  const [mediaKitConnections, setMediaKitConnections] = useState([]);
  const [selectedConnections, setSelectedConnections] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (userAgencyConnections) {
      setUserConnections(userAgencyConnections);
    } else if (user.agencyId) {
      getAgencyConnections(user.agencyId).then((connections) => {
        setUserConnections(connections);
      });
    } else {
      getUserConnections().then((connections) => {
        setUserConnections(connections);
      });
    }
  }, []);

  const changeStep = (step) => {
    return setActiveStep(activeStep + step);
  };

  const populateMediaKitConnections = () => {
    let platforms = [];
    let secondary = [];
    mediaKitConnections.forEach((connection) => {
      platforms.push({ name: connection.platform, id: connection._id });

      let insertPlatform = CONSTANTS.PLATFORM_PANELS[connection.platform];
      insertPlatform = { ...insertPlatform, connectionId: connection._id };
      secondary.push(insertPlatform);
    });

    setMediaKitConnections([...platforms]);

    const mediaKitConnectionData = {
      platforms,
      pageDisplay: {
        ...pageDisplay,
        secondary
      }
    };

    setPageDisplay({
      ...pageDisplay,
      secondary
    });

    //setNewMediaKit({ ...newMediaKit, ...mediaKitConnectionData });
    return { ...mediaKitConnectionData };
  };

  const submitMediaKit = async () => {
    setIsSubmitting(true);
    let insertData = {
      ...newMediaKit,
      pageDisplay,
      platforms: mediaKitConnections
    };

    if (mediaKitConnections.length) {
      const platformData = populateMediaKitConnections(mediaKitConnections);
      insertData = { ...insertData, ...platformData };
    }

    if (insertData.headshot) {
      try {
        const urls = await getUploadUrl(`users/${user._id}/headshots`, insertData.headshot.name);
        const { signedUrl, publicUrl } = urls;
        const uploadImage = await uploadS3Image(signedUrl, insertData.headshot);
        if (uploadImage) {
          insertData.headshot = publicUrl;
        }
      } catch (err) {
        //setHeadshotError(true);
        changeStep(-2);
        newToast({
          status: 'error',
          message: err?.message || 'There was an issue creating your media kit.',
          duration: 5000
        });
        setIsSubmitting(false);
        return;
      }
    }

    createMediaKit(insertData)
      .then(async (data) => {
        await setUserMediaKits([...userMediaKits, { ...insertData, _id: data._id }]);
        navigate(`/${userAgency.slug}/${data.slug}`, { replace: true });
      })
      .catch((err) => {
        newToast({
          status: 'error',
          message: err?.message || 'There was an issue creating your media kit.',
          duration: 5000
        });
        changeStep(-2);
        setIsSubmitting(false);
      });
  };

  return (
    <Center>
      <Box
        borderRadius="lg"
        width="8xl"
        minW="60rem"
        bg="white"
        m="4"
      >
        <Grid
          templateAreas={`"header header""main main""footer footer"`}
          gridTemplateRows={'75px 1fr 30px'}
          gridTemplateColumns={'250px 1fr'}
          gap="0"
          m="4"
          fontWeight="bold"
        >
          <Header />

          <GridItem
            pl="2"
            bg="brand.white"
            area={'main'}
          >
            <Center>
              <Box mb={3}>
                <Heading
                  textAlign={['left']}
                  fontSize={'3xl'}
                >
                  Create a media kit
                </Heading>
              </Box>
            </Center>

            <Center>
              <Box>
                <Stepper
                  mb={5}
                  width={'3xl'}
                  index={activeStep}
                >
                  {steps.map((step, index) => (
                    <Step key={index}>
                      <StepIndicator>
                        <StepStatus
                          complete={<StepIcon />}
                          incomplete={<StepNumber />}
                          active={<StepNumber />}
                        />
                      </StepIndicator>

                      <Box flexShrink="0">
                        <StepTitle>{step.title}</StepTitle>
                        <StepDescription>{step.description}</StepDescription>
                      </Box>

                      <StepSeparator />
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Center>

            <Center>
              {(activeStep === 1 && (
                <Step1
                  headshotBlob={headshotBlob}
                  newMediaKit={newMediaKit}
                  setHeadshotBlob={setHeadshotBlob}
                  setNewMediaKit={setNewMediaKit}
                  changeStep={changeStep}
                />
              )) ||
                (activeStep === 2 && (
                  <Step2
                    userAgency={userAgency}
                    newMediaKit={newMediaKit}
                    userConnections={userConnections}
                    user={user}
                    mediaKitConnections={mediaKitConnections}
                    selectedConnections={selectedConnections}
                    setSelectedConnections={setSelectedConnections}
                    setMediaKitConnections={setMediaKitConnections}
                    setNewMediaKit={setNewMediaKit}
                    setUserConnections={setUserConnections}
                    changeStep={changeStep}
                  />
                )) ||
                (activeStep === 3 && (
                  <Step3
                    isSubmitting={isSubmitting}
                    submitMediaKit={submitMediaKit}
                    changeStep={changeStep}
                  />
                ))}
            </Center>
          </GridItem>
        </Grid>
      </Box>
    </Center>
  );
};

export default CreateMediaKit;
