import { GridItem, Box, HStack, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import logoPng from '../assets/logos/media-sweet-logo.png';
import { useAppContext } from '../hooks/useAppContext';

const Header = (props) => {
  const { user } = useAppContext();
  const isAdmin = user?.isMediasweetAdmin;

  return (
    <GridItem
      pl={2}
      bg="brand.white"
      area={'header'}
    >
      <HStack>
        <Link to="/">
          <Image
            src={logoPng}
            m="2"
            objectFit="cover"
            height="35px"
            display="inline-block"
          />
        </Link>
        <Box height="35px">
          {isAdmin && (
            <Text
              color="red.600"
              as="i"
              fontSize="xl"
            >
              Admin Dashboard
            </Text>
          )}
        </Box>
      </HStack>
    </GridItem>
  );
};

export default Header;
