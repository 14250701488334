import { get, remove, patch, post } from '../utils/HttpsHandlers';

export const getAllConnections = () => {
  return get(`/connection?all=true`, localStorage.getItem('jwt'));
};

export const getAgencyConnections = (id, range) => {
  let apiString = `/connection?agencyId=${id}`;
  if (range) {
    apiString += `&range=${range}`;
  }
  return get(apiString, localStorage.getItem('jwt'));
};

export const getUserConnections = (range) => {
  let apiString = `/connection`;
  if (range) {
    apiString += `?range=${range}`;
  }
  return get(`/connection`, localStorage.getItem('jwt'));
};

export const getConnectionById = (id = [], range) => {
  if (!id.length) return;
  let apiString = `/connection?id=${id.join(',')}`;
  if (range) {
    apiString += `&range=${range}`;
  }
  return get(apiString, localStorage.getItem('jwt'));
};

export const getUserConnectionsByToken = (token) => {
  if (!token) return;
  return get(`/connection?token=${token}`);
};

export const getPlatformConnection = (platform) => {
  if (!platform) return;
  return get(`/connection/oauth/${platform}`, localStorage.getItem('jwt'));
};

export const createConnection = ({ platform, userName }) => {
  const token = localStorage.getItem('invite-token');
  if (!platform || !userName) return;
  let apiString = '/connection';
  if (token) {
    apiString += `?token=${token}`;
  }
  return post(apiString, { userName, platform }, localStorage.getItem('jwt'));
};

export const deleteConnectionByUser = (connectionId) => {
  if (!connectionId) return;
  return remove(`/connection/${connectionId}`, localStorage.getItem('jwt'));
};

export const deleteConnectionByToken = (connectionId, token) => {
  if (!connectionId || !token) return;
  return remove(`/connection/${connectionId}?token=${token}`);
};

export const verifyTwitchOauth = (code) => {
  const token = localStorage.getItem('invite-token');
  return get(`/connection/oauth/twitch/verify?&code=${code}&token=${token}`, localStorage.getItem('jwt')); // as [];
};

export const verifyFacebookOauth = (code) => {
  const token = localStorage.getItem('invite-token');
  return get(`/connection/oauth/facebook/verify?&code=${code}&token=${token}`, localStorage.getItem('jwt')); // as [];
};

export const verifyInstagramOauth = (code) => {
  const token = localStorage.getItem('invite-token');
  return get(`/connection/oauth/instagram/verify?&code=${code}&token=${token}`, localStorage.getItem('jwt')); // as [];
};

export const verifyYoutubeOauth = (code) => {
  const token = localStorage.getItem('invite-token');
  return get(`/connection/oauth/youtube/verify?&code=${code}&token=${token}`, localStorage.getItem('jwt')); // as [];
};

export const verifyTikTokOauth = (code, state) => {
  const token = localStorage.getItem('invite-token');
  return get(`/connection/oauth/tiktok/verify?code=${code}&token=${token}`, localStorage.getItem('jwt')); // as [];
};

export const verifyTwitterOauth = (oauth_token, oauth_verifier) => {
  const token = localStorage.getItem('invite-token');
  return get(
    `/connection/oauth/twitter/verify?&oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}&token=${token}`,
    localStorage.getItem('jwt')
  ); // as [];
};

export const updateConnectionStatus = (connectionId, status) => {
  if (!connectionId) return;
  return patch(`/connection/${connectionId}`, { status }, localStorage.getItem('jwt'));
};

export const initiateSync = (connectionId) => {
  if (!connectionId) return;
  return post(`/connection/sync`, { connectionId }, localStorage.getItem('jwt'));
};
