import { useState, useEffect } from 'react';
import { useAppContext } from '../hooks/useAppContext';
import { useNavigate } from 'react-router-dom';
import { getUserMediakits, getAgencyMediakits } from '../api/mediakit';
import DashboardLayout from '../containers/DashboardLayout';
import PageHeader from '../containers/PageHeader';
import MediaKitCards from '../containers/MediaKitCards';
import MediaKitListSelector from '../components/DashboardMediaKitListSelector';
import Loader from '../components/Loader';
import { useToastHook } from '../components/Toast';

const Home = () => {
  const [state, newToast] = useToastHook();
  const navigate = useNavigate();
  const { user, userAgency, userAgencyProduct, userMediaKits, setUserMediaKits, userAgencyConnections } =
    useAppContext();
  const isAdmin = user?.isMediasweetAdmin;

  const [activeList, setActiveList] = useState('all');
  const [mode, setMode] = useState('view'); //view|createList
  const [selectedMediaKits, setSelectedMediaKits] = useState(new Set([]));
  const [mediaKitsFilter, setMediaKitsFilter] = useState({});
  const [cardsLoaded, setCardsloaded] = useState(false);

  const canCreate =
    (!userAgencyProduct || userAgencyProduct?.mediakits > userMediaKits.length || userMediaKits.length <= 0) &&
    !mediaKitsFilter.ids;

  useEffect(() => {
    if (isAdmin) {
      navigate('/admin/connections', { replace: true });
    }

    if (user?.agencyId && !userMediaKits?.length) {
      getAgencyMediakits(user.agencyId)
        .then((mediakits) => {
          if (mediakits?.length) {
            setUserMediaKits(mediakits);
          }
          setCardsloaded(true);
        })
        .catch((err) => {
          newToast({
            status: 'error',
            message: 'There was an error fetching media kits.'
          });
        });
    } else if (user?.id && !userMediaKits?.length) {
      getUserMediakits(user?._id).then((mediakits) => {
        if (mediakits?.length) {
          setUserMediaKits(mediakits);
        }
        setCardsloaded(true);
      });
    } else {
      setCardsloaded(true);
    }
  }, [user]);

  // TODO move this
  const toggleMediaKitSelect = (id) => {
    if (!selectedMediaKits.has(id)) {
      selectedMediaKits.add(id);
      setSelectedMediaKits(selectedMediaKits);
    } else {
      selectedMediaKits.delete(id);
      setSelectedMediaKits(selectedMediaKits);
    }
  };

  return (
    <DashboardLayout page="mediakits">
      <PageHeader title="Media Kits" />

      <MediaKitListSelector
        setMediaKitsFilter={setMediaKitsFilter}
        activeList={activeList}
        setActiveList={setActiveList}
        selectedMediaKits={selectedMediaKits}
        setSelectedMediaKits={setSelectedMediaKits}
        mode={mode}
        setMode={setMode}
      />

      <Loader isLoaded={cardsLoaded}>
        <MediaKitCards
          toggleMediaKitSelect={toggleMediaKitSelect}
          agencySlug={userAgency?.slug ?? 'mediakit'}
          mode={mode}
          canCreate={canCreate}
          mediaKitsFilter={mediaKitsFilter}
          mediakits={userMediaKits}
          connections={userAgencyConnections}
        />
      </Loader>
    </DashboardLayout>
  );
};

export default Home;
