import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { registerAgent } from '../api/user';

import {
  Card,
  Container,
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Image,
  Stack,
  Text,
  Input,
  Heading,
  ListItem,
  Tooltip,
  UnorderedList
} from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { useToastHook } from '../components/Toast';
import logoPng from '../assets/logos/media-sweet-logo.png';

const AgencyRegister = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [registerLoading, setRegisterLoading] = useState(false);
  const [registrationPassword, setRegistrationPassword] = useState(undefined);
  const [passwordError, setPasswordError] = useState(undefined);
  const [registrationError, setRegistrationError] = useState(undefined);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const agentRegistrationToken = searchParams.get('token');
  const [state, newToast] = useToastHook();

  const setPassword = (password) => {
    if (password.length < 8) {
      setIsButtonDisabled(true);
    } else if (!/\d/.test(password)) {
      setIsButtonDisabled(true);
    } else {
      setRegistrationPassword(password);
      setIsButtonDisabled(false);
    }
  };

  const register = (e) => {
    e.preventDefault();
    setRegisterLoading(true);
    setRegistrationError(undefined);
    setPasswordError(undefined);

    let error = false;
    if (!registrationPassword) {
      setPasswordError('Password can not be empty');
      error = true;
    }

    if (registrationPassword?.length < 8) {
      setPasswordError('Password cannot be less than 8 characters');
      error = true;
    }

    if (!/\d/.test(registrationPassword)) {
      setPasswordError('Password must contain at least one number');
      error = true;
    }

    if (!error) {
      registerAgent(registrationPassword, agentRegistrationToken)
        .then(async (register) => {
          if (register?.email) {
            newToast({
              status: 'success',
              message: 'Registration successful'
            });
            setTimeout(function () {
              navigate('/', { replace: true });
            }, 3000);
          }
        })
        .catch((err) => {
          newToast({
            status: 'error',
            message: err?.message || 'Registration failure'
          });
          setRegistrationError(err?.message);
          setRegisterLoading(false);
        });
    } else {
      setRegisterLoading(false);
    }
  };

  useEffect(() => {
    if (!agentRegistrationToken) {
      navigate('/', { replace: true });
    }
  });

  return (
    <Container mt="50">
      <Card style={{ textAlign: 'center' }}>
        <Box padding="4">
          <Image
            src={logoPng}
            m="15"
            objectFit="cover"
            width="400px"
            display="inline-block"
          />

          <Box m={3}>
            <Heading
              as="h2"
              fontSize="lg"
            >
              Create agency account {register?.email ? `for ${register.email}` : ''}
            </Heading>
          </Box>
          <Box m={3}>
            <Text
              textAlign={['left']}
              fontSize={'md'}
            >
              Password Requirements:
              <UnorderedList>
                <ListItem>8 character minimum length</ListItem>
                <ListItem>Must include at least one number</ListItem>
              </UnorderedList>
            </Text>
          </Box>
          <Container maxW="sm">
            <Stack
              direction="column"
              spacing={2}
              align="center"
            >
              <form>
                <FormControl
                  isInvalid={typeof passwordError !== 'undefined' || typeof registrationError !== 'undefined'}
                >
                  <FormErrorMessage
                    fontSize="sm"
                    color="red.500"
                    mt={0}
                  >
                    {passwordError}
                  </FormErrorMessage>
                  <Input
                    id="password"
                    placeholder="Password"
                    type="password"
                    variant="outline"
                    mb={2}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </FormControl>

                <Box>
                  <Tooltip
                    isDisabled={!isButtonDisabled}
                    label="Your password must adhere to the minimum requirements"
                  >
                    <Button
                      bg="blue.200"
                      color="gray.1000"
                      mb={2}
                      type="submit"
                      isDisabled={isButtonDisabled}
                      isLoading={registerLoading}
                      onClick={(evt) => register(evt)}
                    >
                      <FontAwesomeIcon icon={faKey} />
                      {/* <Text ml={2}>Sign in with email and password</Text> */}
                      <Text ml={2}>Register</Text>
                    </Button>
                  </Tooltip>
                </Box>
              </form>

              <Divider />
            </Stack>
            <Text
              mt={5}
              fontSize="sm"
            >
              By using <b>mediasweet</b> products you agree to our <Link to="/count">Terms of Service</Link>
              &nbsp;and our{' '}
              <Link
                to="http://trymediasweet.com/privacypolicy.php"
                target="_blank"
              >
                Privacy Policy
              </Link>
            </Text>
          </Container>
        </Box>
      </Card>
    </Container>
  );
};

export default AgencyRegister;
