import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext } from '../hooks/useAppContext';
import {
  Box,
  Button,
  AbsoluteCenter,
  Center,
  Divider,
  Grid,
  GridItem,
  Wrap,
  WrapItem,
  Image,
  VStack,
  HStack,
  SimpleGrid,
  SkeletonText,
  Text,
  Heading,
  Tag,
  Tooltip,
  TagLabel
} from '@chakra-ui/react';
import { getMediakit, updateMediaKit } from '../api/mediakit';
import { getAgencyById } from '../api/agency';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatCard from '../components/StatCard';
import ChartCard from '../components/ChartCard';
import ContentCard from '../components/ContentCard';
import helpers from '../utils/helpers';
import { useToastHook } from '../components/Toast';
import { getUploadUrl } from '../api/user';
import { getConnectionById } from '../api/connection';
import HeadshotAvatar from '../components/MediaKitHeadshot';
import Bio from '../components/MediaKitBio';
import DisplayName from '../components/MediaKitDisplayName';
import SlugHeader from '../components/MediaKitSlug';
import Background from '../components/MediaKitBackground';
import EditModeFooter from '../components/MediaKitEditModeFooter';
import Footer from '../containers/Footer';
import MediaKitAddComponentButton from '../components/MediaKitAddComponentButton';
import MediaKitSectionDataPanels from '../components/MediaKitSectionDataPanels';
import ChangeColors from '../components/MediaKitChangeColors';
import ChangeBackground from '../components/MediaKitChangeBackground';
import { uploadS3Image } from '../api/s3';
import { CONSTANTS } from '../constants';
import Loader from '../components/Loader';

const MediaKit = () => {
  const navigate = useNavigate();

  const { user } = useAppContext();
  const { slug } = useParams();
  const [searchParams] = useSearchParams();

  const [mediakit, setMediakit] = useState({
    theme: {
      colors: {
        primary: 'blue',
        secondary: 'blue'
      }
    }
  });

  const [editMode, setEditMode] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);
  const [state, newToast] = useToastHook();

  const [newBackgroundImg, setNewBackgroundImg] = useState(mediakit.background?.source || '');
  const [newBackgroundVideo, setNewBackgroundVideo] = useState(mediakit.background?.source || '');
  const [newBackgroundType, setNewBackgroundType] = useState(mediakit.background?.type || 'image');
  const [newSlug, setNewSlug] = useState();
  const [newHeadshot, setNewHeadshot] = useState();
  const [newHeadshotObject, setNewHeadshotObject] = useState();
  const [newDisplayName, setNewDisplayName] = useState();
  const [newBio, setNewBio] = useState();
  // const [newContactText, setNewContactText] = useState(mediakit.contactText||"");
  // const [newContactUrl, setNewContactUrl] = useState(mediakit.contactUrl||"");
  const [newPrimaryColor, setNewPrimaryColor] = useState(mediakit.theme.colors.primary || 'blue');
  const [newSecondaryColor, setNewSecondaryColor] = useState(mediakit.theme.colors.secondary || 'pink');

  const [isSaving, setIsSaving] = useState(false);
  const [pageDisplay, setPageDisplay] = useState(mediakit.pageDisplay || {});
  const [mediaKitAgency, setMediaKitAgency] = useState({});
  const [connectionEditPopover, setConnectionEditPopover] = useState(false);
  const [mediaKitConnections, setMediaKitConnections] = useState([]);
  const [totalReach, setTotalReach] = useState(0);
  const [primaryPlatform, setPrimaryPlatform] = useState();
  const [platforms, setPlatforms] = useState();
  const [newConnections, setNewConnections] = useState([]);
  const [editEnabled, setEditEnabled] = useState(false);

  useEffect(() => {
    if (slug && !mediakit.slug) {
      getMediakit(slug)
        .then(async (mediakit) => {
          if (!mediakit || (mediakit && !mediakit.length && !mediakit?._id)) {
            navigate('/', { replace: true });
          }
          setMediakit(mediakit);
          setPageDisplay(mediakit.pageDisplay);
          setNewPrimaryColor(mediakit.theme.colors?.primary);
          setNewSecondaryColor(mediakit.theme.colors?.secondary);

          if (!Object.keys(mediaKitAgency).length && mediakit.agencyId) {
            const agency = await getAgencyById(mediakit.agencyId);
            setMediaKitAgency(agency);
          }

          if (mediakit.background?.type === 'video') {
            setNewBackgroundType('video');
            setNewBackgroundVideo(mediakit.background.source);
          } else {
            setNewBackgroundImg(mediakit.background.source);
          }

          if (
            (user?.agencyId !== undefined && user?.agencyId === mediakit?.agencyId) ||
            (mediakit?.userId !== undefined && user?._id === mediakit?.userId)
          ) {
            setEditEnabled(true);

            // only enter editmode from queryparam if we're allowed to
            if (searchParams.get('edit') === 'true' || searchParams.get('edit') === '1') {
              setEditMode(true);
            }
          }

          let connections = {};
          let runningFollowers = 0;
          let runningPlatform = mediakit?.platforms[0];
          let totalFollowers = 0;
          let runningPlatforms = [];
          const allConnections = await getConnectionById(mediakit?.platforms?.map((p) => p.id));

          allConnections.map((connection) => {
            const reach = parseInt(connection?.stats[0]?.followers) || parseInt(connection?.stats[0]?.subscribers) || 0;
            if (connection._id) {
              connections[connection._id] = connection;
              if (reach > runningFollowers) {
                runningFollowers = reach;
                runningPlatform = connection;
              }
              totalFollowers += reach;
              runningPlatforms.push({ id: connection._id, name: connection.platform });
            }
          });
          setPlatforms(runningPlatforms);
          setTotalReach(totalFollowers);
          setPrimaryPlatform(runningPlatform);
          setMediaKitConnections(connections);
        })
        .catch((err) => {
          //console.error('Error loading MediaKit: ', err);
          newToast({
            status: 'error',
            message: `There was a problem loading the Media Kit`,
            duration: 5000
          });
        });
    }
  }, []);

  const reorderSection = (sectionName, arr, direction, idx) => {
    const newArr = [...arr];
    newArr.splice(idx + direction, 0, newArr.splice(idx, 1)[0]);
    setHasChanged(true);
    setPageDisplay({ ...pageDisplay, [sectionName]: newArr });
  };

  const removeConnection = (connectionId) => {
    let reach =
      totalReach -
      (mediaKitConnections[connectionId]?.stats[0]?.followers ||
        mediaKitConnections[connectionId]?.stats[0]?.subscribers);
    setTotalReach(reach);
    const newArr = platforms.filter((platform) => platform.id !== connectionId);
    setPlatforms([...newArr]);
    delete mediaKitConnections[connectionId];

    if (primaryPlatform?._id === connectionId) {
      let runningFollowers = 0;
      let runningPlatform;
      Object.keys(mediaKitConnections).forEach((key) => {
        let reach = mediaKitConnections[key].stats[0]?.followers || mediaKitConnections[key].stats[0]?.subscribers;
        if (reach > runningFollowers) {
          runningFollowers = reach;
          runningPlatform = mediaKitConnections[key];
        }
      });
      setPrimaryPlatform(runningPlatform);
    }

    setMediaKitConnections({ ...mediaKitConnections });
    pageDisplay.secondary = pageDisplay.secondary.filter((section) => section.connectionId !== connectionId);
    setPageDisplay({ ...pageDisplay });
    setHasChanged(true);
  };

  const MediaKitConnectionHeader = (props) => {
    const { sectionPanels, setSectionPanels, sectionName, idx, section, editMode } = props;

    return (
      <Box
        zIndex="1"
        width="100%"
        position="relative"
        padding="10"
      >
        <Divider />
        <AbsoluteCenter
          bg="white"
          px="4"
        >
          <HStack>
            {editMode && idx + 1 !== platforms?.length ? (
              <Tooltip label="move section down">
                <Button
                  width="20px"
                  p="0"
                  height="20px"
                  fontSize="sm"
                  boxShadow="base"
                  onClick={() => reorderSection(sectionName, pageDisplay.secondary, CONSTANTS.DIRECTION_DOWN, idx)}
                >
                  <FontAwesomeIcon icon={faArrowDown} />
                </Button>
              </Tooltip>
            ) : (
              <Box width="20px"></Box>
            )}

            {editMode ? (
              <MediaKitSectionDataPanels
                hasChanged={hasChanged}
                setHasChanged={setHasChanged}
                setSectionPanels={setSectionPanels}
                sectionPanels={sectionPanels}
                pageDisplay={pageDisplay}
                setPageDisplay={setPageDisplay}
                section={section}
                removeConnection={removeConnection}
                order={idx}
                colors={{ primary: newPrimaryColor, secondary: newSecondaryColor }}
              />
            ) : (
              <Heading fontSize="xl">
                <HStack>
                  <Box>{helpers.getSocialName(section?.platform)}</Box>{' '}
                  <Box height="23px">{helpers.getSocialIcon(section?.platform)}</Box>
                </HStack>
              </Heading>
            )}
            {editMode && idx !== 0 ? (
              <Tooltip label="move section up">
                <Button
                  width="20px"
                  p="0"
                  height="20px"
                  fontSize="sm"
                  boxShadow="base"
                  onClick={() => reorderSection(sectionName, pageDisplay.secondary, CONSTANTS.DIRECTION_UP, idx)}
                >
                  <FontAwesomeIcon icon={faArrowUp} />
                </Button>
              </Tooltip>
            ) : (
              <Box width="20px"></Box>
            )}
          </HStack>
        </AbsoluteCenter>
      </Box>
    );
  };

  const RenderStatCard = (props) => {
    let { panelIndex, totalCards, panel, connection, colors } = props;
    return (
      <StatCard
        panelIndex={panelIndex}
        totalCards={totalCards}
        mediaKitAgency={mediaKitAgency}
        panel={panel}
        connection={connection}
        totalReach={totalReach}
        primaryPlatform={primaryPlatform}
        colors={{ primary: newPrimaryColor, secondary: newSecondaryColor }}
      />
    );
  };

  const RenderSectionContent = (props) => {
    const { sectionName, section, colors, editMode, idx, length } = props;
    const [sectionPanels, setSectionPanels] = useState(section.panels);

    const dataLength = sectionPanels.filter((panel) => panel.component && panel.component !== 'content').length;
    let runningIdx = 0;

    return (
      <Box
        key={`section-${idx}`}
        zIndex="100"
        mb="4"
      >
        {sectionName !== 'main' && (
          <MediaKitConnectionHeader
            sectionPanels={sectionPanels}
            setSectionPanels={setSectionPanels}
            idx={idx}
            colors={{ primary: newPrimaryColor, secondary: newSecondaryColor }}
            editMode={editMode}
            section={section}
            mediakit={mediakit}
            sectionName={sectionName}
          />
        )}
        <Box
          px="4"
          pb="2"
          fontSize="md"
        >
          {section?.section === 'connection' && (
            <HStack
              as="span"
              px="2"
            >
              <Box width="100%">
                <HStack spacing="0">
                  {section?.platform && <Box height="18px">{helpers.getSocialIcon(section?.platform)}</Box>}

                  <Heading
                    size="sm"
                    as="span"
                    pl="2"
                    color={`${colors.secondary}.1000`}
                  >
                    {section?.platform && (
                      <Link
                        target="_blank"
                        to={mediaKitConnections[section.connectionId]?.profileUrl}
                      >
                        {mediaKitConnections[section.connectionId]?.profileUrl.replace(/(https|http):\/\/|www\./g, '')}
                      </Link>
                    )}
                  </Heading>
                </HStack>
              </Box>
              {section?.lastSynced && (
                <Box
                  width="100%"
                  align="right"
                >
                  <Text
                    as="span"
                    fontSize="xs"
                    color="gray.500"
                  >
                    Showing data from:
                  </Text>
                  <Text
                    as="span"
                    fontSize="xs"
                    color="gray.600"
                  >
                    {helpers.formatDate(section?.lastSynced)}
                  </Text>
                </Box>
              )}
            </HStack>
          )}
          {section?.section !== 'connection' && (
            <Heading
              size="md"
              as="span"
            >
              {section?.title && `${section?.title}`}
            </Heading>
          )}
        </Box>

        <Wrap
          mt="0"
          justify="center"
        >
          {sectionPanels?.map((panel, idx) => {
            if (!panel.component) return;
            runningIdx += 1;
            return (
              <WrapItem
                p={1}
                key={`${panel.component}-${idx}`}
              >
                {panel?.component === 'stat' && (
                  <RenderStatCard
                    panelIndex={runningIdx}
                    totalCards={dataLength}
                    colors={{ primary: newPrimaryColor, secondary: newSecondaryColor }}
                    panel={panel}
                    connection={mediaKitConnections[section.connectionId]}
                  />
                )}
                {panel?.component === 'cchart' && (
                  <ChartCard
                    panelIndex={runningIdx}
                    totalCards={dataLength}
                    colors={{ primary: newPrimaryColor, secondary: newSecondaryColor }}
                    panel={panel}
                    connection={mediaKitConnections[section.connectionId]}
                  />
                )}

                {panel?.component === 'content' && !!mediaKitConnections[section.connectionId]?.content.length && (
                  <VStack mx="8">
                    <Heading
                      size="sm"
                      width="100%"
                    >
                      {panel?.title && `${panel?.title}`}
                    </Heading>

                    <SimpleGrid
                      spacing="4"
                      columns={[2, null, 4]}
                    >
                      {mediaKitConnections[section.connectionId]?.content
                        .sort((a, b) => new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime())
                        .map((content, idx) => {
                          if (idx >= 4) return;
                          const contentData = mediaKitConnections[section.connectionId];
                          return (
                            <ContentCard
                              key={`${content._id}-${idx}`}
                              avatar={contentData?.thumbnailUrl || ''}
                              username={contentData?.userName || ''}
                              platform={contentData?.platform}
                              content={content}
                              colors={{ primary: newPrimaryColor, secondary: newSecondaryColor }}
                            />
                          );
                        })}
                    </SimpleGrid>
                  </VStack>
                )}
                {panel?.component === 'brand' &&
                  panel?.panels.map((brand, i) => {
                    return (
                      <Image
                        key={`brand-key-${i}`}
                        src={brand.imageSrc}
                        width="2xs"
                        px="6"
                        my="auto"
                      />
                    );
                  })}
              </WrapItem>
            );
          })}
        </Wrap>
      </Box>
    );
  };

  const submitMediakitUpdates = async () => {
    setIsSaving(true);
    let publicHeadshotUrl = mediakit.avatar;

    try {
      if (newHeadshot && newHeadshot !== mediakit.headshot) {
        const urls = await getUploadUrl(`users/${user?._id}/headshots`, newHeadshot.name);
        const { signedUrl, publicUrl } = urls;

        const uploadImage = await uploadS3Image(signedUrl, newHeadshot);
        if (uploadImage) {
          publicHeadshotUrl = publicUrl;
        }
      }

      let newBackground = { type: '', source: '' };
      if (newBackgroundType) {
        switch (newBackgroundType) {
          case 'video':
            newBackground = newBackgroundVideo;
            break;
          case 'image':
            newBackground = newBackgroundImg;
            break;
        }
      }

      const updatedData = {
        ...mediakit,
        _id: undefined,
        theme: {
          colors: {
            primary: newPrimaryColor || mediakit.theme.colors.primary,
            secondary: newSecondaryColor || mediakit.theme.colors.secondary
          }
        },
        headshot: publicHeadshotUrl || mediakit.headshot,
        background: {
          ...mediakit.background,
          source: newBackground,
          type: newBackgroundType
        },
        contactText: /* newContactText ||*/ mediakit.contactText,
        contactUrl: /* newContactUrl ||*/ mediakit.contactUrl,
        slug: newSlug || mediakit.slug,
        displayName: newDisplayName || mediakit.displayName,
        bio: newBio || mediakit.bio,
        pageDisplay: pageDisplay || mediakit.pageDisplay,
        platforms
      };

      updatedData.pageDisplay = {
        main: updatedData.pageDisplay.main.map((section) => {
          const panels = section?.panels.map((panel) => {
            return {
              label: panel.label,
              title: panel.title,
              component: panel.component
            };
          });
          return {
            section: section.section,
            title: section.title,
            platform: section.platform,
            panels: panels
          };
        }),
        secondary: updatedData.pageDisplay.secondary.map((section) => {
          const panels = section?.panels.map((panel) => {
            return {
              label: panel.label,
              title: panel.title,
              component: panel.component
            };
          });
          return {
            section: section.section,
            title: section.title,
            platform: section.platform,
            panels: panels,
            connectionId: section.connectionId
          };
        })
      };

      setPageDisplay({ ...pageDisplay });

      const response = await updateMediaKit(mediakit._id, updatedData);

      if (response) {
        setChangesSaved(true);
        setHasChanged(false);
        newToast({
          status: 'success',
          message: 'Your media kit has been updated',
          duration: 5000
        });
        if (newSlug && newSlug !== slug) {
          navigate(`/mediakits/${newSlug}`, { replace: false });
        }
      }
    } catch (err) {
      //console.log(err);
      //imageUploadError = true;
      newToast({
        status: 'error',
        message: 'There was an issue updating your media kit.',
        duration: 5000
      });
    }
    setIsSaving(false);
  };

  const addConnectionToMediaKit = async () => {
    let secondary = pageDisplay.secondary;
    let newPlatforms = [];
    let runningReach = 0;
    let runningConnections = {};

    const upsertingConnectionIds = newConnections.map((i) => i._id);

    const upsertingConnections = await getConnectionById(newConnections);

    for await (const newConnection of upsertingConnections) {
      try {
        newPlatforms.push({ name: newConnection.platform, id: newConnection._id });

        runningReach += parseInt(newConnection.stats[0]?.followers || newConnection.stats[0]?.subscribers);

        let insertPlatform = CONSTANTS.PLATFORM_PANELS[newConnection.platform];
        insertPlatform = { ...insertPlatform, connectionId: newConnection._id };

        secondary.push(insertPlatform);
        runningConnections[newConnection._id] = newConnection;
      } catch (err) {
        newToast({
          status: 'error',
          message: 'Something went wrong',
          duration: 5000
        });
      }
    }

    setMediaKitConnections({ ...mediaKitConnections, ...runningConnections });
    setPlatforms([...platforms, ...newPlatforms]);
    setTotalReach(totalReach + runningReach);
    setHasChanged(true);
    setPageDisplay({
      ...pageDisplay,
      secondary
    });
  };

  return (
    <Loader
      text="Loading mediakit..."
      isLoaded={typeof mediakit.pageDisplay?.secondary !== 'undefined'}
      height="100vh"
    >
      <Center>
        <Box
          borderRadius="lg"
          bg="white"
          my="3"
          maxW="6xl"
          minW="6xl"
        >
          <Box
            borderRadius="lg"
            bgGradient={`linear(to-b, ${newPrimaryColor}.200, ${newSecondaryColor}.100, white)`}
            px={4}
            position="relative"
            overflow="hidden"
          >
            {editMode && hasChanged && (
              <Button
                isLoading={isSaving}
                //bg={mediakit.theme.colors.secondary + '.500'}
                color="gray.100"
                bg="green.500"
                px="4"
                boxShadow="base"
                py="2"
                borderRadius="2xl"
                position="fixed"
                zIndex={10}
                right="0px"
                bottom="0px"
                m="5"
                mt="2"
                onClick={submitMediakitUpdates}
              >
                Save Changes
              </Button>
            )}

            <Background
              editMode={editMode}
              hasChanges={hasChanged}
              setHasChanged={setHasChanged}
              background={mediakit.background}
              newBackgroundImg={newBackgroundImg}
              newBackgroundVideo={newBackgroundVideo}
              newBackgroundType={newBackgroundType}
            />

            {editMode && (
              <VStack
                align="right"
                position="absolute"
                top="0px"
                right="10px"
                zIndex="10"
              >
                <ChangeBackground
                  hasChanged={hasChanged}
                  setHasChanged={setHasChanged}
                  newBackgroundType={newBackgroundType}
                  newBackgroundVideo={newBackgroundVideo}
                  setNewBackgroundImg={setNewBackgroundImg}
                  setNewBackgroundVideo={setNewBackgroundVideo}
                  setNewBackgroundType={setNewBackgroundType}
                />

                <ChangeColors
                  setHasChanged={setHasChanged}
                  setNewPrimaryColor={setNewPrimaryColor}
                  newPrimaryColor={newPrimaryColor}
                  setNewSecondaryColor={setNewSecondaryColor}
                  newSecondaryColor={newSecondaryColor}
                />
              </VStack>
            )}

            <Grid
              zIndex="2"
              position="relative"
              templateAreas={`"header header""nav main""footer footer"`}
              gridTemplateRows={'75px 1fr 30px'}
              gridTemplateColumns={'250px 1fr'}
              gap="0"
              fontWeight="bold"
            >
              <SlugHeader
                mediaKitAgency={mediaKitAgency}
                editMode={editMode}
                setNewSlug={setNewSlug}
                colors={mediakit.theme.colors}
                slug={slug}
                setHasChanged={setHasChanged}
              />

              <GridItem
                pl="2"
                area={'nav'}
              >
                <HeadshotAvatar
                  editMode={editMode}
                  setHasChanged={setHasChanged}
                  headshot={mediakit.headshot}
                  setNewHeadshot={setNewHeadshot}
                  setNewHeadshotObject={setNewHeadshotObject}
                />
                {/*
                // temp remove contact form
              <Contact
                editMode={editMode}
                setHasChanged={setHasChanged}
                colors={mediakit.theme.colors}
                contactText={mediakit.contactText}
                contactUrl={mediakit.contactUrl}
                setNewContactText={setNewContactText}
                setNewContactUrl={setNewContactUrl}
              />
              */}
              </GridItem>

              <GridItem
                px="2"
                area={'main'}
              >
                <Box px="2">
                  <DisplayName
                    editMode={editMode}
                    setHasChanged={setHasChanged}
                    setNewDisplayName={setNewDisplayName}
                    displayName={mediakit.displayName}
                  />

                  <Box
                    color="gray.900"
                    fontWeight="semibold"
                    alignItems="center"
                    letterSpacing="wide"
                    fontSize="md"
                    textTransform="uppercase"
                  >
                    <HStack
                      mx="auto"
                      spacing={4}
                      align="stretch"
                      pb="2"
                    >
                      {platforms?.map((platform, idx) => {
                        const reach =
                          mediaKitConnections[platform.id]?.stats[0]?.followers ||
                          mediaKitConnections[platform.id]?.stats[0]?.subscribers;
                        const name = mediaKitConnections[platform.id]?.platform;

                        return (
                          <VStack
                            key={`${name}-${idx}`}
                            spacing="0"
                            fontSize="lg"
                          >
                            <Box
                              as="span"
                              color={`platform.${name}`}
                            >
                              <Box
                                height="18px"
                                mx="2px"
                                mb="8px"
                              >
                                {helpers.getSocialIcon(name)}
                              </Box>
                            </Box>
                            <Box
                              mt="0"
                              as="span"
                              fontSize="sm"
                            >
                              {helpers.formatNumber(parseInt(reach), true) || ''}
                            </Box>
                          </VStack>
                        );
                      })}
                    </HStack>
                  </Box>

                  <HStack spacing={4}>
                    {mediakit.tags?.map((tag, idx) => {
                      return (
                        <Tag
                          size={'md'}
                          key={`${tag}-${idx}`}
                          variant="subtle"
                          color={`${mediakit?.theme?.colors.secondary}.700`}
                          bg={`${mediakit?.theme?.colors.secondary}.100`}
                        >
                          <TagLabel>{tag}</TagLabel>
                        </Tag>
                      );
                    })}
                  </HStack>

                  <Bio
                    editMode={editMode}
                    setNewBio={setNewBio}
                    setHasChanged={setHasChanged}
                    bio={mediakit.bio}
                  />
                </Box>
              </GridItem>
            </Grid>

            <Box>
              <SkeletonText
                isLoaded={typeof mediakit?.pageDisplay?.main !== 'undefined'}
                noOfLines={1}
                skeletonHeight={'200px'}
              >
                {pageDisplay?.main?.map((section, idx) => {
                  return (
                    <RenderSectionContent
                      sectionName="main"
                      section={section}
                      idx={idx}
                      length={mediakit?.pageDisplay?.main?.length}
                      colors={mediakit?.theme?.colors}
                      editMode={editMode}
                    />
                  );
                })}
              </SkeletonText>
            </Box>
          </Box>

          {editMode && (
            <MediaKitAddComponentButton
              mediaKitConnections={mediaKitConnections}
              selectedConnections={newConnections}
              setSelectedConnections={setNewConnections}
              onSubmit={addConnectionToMediaKit}
            />
          )}

          <Box p="2">
            <SkeletonText
              isLoaded={typeof mediakit.pageDisplay?.secondary !== 'undefined'}
              noOfLines={2}
              skeletonHeight={'200px'}
            >
              {pageDisplay?.secondary?.map((section, idx) => {
                return (
                  <RenderSectionContent
                    sectionName="secondary"
                    section={section}
                    idx={idx}
                    length={mediakit?.pageDisplay?.secondary?.length}
                    colors={mediakit?.theme?.colors}
                    editMode={editMode}
                  />
                );
              })}
            </SkeletonText>
          </Box>
          {mediaKitConnections && editEnabled && (
            <EditModeFooter
              hasChanged={hasChanged}
              setHasChanged={setHasChanged}
              editMode={editMode}
              setEditMode={setEditMode}
            />
          )}
          <Box mb="2">
            <Footer />
          </Box>
        </Box>
      </Center>
    </Loader>
  );
};

export default MediaKit;
