export const uploadS3Image = (url, file) => {
  return new Promise((resolve, reject) => {
    fetch(String(url), {
      method: 'PUT',
      cache: 'no-cache',
      credentials: 'same-origin',
      referrerPolicy: 'no-referrer',
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read'
      },
      body: file
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};
