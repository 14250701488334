import { createContext, useContext, useState } from 'react';

const NavContext = createContext();

export const NavProvider= ({children}) => {
  const [isNavOpen, setIsNavOpen] = useState(true);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  }

  return (<NavContext.Provider value={{isNavOpen, toggleNav}}>
    {children}
  </NavContext.Provider>
  )

}

export const useIsNavOpen = () => {
  return useContext(NavContext)
} 
