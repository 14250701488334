import React, { memo, useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  Input,
  FormLabel,
  FormErrorMessage,
  Table,
  TableContainer,
  TableCaption,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Text
} from '@chakra-ui/react';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import helpers from '../utils/helpers';

const SortedTable = memo(function SortedTable({ key = 0, search = true, rows = [], rowLength = 0 }) {
  const [tableData, setTableData] = useState(rows || []);
  const [tableHeaders, setTableHeaders] = useState([]); // { [id]: { type: string, order: 0||1} }
  const [tableDataFiltered, setTableDataFiltered] = useState(rows || []);

  useEffect(() => {
    const headers = {};
    if (!tableData.length || !Object.keys(tableData[0])) {
      return;
    }
    setTableData(rows)
    setTableDataFiltered(rows)
    Object.keys(tableData[0]).map((header) => {
      headers[header] = { type: 'string' };
      if (header.includes('created') || header.includes('updated')) {
        headers[header] = { type: 'date' };
      } else if (typeof tableData[0][header] === 'number') {
        headers[header] = { type: 'number' };
      } else if (typeof tableData[0][header] === 'object') {
        headers[header] = { type: 'none' };
      }
    });
    setTableHeaders(headers);
  }, [rows, rowLength]);

  /**
   * columnSort.
   *
   * @param {string} name - the name of the column being sorted
   * @param {string} type - expecting to be string, date, number
   */
  const columnSort = (name, type) => {
    if (type === 'none') return;
    const newSortOrder = tableHeaders[name].order === 'asc' ? 'desc' : 'asc';
    Object.keys(tableHeaders).map((header) => {
      delete tableHeaders[header].order;
    });
    setTableHeaders({ ...tableHeaders, [name]: { ...tableHeaders[name], order: newSortOrder } });

    let compareFunction = (a, b) => {
      if ((a[name] !== 0 && b[name] !== 0 && !a[name]) || !b[name]) {
        return 1;
      }
      switch (newSortOrder) {
        case 'asc':
          return a[name] - b[name];
        case 'desc':
          return b[name] - a[name];
        default:
          break;
      }
    };

    if (type === 'string') {
      compareFunction = (a, b) => {
        if (!a[name] || !b[name]) {
          return 1;
        }
        switch (newSortOrder) {
          case 'asc':
            return a[name].localeCompare(b[name]);
          case 'desc':
            return b[name].localeCompare(a[name]);
          default:
            break;
        }
      };
    }

    const sortedList = tableData.sort(compareFunction);
    setTableData(sortedList);
  };

  /**
   * filterResults
   *
   * @param {string} filterText - the search term to be compared against all columns
   */
  const filterResults = (filterText) => {
    if (!filterText || filterText === '') {
      setTableDataFiltered(tableData);
    } else {
      const filteredData = tableData.filter((row) => {
        let applicable = false;
        Object.keys(row).map((column) => {
          if (typeof row[column] === 'string' && row[column].includes(filterText)) {
            applicable = true;
          }
        });
        return applicable;
      });
      setTableDataFiltered([...filteredData]);
    }
  };

  return (
    <Box width="100%">
      {search && (
        <Box mb="20px">
          <FormControl isInvalid={false}>
            <Input
              width="sm"
              fontSize="14px"
              p="5px 10px"
              height="fit-content"
              onChange={(e) => {
                filterResults(e.target.value);
              }}
              placeholder="Search..."
            />
            {false && <FormErrorMessage></FormErrorMessage>}
          </FormControl>
        </Box>
      )}
      <TableContainer
        borderTop="1px"
        borderColor="gray.200"
        spacing="1"
      >
        <Table
          size="sm"
          variant="striped"
        >
          <TableCaption></TableCaption>
          <Thead>
            <Tr>
              {tableHeaders.length !== 0 &&
                Object.keys(tableHeaders).map((header, idx) => {
                  return (
                    <Th
                      cursor="pointer"
                      _hover={{
                        background: 'blue.100'
                      }}
                      onClick={() => {
                        columnSort(header, tableHeaders[header].type);
                      }}
                      key={`th-${idx}`}
                    >
                      <Text
                        display="inline"
                        mr="2"
                      >
                        {header}
                      </Text>
                      {tableHeaders[header].order === 'asc' && <FontAwesomeIcon icon={faArrowDown} />}
                      {tableHeaders[header].order === 'desc' && <FontAwesomeIcon icon={faArrowUp} />}
                    </Th>
                  );
                })}
            </Tr>
          </Thead>
          <Tbody>
            {tableData.length !== 0 &&
              tableDataFiltered.map((row, idx) => {
                return (
                  <Tr key={`tr-${idx}`}>
                    {Object.keys(row).map((cell, idx) => {
                      return (
                        <Td
                          key={`td-${idx}`}
                          margin={2}
                          padding={1.5}
                        >
                          {row[cell] === 0 ? <Text color="gray.300">--</Text> : row[cell]}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
});

export default SortedTable;
