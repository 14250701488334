import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Code, Button, FormControl, Text, Textarea } from '@chakra-ui/react';
import { useAppContext } from '../../hooks/useAppContext';
import DashboardLayout from '../../containers/DashboardLayout';
import PageHeader from '../../containers/PageHeader';
import Loader from '../../components/Loader';
import { getEmailTemplates, createEmailTemplate, updateEmailTemplate } from '../../api/product';

const EmailTemplates = () => {
  const { user } = useAppContext();
  const isAdmin = user?.isMediasweetAdmin || false;
  const [storedEmailTemplates, setStoredEmailTemplates] = useState([]);

  const instantiateObject = () => {
    getEmailTemplates().then((templates) => {
      const tmpObj = {};
      templates.map((template) => {
        tmpObj[template._id] = { ...template };
      });
      setStoredEmailTemplates(tmpObj);
    });
  };

  useEffect(() => {
    instantiateObject();
  }, []);

  const createTemplates = async () => {
    if (storedEmailTemplates.length) {
      return;
    }
    const exampleAgencyInvite = 'Visit us at: {{inviteUrl}}';
    const exampleContentCreatorInvite = 'Visit us at: {{inviteUrl}}';
    await createEmailTemplate({ body: exampleAgencyInvite, type: 'invite-agency' });
    await createEmailTemplate({ body: exampleContentCreatorInvite, type: 'invite-content-creator' });

    instantiateObject();
  };

  const handleTextChange = (e, id) => {
    const body = e.target.value;
    const updatedState = { ...storedEmailTemplates };
    updatedState[id] = { ...storedEmailTemplates[id], body };
    setStoredEmailTemplates(updatedState);
  };

  const handleSubmit = async () => {
    Object.keys(storedEmailTemplates).map(async (key) => {
      await updateEmailTemplate(storedEmailTemplates[key]._id, {
        body: storedEmailTemplates[key].body,
        type: storedEmailTemplates[key].type
      });
    });
  };
  return (
    <DashboardLayout page="templates">
      <PageHeader
        title="Email Templates"
        body={
          <Box mb={3}>
            {Object.keys(storedEmailTemplates).length !== 0 && (
              <Text mb="8px">
                Template variables available:
                <Code as="inline">&#123;&#123;&#123;inviteUrl&#125;&#125;&#125;</Code>,
                <Code as="inline">&#123;&#123;name&#125;&#125;</Code>, and
                <Code as="inline">&#123;&#123;agency&#125;&#125;</Code>
              </Text>
            )}
          </Box>
        }
      />

      <Box maxW="650px">
        <FormControl isInvalid={false}>
          {Object.keys(storedEmailTemplates).map((key) => {
            const textTitle =
              storedEmailTemplates[key].type === 'invite-agency'
                ? 'Agency Invite Email Body:'
                : 'Content Creator Invite Email Body:';
            const placeHolder =
              storedEmailTemplates[key].type === 'invite-agency'
                ? 'Enter agency invite email body here'
                : 'Enter content creator email body here';
            return (
              <Box mb="25px">
                <Text mb="8px">{textTitle}</Text>
                <Textarea
                  fontSize="sm"
                  height="180px"
                  value={storedEmailTemplates[key].body}
                  onChange={(e) => {
                    handleTextChange(e, storedEmailTemplates[key]._id);
                  }}
                  placeholder={placeHolder}
                  size="lg"
                />
              </Box>
            );
          })}

          <Box mt="3">
            {Object.keys(storedEmailTemplates).length !== 0 ? (
              <Button
                colorScheme="blue"
                px="6"
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            ) : (
              <Button
                colorScheme="blue"
                px="6"
                onClick={() => createTemplates()}
              >
                Create Templates
              </Button>
            )}
          </Box>
        </FormControl>
      </Box>
    </DashboardLayout>
  );
};

export default EmailTemplates;
