import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Editable,
  EditableInput,
  EditablePreview,
  GridItem,
  HStack,
  Image,
  Text,
  Heading
} from '@chakra-ui/react';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SlugHeader = (props) => {
  const { editMode, mediaKitAgency, slug } = props;

  const [newSlug, setSlug] = useState(slug);
  const [textAreaFocus, setTextAreaFocus] = useState(false);

  const updateSlug = (value) => {
    value = value.replace(' ', '');
    setSlug(value);
    props.setNewSlug(value);
  };

  return (
    <GridItem
      pl={2}
      mt={3}
      area={'header'}
    >
      <HStack>
        {/*!!Object.keys(mediaKitAgency).length && (
          <Link to="/">
            <Image
              ml="4"
              mb="3"
              borderRadius="lg"
              boxSize="135px"
              height="45px"
              objectFit="cover"
              src={mediaKitAgency?.logo}
            ></Image>
          </Link>
        )*/}
        <Heading
          as="h1"
          m="3"
          size="md"
          noOfLines={1}
        >
          <Text
            as="span"
            color="pink.500"
          >
            <Link to="/">mediasweet.com</Link>
          </Text>
          <Text
            as="span"
            color={`${props.colors.primary}.600`}
          >
            {'/' + ( mediaKitAgency.slug ?? 'mediakits')}
          </Text>

          {!editMode ? (
            <Text
              as="span"
              color="gray.1000"
            >
              /{props?.slug}
            </Text>
          ) : (
            <Box
              as="span"
              width="100%"
            >
              <Text
                as="span"
                color="gray.1000"
              >
                /
              </Text>
              <Editable
                as="span"
                defaultValue={newSlug}
                value={newSlug}
                fontSize="xl"
              >
                <EditablePreview
                  id="slug-textarea"
                  cursor="pointer"
                  m={0}
                  p={0}
                />
                <EditableInput
                  fontSize="xl"
                  width="fit-content"
                  height="100%"
                  m={0}
                  p={0}
                  onChange={(e) => {
                    //updateSlug(e.target.value);
                    setSlug(e.target.value);
                    props.setHasChanged(true);
                  }}
                  onBlur={(e) => {
                    if (newSlug || newSlug != '') {
                      updateSlug(newSlug);
                    }
                    setTextAreaFocus(false);
                  }}
                  onFocus={() => setTextAreaFocus(true)}
                />
                {!textAreaFocus && (
                  <Box
                    cursor="pointer"
                    as="span"
                    ml="2"
                    color="blue.800"
                    fontSize="xl"
                    onClick={() => document.getElementById('slug-textarea').focus()}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </Box>
                )}
              </Editable>
            </Box>
          )}
        </Heading>
      </HStack>
    </GridItem>
  );
};

export default SlugHeader;
