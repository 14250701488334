import { useState } from 'react';
import helpers from '../utils/helpers';
import { Button, Center, Box, Container, Text, Tooltip, SimpleGrid, Spinner } from '@chakra-ui/react';
import { getPlatformConnection } from '../api/connection';
import CreateNewConnectionModal from './CreateNewConnectionModal';
import { CONSTANTS } from '../constants';
import { useToastHook } from '../components/Toast';

const CreateNewConnectionButtons = ({ token, userAgency, fetchConnections }) => {
  const [_, newToast] = useToastHook();
  const availablePlatforms = [
    { name: 'meta', type: 'oauth' },
    { name: 'kick', type: 'username' },
    { name: 'tiktok', type: 'oauth' },
    { name: 'twitch', type: 'oauth' },
    { name: 'twitter', type: 'oauth' },
    { name: 'youtube', type: 'oauth' }
  ];

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [createModalPlatform, setCreateModalPlatform] = useState('');

  const [loading, setLoading] = useState(false);
  const [popupError, setPopupError] = useState('');

  const getOauthUrl = async (e, platform) => {
    const platformWindow = window.open('');
    setLoading(true);
    setPopupError('');

    getPlatformConnection(platform)
      .then((oauthUrl) => {
        platformWindow.location = oauthUrl;

        window.addEventListener('message', function (event) {
          if (event.origin !== CONSTANTS.EVENT_ORIGIN_URL) {
            return;
          }
          fetchConnections({ userAgency, token });
        });
      })
      .catch((err) => {
        newToast({
          status: 'error',
          message:
            process.env.NODE_ENV !== 'production'
              ? err
              : 'There was an issue creating this connection, please try again'
        });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Container
      align="center"
      position="relative"
      display="flex"
      justifyContent="center"
    >
      <CreateNewConnectionModal
        inviteToken={token}
        fetchConnections={fetchConnections}
        userAgency={userAgency}
        platform={createModalPlatform}
        isOpen={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />

      {loading && (
        <Box
          position="absolute"
          top="0"
          zIndex="2"
          alignItems="center"
          height="100%"
          display="flex"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
            marginRight="0.5rem"
          />
          {/* <p>Loading...</p> */}
        </Box>
      )}
      <Box
        filter={loading && 'auto'}
        blur={loading && '3px'}
        pointerEvents={loading && 'none'}
      >
        <SimpleGrid
          columns={[2, 3, null, 3]}
          spacing={4}
          justify="center"
        >
          {availablePlatforms.map((platform) => {
            return (
              <Box key={platform.name}>
                <Button
                  minWidth="120px"
                  width="100%"
                  px="2"
                  py="6"
                  borderColor={`platform.${platform.name}`}
                  border="1px"
                  boxShadow="base"
                  colorScheme={`platform.${platform.name}`}
                  color={`platform.${platform.name}`}
                  bg="gray.50"
                  align="center"
                  onClick={(e) => {
                    if (platform.type === 'oauth') {
                      getOauthUrl(e, platform.name);
                    } else if (platform.type === 'username') {
                      setCreateModalPlatform(platform.name);
                      setCreateModalOpen(true);
                    }
                  }}
                  size="full"
                  aria-label={`Connect ${platform.name}`}
                >
                  <Box align="center">
                    <Box fontSize="4xl">{helpers.getSocialIcon(platform.name, undefined, '32px')}</Box>
                    <Text
                      mt="1"
                      fontSize="xs"
                      color="gray.600"
                    >
                      {helpers.getSocialName(platform.name)}
                    </Text>
                  </Box>
                </Button>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
    </Container>
  );
};

export default CreateNewConnectionButtons;
