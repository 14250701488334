const REACT_APP_API_DEV_URL = 'http://localhost:3000/api';
const REACT_APP_API_STAGING_URL = 'https://g0lco8mfaa.execute-api.us-west-2.amazonaws.com/api';
const REACT_APP_API_PROD_URL = 'https://api.mediasweet.com/api';
const EVENT_ORIGIN_DEV_URL = 'http://localhost:1337';
const EVENT_ORIGIN_STAGING_URL = 'http://sweet-bucket-sweet-media-frontend-staging.s3-website-us-west-2.amazonaws.com';
const EVENT_ORIGIN_PROD_URL = 'https://mediasweet.com';

let base_url = REACT_APP_API_DEV_URL;
let event_url = EVENT_ORIGIN_DEV_URL;

//let base_url = REACT_APP_API_STAGING_URL;
//let event_url = EVENT_ORIGIN_STAGING_URL;

if (process.env.REACT_APP_ENV === 'prod') {
  base_url = REACT_APP_API_PROD_URL;
  event_url = EVENT_ORIGIN_PROD_URL;
}

if (process.env.REACT_APP_ENV === 'staging') {
  base_url = REACT_APP_API_STAGING_URL;
  event_url = EVENT_ORIGIN_STAGING_URL;
}

export const CONSTANTS = {
  REACT_APP_API_BASE_URL: base_url,
  EVENT_ORIGIN_URL: event_url,
  DIRECTION_UP: -1,
  DIRECTION_DOWN: 1,
  PLATFORM_PANELS: {
    facebook: {
      section: 'connection',
      platform: 'facebook',
      title: 'Facebook Overview',
      panels: [
        {
          component: 'stat',
          label: 'followers',
          title: 'Total Followers'
        },
        {
          component: 'stat',
          label: 'fans',
          title: 'Total Fans'
        },
        {
          component: 'stat',
          label: 'avg_engagement',
          title: 'Average Engagement'
        },
        {
          component: 'cchart',
          label: 'age_dist',
          title: 'Age Distribution'
        },
        {
          component: 'cchart',
          label: 'gender_dist',
          title: 'Gender Distribution'
        },
        {
          component: 'cchart',
          label: 'country_dist',
          title: 'Country Distribution'
        },
        {
          component: 'content',
          label: 'latest_posts',
          title: 'Latest Posts'
        }
      ]
    },
    instagram: {
      section: 'connection',
      platform: 'instagram',
      title: 'Instagram Overview',
      panels: [
        {
          component: 'stat',
          label: 'followers',
          title: 'Total Followers'
        },
        {
          component: 'stat',
          label: 'reach_28',
          title: 'Reach'
        },
        {
          component: 'stat',
          label: 'impressions_28',
          title: 'Impressions'
        },
        {
          component: 'stat',
          label: 'avg_engagement',
          title: 'Average Engagement'
        },
        {
          component: 'cchart',
          label: 'age_dist',
          title: 'Age Distribution'
        },
        {
          component: 'cchart',
          label: 'gender_dist',
          title: 'Gender Distribution'
        },
        {
          component: 'cchart',
          label: 'country_dist',
          title: 'Country Distribution'
        },
        {
          component: 'content',
          label: 'latest_posts',
          title: 'Latest Posts'
        }
      ]
    },
    kick: {
      section: 'connection',
      platform: 'kick',
      title: 'Kick Overview',
      panels: [
        {
          component: 'stat',
          label: 'followers',
          title: 'Total Followers'
        },
        {
          component: 'stat',
          label: 'average_views',
          title: 'Average Views'
        },
        {
          component: 'content',
          label: 'latest_posts',
          title: 'Latest Posts'
        }
      ]
    },
    tiktok: {
      section: 'connection',
      platform: 'tiktok',
      title: 'TikTok Overview',
      panels: [
        {
          component: 'stat',
          label: 'followers',
          title: 'Total Followers'
        },
        {
          component: 'stat',
          label: 'total_likes',
          title: 'Total Likes'
        },
        {
          component: 'stat',
          label: 'total_posts',
          title: 'Total Posts'
        },
        {
          component: 'content',
          label: 'latest_posts',
          title: 'Latest Posts'
        }
      ]
    },
    twitter: {
      section: 'connection',
      platform: 'twitter',
      title: 'Twitter Overview',
      panels: [
        {
          component: 'stat',
          label: 'followers',
          title: 'Total Followers'
        },
        {
          component: 'stat',
          label: 'total_posts',
          title: 'Total Tweets'
        }
        /*{
          component: 'stat',
          label: 'avg_engagement',
          title: 'Average Engagement'
        },
        {
          component: 'content',
          label: 'latest_posts',
          title: 'Latest Tweets'
        }*/
      ]
    },
    twitch: {
      section: 'connection',
      platform: 'twitch',
      title: 'Twitch Overview',
      panels: [
        {
          component: 'stat',
          label: 'followers',
          title: 'Total Followers'
        },
        {
          component: 'stat',
          label: 'subscribers',
          title: 'Subscribers'
        },
        /*{
          component: 'stat',
          label: 'total_views',
          title: 'Total Views'
        },*/
        {
          component: 'content',
          label: 'popular_posts',
          title: 'Top Clips'
        }
      ]
    },
    youtube: {
      section: 'connection',
      platform: 'youtube',
      title: 'YouTube Overview',
      panels: [
        {
          component: 'stat',
          label: 'subscribers',
          title: 'Total Subscribers'
        },
        {
          component: 'stat',
          label: 'total_views',
          title: 'Total Video Views'
        },
        {
          component: 'stat',
          label: 'total_posts',
          title: 'Total Videos'
        },
        {
          component: 'cchart',
          label: 'age_dist',
          title: 'Age Distribution'
        },
        {
          component: 'cchart',
          label: 'gender_dist',
          title: 'Gender Distribution'
        },
        {
          component: 'cchart',
          label: 'country_dist',
          title: 'Country Distribution'
        },
        {
          component: 'content',
          label: 'latest_posts',
          title: 'Latest Videos'
        }
      ]
    }
  }
};
