import { useState } from 'react';
import { Center, Button, Text } from '@chakra-ui/react';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MediaKitAddComponentModal from './MediaKitAddComponentModal';

const MediaKitAddComponentButton = (props) => {
  const { mediaKitConnections, selectedConnections, setSelectedConnections, onClose, onSubmit } = props;

  const [addComponentModalOpen, setAddComponentModalOpen] = useState(false);

  return (
    <Center>
      <Button
        border="2px"
        borderRadius="xl"
        borderStyle="dashed"
        borderColor="blue.300"
        color="blue.900"
        width="100%"
        mt="30px"
        mx="80px"
        bg="blue.50"
        px="20px"
        py="50px"
        _hover={{
          background: 'blue.100'
        }}
        onClick={() => setAddComponentModalOpen(!addComponentModalOpen)}
      >
        <Center>
          <Text
            fontSize="18px"
            mr="2"
          >
            add to media kit
          </Text>
          <FontAwesomeIcon icon={faAdd} />
        </Center>
      </Button>
      <MediaKitAddComponentModal
        disabledConnections={mediaKitConnections}
        selectedConnections={selectedConnections}
        setSelectedConnections={setSelectedConnections}
        isOpen={addComponentModalOpen}
        setIsOpen={setAddComponentModalOpen}
        onSubmit={onSubmit}
        onClose={() => setAddComponentModalOpen(!addComponentModalOpen)}
      />
    </Center>
  );
};

export default MediaKitAddComponentButton;
