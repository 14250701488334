import { useState } from 'react';
import { Box, Container, Editable, EditablePreview, EditableTextarea, SkeletonText, Text } from '@chakra-ui/react';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Bio = (props) => {
  const { editMode, setHasChanged } = props;

  const [bio, setBio] = useState(props.bio || '');
  const [textAreaFocus, setTextAreaFocus] = useState(false);

  const onBlur = () => {
    props.setNewBio(bio);
    setTextAreaFocus(false);
  };

  return (
    <Container
      width="100%"
      height="180px"
      maxW="fit-content"
      py={2}
      px={0}
      m={0}
      right={0}
      position="relative"
    >
      <SkeletonText
        spacing={3}
        noOfLines={4}
        skeletonHeight={4}
        isLoaded={typeof props.bio !== 'undefined'}
      >
        {!editMode &&
          props?.bio &&
          props.bio.split('\n').map((line) => {
            return <Text>{line}</Text>;
          })}

        {editMode && (
          <Editable
            defaultValue={props.bio || 'Bio is empty'}
            width="100%"
          >
            <EditablePreview
              cursor="pointer"
              id="bio-textarea"
            />
            <EditableTextarea
              rows="6"
              cols="100"
              maxW="max-fill"
              maxH="180px"
              minH="180px"
              onChange={(e) => {
                setHasChanged(true);
                setBio(e.target.value);
              }}
              onFocus={() => setTextAreaFocus(true)}
              onBlur={() => onBlur()}
            />
            {!textAreaFocus && (
              <Box
                cursor="pointer"
                as="span"
                ml="1"
                onClick={() => document.getElementById('bio-textarea').focus()}
              >
                <FontAwesomeIcon icon={faPencil} />
              </Box>
            )}
          </Editable>
        )}
      </SkeletonText>
    </Container>
  );
};

export default Bio;
