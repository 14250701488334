import { Box, Center, Container, Spinner, Text } from '@chakra-ui/react';

const Step3 = (props) => {
  const { isSubmitting, submitMediaKit } = props;

  if(!isSubmitting){
    submitMediaKit();
  }

  return (
    <Container>
      <Box>
        <Center>
          <Spinner
            mt="60px"
            mb="20px"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Center>
      </Box>
      <Box>
        <Center>
          <Text>Creating Media Kit...</Text>
        </Center>
      </Box>
    </Container>
  );
};
export default Step3;
