import { Avatar, Box, Center, Card, Image, Text, Heading, HStack, VStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faHeart, faComment, faShare, faRepeat, faClock } from '@fortawesome/free-solid-svg-icons';
import helpers from '../utils/helpers';

const ContentCard = (props) => {
  const { colors = { primary: 'blue', secondary: 'green' }, platform, username, content, avatar } = props;

  const keyToIcon = (key) => {
    switch (key) {
      case 'views':
        return (
          <Box
            as="span"
            color={`${colors.primary}.300`}
            fontSize="sm"
          >
            <FontAwesomeIcon icon={faPlay} />
          </Box>
        );
        break;
      case 'likes':
        return (
          <Box
            as="span"
            color={`${colors.primary}.300`}
            fontSize="sm"
          >
            <FontAwesomeIcon icon={faHeart} />
          </Box>
        );
        break;
      case 'duration':
        return (
          <Box
            as="span"
            color={`${colors.primary}.300`}
            fontSize="sm"
          >
            <FontAwesomeIcon icon={faClock} />
          </Box>
        );
        break;
      case 'comments':
        return (
          <Box
            as="span"
            color={`${colors.primary}.300`}
            fontSize="sm"
          >
            <FontAwesomeIcon icon={faComment} />
          </Box>
        );
        break;
      case 'reposts':
        return (
          <Box
            as="span"
            color={`${colors.primary}.300`}
            fontSize="sm"
          >
            <FontAwesomeIcon icon={faRepeat} />
          </Box>
        );
        break;
      case 'shares':
        return (
          <Box
            as="span"
            color={`${colors.primary}.300`}
            fontSize="sm"
          >
            <FontAwesomeIcon icon={faShare} />
          </Box>
        );
        break;
      default:
        return key;
        break;
    }
  };

  const getCardValue = (key, value) => {
    if (key === 'duration') {
      return helpers.formatTime(value);
    }
    return helpers.formatNumber(content.stats[key], true);
  };

  return (
    <Card
      minH="125px"
      height="100%"
      width="100%"
      as="span"
      px={0}
      pt={4}
      pb={2}
      color={`gray.1000`}
      position="relative"
    >
      <Box bottom="10px">
        <Text
          color={`gray.500`}
          fontSize="xs"
        >
          {/* Posted on */} {helpers.formatDate(content.createdAt)}
        </Text>
      </Box>

      {content.thumbnailUrl && (
        <Link
          to={content.url}
          target="_blank"
        >
          <Image
            fallbackSrc="https://placehold.co/222x222"
            src={content.thumbnailUrl}
            width="100%"
            mb="2"
          />
        </Link>
      )}

      {content.title && (
        <Heading
          height="100%"
          fontSize="md"
        >
          {content.title.length > 50 ? content.title.slice(0, 50) + '...' : content.title}
        </Heading>
      )}

      {platform === 'facebook' && content.caption && (
        <Text
          height="100%"
          color="gray.600"
          fontSize="sm"
        >
          {content.caption}
        </Text>
      )}

      {avatar && username && (
        <HStack spacing="0">
          <Box as="span">
            <Avatar
              size="sm"
              src={avatar}
              borderWidth="5px"
            />
          </Box>
          <Heading
            as="span"
            color={`${colors.secondary}.500`}
            mb="2"
            fontSize="md"
          >
            <Link
              to={content.url}
              target={'_blank'}
            >
              @{username}
            </Link>
          </Heading>
        </HStack>
      )}

      {content.stats && (
        <Center>
          <VStack>
            <HStack
              mt="4"
              spacing="2"
            >
              {Object.keys(content.stats).map((key, idx) => {
                return (
                  <Box
                    mr="2"
                    key={`stats-${idx}`}
                  >
                    <Text
                      mr="1"
                      as="span"
                      fontSize="sm"
                    >
                      {getCardValue(key, content.stats[key])}
                    </Text>
                    {keyToIcon(key)}
                  </Box>
                );
              })}
            </HStack>
          </VStack>
        </Center>
      )}
    </Card>
  );
};

export default ContentCard;
