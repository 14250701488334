import { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Text,
  Button
} from '@chakra-ui/react';

const CreateAgencyModal = (props) => {
  const [agencySlug, setAgencySlug] = useState('');
  const [agencyName, setAgencyName] = useState('');
  const [agencySlugErr, setAgencySlugErr] = useState('');
  const [agencyNameErr, setAgencyNameErr] = useState('');
  const [agencyFormErr, setAgencyFormErr] = useState('');

  const resetErrorState = () => {
    setAgencySlugErr('');
    setAgencyNameErr('');
    setAgencyFormErr('');
  };

  const submitAgency = () => {
    resetErrorState();
    let error = '';
    if (!agencySlug || agencySlug == '') {
      error = "URL can't be empty";
      setAgencySlugErr(error);
    } else if (agencySlug.includes(' ')) {
      error = "URL can't have spaces";
      setAgencySlugErr(error);
    }
    if (!agencyName || agencyName == '') {
      error = "Name can't be empty";
      setAgencyNameErr(error);
    }

    if (!error) {
      return props.onSubmit({ name: agencyName, slug: agencySlug });
    } else {
      setAgencyFormErr('An error occured');
    }
  };

  const error = agencyFormErr || props.errorMessage;

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Agency</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {error && <Text color="red.500">{error}</Text>}
          <FormControl isInvalid={agencySlugErr}>
            <FormLabel>URL Slug</FormLabel>
            <Input
              value={agencySlug}
              onChange={(e) => {
                setAgencySlugErr('');
                setAgencySlug(e.target.value);
              }}
              placeholder="company-name"
            />
            {agencySlugErr && <FormErrorMessage>{agencySlugErr}</FormErrorMessage>}
          </FormControl>

          <FormControl
            mt={4}
            isInvalid={agencyNameErr}
          >
            <FormLabel>Name</FormLabel>
            <Input
              value={agencyName}
              onChange={(e) => {
                setAgencyNameErr('');
                setAgencyName(e.target.value);
              }}
              placeholder="Company Name"
            />

            {agencyNameErr && <FormErrorMessage>{agencyNameErr}</FormErrorMessage>}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => submitAgency()}
            colorScheme="blue"
            ml={3}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateAgencyModal;
