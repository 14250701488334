import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  Box,
  Button,
  Avatar,
  Input,
  InputGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Table,
  TableContainer,
  Th,
  Thead,
  Tbody,
  Tr,
  Td,
  Select,
  Text
} from '@chakra-ui/react';
import { useAppContext } from '../../hooks/useAppContext';
import DashboardLayout from '../../containers/DashboardLayout';
import PageHeader from '../../containers/PageHeader';
import { useToastHook } from '../../components/Toast';
import { update, getUserById } from '../../api/user';
import { getAgencyList } from '../../api/agency';

const EditUser = () => {
  const { user } = useAppContext();

  const userId = window.location.pathname.split('/')[3];
  const [editUser, setEditUser] = useState([]);

  const [userName, setUserName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userIsAdmin, setUserIsAdmin] = useState();
  const [userAgencyId, setUserAgencyId] = useState();
  const [userAgency, setUserAgency] = useState();
  const [userStatus, setUserStatus] = useState();

  const [agencyList, setAgencyList] = useState([]);

  const [userNameError, setUserNameError] = useState('');
  const [userEmailError, setUserEmailError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [_, newToast] = useToastHook();

  useEffect(() => {
    getUserById(userId).then((user) => {
      setEditUser(user);
      setUserName(user.name);
      setUserEmail(user.email);
      setUserIsAdmin(user.isMediasweetAdmin);
      setUserAgencyId(user.agencyId);
      setUserStatus(user.status);
      getAgencyList().then((agencyList) => {
        setAgencyList(agencyList);
        setUserAgency(agencyList.filter((agency) => agency._id === user.agencyId)[0]);
      });
    });
  }, [userId]);

  const agencyColumns = ['logo', 'name', 'slug', 'product'];

  const submitForm = async () => {
    setIsLoading(true);
    let error = false;
    if (!userName || userName === '') {
      setUserNameError("User name can't be blank");
      error = true;
    }
    if (!userEmail || !userEmail === '') {
      error = true;
      setUserEmailError("User email can't be blank");
    }

    if (!error) {
      const updateBody = {
        name: userName,
        email: userEmail,
        isMediasweetAdmin: userIsAdmin,
        agencyId: userAgencyId === '' ? null : userAgencyId,
        status: userStatus
      };

      update(userId, updateBody, localStorage.getItem('jwt'))
        .then((res) => {
          if (res?.acknowledged) {
            setEditUser({ ...editUser, ...updateBody });
            newToast({
              status: 'success',
              message: 'User details have been saved'
            });
          }
        })
        .catch((err) => {
          console.error(err);
          newToast({
            status: 'error',
            message: 'There was an error updating the user.'
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };

  if (editUser === []) {
    return <p>Loading...</p>;
  }

  return (
    <DashboardLayout page="users">
      <PageHeader
        title="Users"
        body={
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/admin/users">
                <Text
                  color="blue.500"
                  as="u"
                >
                  users
                </Text>
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <Link
                color="blue.500"
                to="#"
              >
                <Text
                  color="blue.500"
                  as="u"
                >
                  {editUser._id}
                </Text>
              </Link>
            </BreadcrumbItem>
          </Breadcrumb>
        }
      />

      <Box maxW="500px">
        <Box mb={8}>
          <FormControl
            mt="6"
            isInvalid={userNameError}
          >
            <FormLabel>Name:</FormLabel>
            <Input
              value={userName}
              onChange={(e) => {
                setUserNameError('');
                setUserName(e.target.value);
              }}
            />
            <FormErrorMessage>{userNameError}</FormErrorMessage>
          </FormControl>

          <FormControl
            mt="6"
            isInvalid={userEmailError}
          >
            <FormLabel>Email:</FormLabel>
            <InputGroup width={'md'}>
              <Input
                value={userEmail}
                type="agency-name"
                isDisabled={true}
              />
            </InputGroup>
            <FormErrorMessage
              float="left"
              mt={0}
            >
              {userEmailError}
            </FormErrorMessage>
          </FormControl>

          <FormControl mt="6">
            <FormLabel>Is Admin</FormLabel>
            <Select
              value={userIsAdmin}
              // disabled={user?._id === editUser._id}
              onChange={(e) => {
                // silly bool conversion
                setUserIsAdmin(e.target.value === 'true');
              }}
            >
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Select>
          </FormControl>

          <FormControl mt="6">
            <FormLabel>Agency:</FormLabel>
            <Select
              placeholder="Select agency by name"
              value={userAgencyId}
              onChange={(e) => {
                console.log(e.target.value);
                setUserAgencyId(e.target.value);
                setUserAgency(agencyList.filter((product) => product._id === e.target.value)[0]);
              }}
            >
              {agencyList.map((product) => {
                return (
                  <option
                    key={product?._id}
                    value={product?._id}
                  >
                    {product?.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>

          {userAgency && (
            <TableContainer>
              <Table
                size="sm"
                variant="striped"
              >
                <Thead>
                  <Tr>
                    {agencyColumns.map((column) => {
                      return <Th key={column}>{column}</Th>;
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    {agencyColumns.map((key) => {
                      if (key === 'logo') {
                        return (
                          <Td key={key}>
                            <Avatar
                              height="20px"
                              src={String(userAgency[key])}
                            />
                          </Td>
                        );
                      } else {
                        return <Td key={key}>{String(userAgency[key])}</Td>;
                      }
                    })}
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Box>

        <FormControl mt="6">
          <FormLabel>Status</FormLabel>
          <Select
            value={userStatus}
            onChange={(e) => {
              setUserStatus(e.target.value);
            }}
          >
            <option value={'enabled'}>Enabled</option>
            <option value={'disabled'}>Disabled</option>
          </Select>
        </FormControl>

        <Box mt={6}>
          <Button
            isDisabled={
              userName === editUser.name &&
              userEmail === editUser.email &&
              userIsAdmin === editUser.isMediasweetAdmin &&
              userAgencyId === editUser.agencyId &&
              userStatus === editUser.status
            }
            isLoading={isLoading}
            onClick={submitForm}
            background="blue.500"
            color="white"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default EditUser;
