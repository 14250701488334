import { useState } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Switch,
  Text,
  Button
} from '@chakra-ui/react';

const CreateProductModal = (props) => {
  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [productMediaKits, setProductMediaKits] = useState(0);
  const [productMembers, setProductMembers] = useState(0);
  const [productBranding, setProductBranding] = useState(false);
  const [productCohorts, setProductCohorts] = useState(5);

  const [productNameErr, setProductNameErr] = useState('');
  const [productDescriptionErr, setProductDescriptionErr] = useState('');
  const [productMediaKitsErr, setProductMediaKitsErr] = useState('');
  const [productMembersErr, setProductMembersErr] = useState('');
  const [productBrandingErr, setProductBrandingErr] = useState('');
  const [productCohortsErr, setProductCohortsErr] = useState('');

  const [productFormErr, setProductFormErr] = useState('');

  const submitProduct = () => {
    try {
      if (!productName || productName == '') {
        setProductNameErr("Name can't be empty");
      }
      return props.onSubmit({
        name: productName,
        description: productDescription,
        mediakits: productMediaKits,
        members: productMembers,
        branding: productBranding,
        cohorts: productCohorts
      });
    } catch (err) {
      setProductFormErr(err);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Product</ModalHeader>
        {productFormErr && <Text color="red.500">{productFormErr}</Text>}
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl
            mt={4}
            isInvalid={productNameErr}
          >
            <FormLabel>Name</FormLabel>
            <Input
              value={productName}
              onChange={(e) => {
                setProductNameErr('');
                setProductName(e.target.value);
              }}
              placeholder="Company Name"
            />
            {productNameErr && <FormErrorMessage>{productNameErr}</FormErrorMessage>}
          </FormControl>

          <FormControl
            mt={4}
            isInvalid={productDescriptionErr}
          >
            <FormLabel>Description</FormLabel>
            <Input
              value={productDescription}
              onChange={(e) => {
                setProductDescriptionErr('');
                setProductDescription(e.target.value);
              }}
              placeholder="Description"
            />
            {productDescriptionErr && <FormErrorMessage>{productDescriptionErr}</FormErrorMessage>}
          </FormControl>

          <FormControl
            mt={4}
            isInvalid={productMediaKitsErr}
          >
            <FormLabel>
              MediaKits{' '}
              <Text
                fontSize="xs"
                as="i"
              >
                (max)
              </Text>
            </FormLabel>
            <NumberInput
              step={1}
              defaultValue={5}
              min={1}
              value={productMediaKits}
              onChange={(e) => {
                setProductMediaKitsErr();
                setProductMediaKits(e);
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {productMediaKitsErr && <FormErrorMessage>{productMediaKitsErr}</FormErrorMessage>}
          </FormControl>
          <FormControl
            mt={4}
            isInvalid={productMembersErr}
          >
            <FormLabel>
              Members
              <Text
                fontSize="xs"
                as="i"
              >
                (max)
              </Text>
            </FormLabel>
            <NumberInput
              step={1}
              defaultValue={5}
              min={1}
              value={productMembers}
              onChange={(e) => {
                setProductMembersErr('');
                setProductMembers(e);
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {productMembersErr && <FormErrorMessage>{productMembersErr}</FormErrorMessage>}
          </FormControl>
          <FormControl
            mt={4}
            isInvalid={productBrandingErr}
          >
            <FormLabel
              htmlFor="product-branding"
              mb="0"
            >
              Branding enabled
            </FormLabel>

            <Switch
              id="product-branding"
              value={productBranding}
              onChange={(e) => {
                setProductBranding(!productBranding);
              }}
            />

            {productBrandingErr && <FormErrorMessage>{productBrandingErr}</FormErrorMessage>}
          </FormControl>
          <FormControl
            mt={4}
            isInvalid={productCohortsErr}
          >
            <FormLabel>
              Cohorts (lists)
              <Text
                fontSize="xs"
                as="i"
              >
                (max)
              </Text>
            </FormLabel>
            <NumberInput
              step={1}
              defaultValue={productCohorts}
              min={1}
              onChange={(e) => {
                setProductCohortsErr('');
                setProductCohorts(e);
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {productCohortsErr && <FormErrorMessage>{productCohortsErr}</FormErrorMessage>}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            onClick={() => submitProduct()}
            colorScheme="blue"
            ml={3}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateProductModal;
