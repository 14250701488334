import { useState, useEffect } from 'react';
import { useAppContext } from '../hooks/useAppContext';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Input,
  FormControl,
  FormErrorMessage,
  InputLeftAddon,
  InputGroup,
  SimpleGrid,
  Text,
  Heading
} from '@chakra-ui/react';
import { getAgencyListMediakits } from '../api/mediakit';
import { getConnectionById } from '../api/connection';
import { getAgencyBySlug } from '../api/agency';
import Header from '../containers/Header';
import MediaKitCards from '../containers/MediaKitCards';
import { useToastHook } from '../components/Toast';

const List = () => {
  const navigate = useNavigate();
  const [state, newToast] = useToastHook();
  const { user, userAgency } = useAppContext();
  const { agencySlug, listSlug } = useParams();
  const [enableAccess, setEnableAccess] = useState(false);
  const [passcodeInput, setPasscodeInput] = useState('');

  const [listMediaKits, setListMediaKits] = useState([]);
  const [listAgency, setListAgency] = useState({});
  const [agencyList, setAgencyList] = useState({});
  const [passcodeError, setPasscodeError] = useState('');
  const [listConnections, setListConnections] = useState([]);

  useEffect(() => {
    if (agencySlug && listSlug) {
      getAgencyBySlug(agencySlug).then((agency) => {
        setListAgency(agency);
        getAgencyListMediakits(agency._id, listSlug).then(async (list) => {
          if (list) {
            if (list.passcode) {
              setEnableAccess(false);
            } else {
              setEnableAccess(true);
            }
            setAgencyList(list);
            setListMediaKits(list?.mediakits);

            // Get connections for inner media kits
            let connectionList = [];
            list?.mediakits.map((mediakit) => {
              mediakit.platforms.map((platform) => {
                connectionList.push(platform.id);
              });
            });
            connectionList = await getConnectionById(connectionList);
            let connectionObject = {};
            connectionList.map((connection) => {
              connectionObject[connection._id] = connection;
            });
            setListConnections(connectionObject);
          }
        });
      });
    }
  }, []);

  const submitPasscode = (e) => {
    setPasscodeError('');
    e.preventDefault();
    if (passcodeInput === agencyList.passcode) {
      setEnableAccess(true);
    } else {
      setPasscodeError('The passcode you entered is not correct');
    }
  };

  return (
    <Center>
      <Box
        borderRadius="lg"
        w={[220, 900, 1100]}
        bg="white"
        m="4"
      >
        <Grid
          templateAreas={`"header header""main main""footer footer"`}
          gridTemplateRows={'75px 1fr 30px'}
          gridTemplateColumns={'250px 1fr'}
          gap="0"
          m="4"
          fontWeight="bold"
        >
          <Header agency={listAgency} />

          <GridItem
            pl="2"
            bg="brand.white"
            area={'main'}
          >
            <Center>
              <Box
                mb={3}
                textAlign="center"
              >
                {listAgency?.name ? <Heading fontSize={'3xl'}>{listAgency?.name}'s Media Kits</Heading> : <>Loading</>}
                <Heading
                  mt="2"
                  fontSize="lg"
                >
                  {agencyList.name}
                </Heading>
              </Box>
            </Center>
            {agencyList?.passcode && !enableAccess && (
              <Center mb="10">
                <Box
                  mt="5"
                  mb="10"
                >
                  <Text
                    align="center"
                    my="2"
                    size="sm"
                  >
                    A passcode is required to view this list
                  </Text>
                  <FormControl isInvalid={passcodeError.length > 0}>
                    <form>
                      <InputGroup>
                        <InputLeftAddon children="Passcode" />
                        <Input
                          value={passcodeInput}
                          onChange={(e) => {
                            if (passcodeError) {
                              setPasscodeError('');
                            }
                            setPasscodeInput(e.target.value);
                          }}
                          type="text"
                        />
                        <Button
                          mx="2"
                          px="8"
                          bg="blue.500"
                          color="#FFF"
                          type="submit"
                          onClick={(e) => submitPasscode(e)}
                        >
                          Submit
                        </Button>
                      </InputGroup>
                      <Center>
                        <FormErrorMessage
                          align="center"
                          mt={0}
                        >
                          {passcodeError}
                        </FormErrorMessage>
                      </Center>
                    </form>
                  </FormControl>
                </Box>
              </Center>
            )}

            {enableAccess && (
              <SimpleGrid
                my="6"
                spacing="4"
                minChildWidth="250px"
              >
                <MediaKitCards
                  key={listConnections.length}
                  public={true}
                  mediakits={listMediaKits}
                  connections={listConnections}
                  agencySlug={agencySlug}
                />
              </SimpleGrid>
            )}
          </GridItem>

          <GridItem
            bg="brand.white"
            area={'footer'}
          >
            <Text
              mt="4"
              fontSize="xs"
              color="gray.500"
              align="center"
            >
              Copyright Media Sweet 2023
            </Text>
          </GridItem>
        </Grid>
      </Box>
    </Center>
  );
};

export default List;
