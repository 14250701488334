import { Box, Button, VStack, StackDivider } from '@chakra-ui/react';
import PlatformsContainer from '../containers/PlatformsContainer';

const Step2 = (props) => {
  const {
    userAgency,
    userConnections,
    setMediaKitConnections,
    selectedConnections,
    setSelectedConnections,
    changeStep,
    setUserConnections
  } = props;

  let disableSubmit = true;
  if (selectedConnections.length) {
    disableSubmit = false;
  }

  const submitForm = async () => {
    let mediakitConnections = [];
    // todo disable or enable next step button depending upon
    // if an authorized connection has been selected
    for (const connection of selectedConnections) {
      const userConnection = userConnections.filter((conn) => conn._id === connection);
      if (userConnection.length) {
        mediakitConnections.push(userConnection[0]);
      }
    }
    setMediaKitConnections(mediakitConnections);

    changeStep(1);
  };

  return (
    <Box>
      <VStack divider={<StackDivider borderColor="gray.100" />}>
        <PlatformsContainer
          userAgency={userAgency}
          setUserConnections={setUserConnections}
          userConnections={userConnections}
          selectedConnections={selectedConnections}
          setSelectedConnections={setSelectedConnections}
          view={'icons'}
          selectable={'true'}
          token={''}
        />

        <Box mt={6}>
          <Button
            mx="2"
            onClick={() => props.changeStep(-1)}
            background="gray.100"
            color="gray.1000"
          >
            Go Back
          </Button>
          <Button
            mx="2"
            onClick={submitForm}
            background="blue.500"
            color="white"
            isDisabled={disableSubmit}
          >
            Next Step
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};

export default Step2;
