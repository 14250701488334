import { useState } from 'react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Button,
  Text,
  Box,
  Tabs,
  Tab,
  Input,
  TabPanel,
  TabList,
  TabPanels
} from '@chakra-ui/react';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChangeBackground = (props) => {
  const [backgroundLink, setBackgroundLink] = useState(props.newBackgroundVideo);

  const setYTBackground = (url) => {
    const embedUrl = youtubeParser(url);
    props.setNewBackgroundVideo(`https://youtube.com/embed/${embedUrl}`);
    setBackgroundLink(`https://youtube.com/embed/${embedUrl}`);
  };

  const youtubeParser = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  };

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          background="blue.300"
          p="1"
          py="2"
          px="3"
          mt="3"
          boxShadow="base"
          borderRadius="xl"
          bg="gray.100"
          color="blue.800"
        >
          <Text
            mx="1"
            as="span"
          >
            Background
          </Text>
          <Box
            mx="1"
            as="span"
          >
            <FontAwesomeIcon icon={faPencil} />
          </Box>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Tabs
            isFitted
            defaultIndex={props.newBackgroundType === 'video' ? 1 : 0}
            onChange={(index) => {
              if (!props.hasChanged) props.setHasChanged(true);
              if (index === 0) {
                props.setNewBackgroundType('image');
                props.setNewBackgroundImg(document.getElementById('input-bg-image').value);
              } else if (index === 1) {
                props.setNewBackgroundType('video');
                props.setNewBackgroundVideo(document.getElementById('input-bg-video').value);
              }
            }}
          >
            <TabList>
              <Tab>Image</Tab>
              <Tab>Video</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                Image URL:
                <Input
                  value={props.newBackgroundImg}
                  id="input-bg-image"
                  onChange={(e) => {
                    if (!props.hasChanged) props.setHasChanged(true);
                    if (props.newBackgroundType === 'image') {
                      props.setNewBackgroundImg(e.target.value);
                    }
                  }}
                  placeholder="https://i.imgur.com/BTNIDBR.gif"
                />
              </TabPanel>
              <TabPanel>
                YouTube link:
                <Input
                  value={backgroundLink}
                  id="input-bg-video"
                  onChange={(e) => {
                    props.setHasChanged(true);
                    setYTBackground(e.target.value);
                  }}
                  placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ChangeBackground;
