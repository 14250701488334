import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Text } from '@chakra-ui/react';
import Loader from '../../components/Loader';
import { useAppContext } from '../../hooks/useAppContext';
import DashboardLayout from '../../containers/DashboardLayout';
import PageHeader from '../../containers/PageHeader';
import { getProductList, createProduct } from '../../api/product';
import CreateProductModal from '../../components/CreateProduct';
import helpers from '../../utils/helpers';
import SortedTable from '../../components/SortingTable';

const AdminProducts = () => {
  const { user } = useAppContext();
  const isAdmin = user?.isMediasweetAdmin || false;
  const [productList, setProductList] = useState([]);

  const [filteredProductList, setFilteredProductList] = useState([]);
  const [isCreateProductOpen, setIsCreateProductOpen] = useState(false);
  const [productSearchFilter, setProductSearchFilter] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProductList().then((products) => {
      products = products.map((product) => {
        return {
          actions: (
            <Link to={`/admin/products/${product._id}`}>
              <Text as="u">Edit</Text>
            </Link>
          ),
          id: product._id,
          name: product.name,
          description: product.description,
          mediakits: Number(product.mediakits),
          members: Number(product.members),
          branding: String(product.branding),
          lists: Number(product.cohorts),
          createdAt: product.createdAt ? helpers.formatDate(product.createdAt) : ''
        };
      });

      setProductList(products);
      setIsLoading(false);
    });
  }, []);

  /**
   * submitNewProduct.
   * callback function passed to CreateProductModal
   * @param {object} newProduct
   */
  const submitNewProduct = async (newProduct) => {
    const product = await createProduct(newProduct);
    setProductList([...productList, product]);
    setIsCreateProductOpen(false);
    getProductList().then((products) => {
      setProductList(products);
      setFilteredProductList(products);
    });
  };

  return (
    <DashboardLayout page="products">
      <PageHeader
        title="Products"
        body={
          <Button
            colorScheme="blue"
            px="2"
            margin="2"
            onClick={() => setIsCreateProductOpen(true)}
          >
            Create Product
          </Button>
        }
      />

      <CreateProductModal
        isOpen={isCreateProductOpen}
        onSubmit={async (product) => await submitNewProduct(product)}
        onClose={() => setIsCreateProductOpen(false)}
      />

      <Loader
        text="Loading products..."
        isLoaded={!isLoading && productList?.length}
      >
        <SortedTable rows={productList} />
      </Loader>
    </DashboardLayout>
  );
};

export default AdminProducts;
