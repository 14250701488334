import React, { useState } from 'react';
import Layout from './components/Layout';
import routes from './routes';
import { AppContextProvider } from './hooks/useAppContext';
import { NavProvider } from './hooks/navOpen';
import '@fontsource/uncut-sans/500.css';
import '@fontsource/hauora-sans/600.css';
import { ChakraProvider, extendTheme, Center, Text, Image, VStack } from '@chakra-ui/react';
//import chakraTheme from "@chakra-ui/theme"
import Loader from './components/Loader';
import logoPng from './assets/logos/media-sweet-logo.png';

let theme = extendTheme({
  fonts: {
    heading: `"Uncut Sans", sans-serif`,
    body: `"Hauora Sans", sans-serif`
  },
  styles: {
    global: {
      body: {
        fontWeight: 400,
        //bg: "#F7F7F7",
        bg: 'purple.100',
        color: 'gray.1000'
      },
      a: {
        //color: 'blue.500',
        fontWeight: 800,
        _hover: {
          textDecoration: 'underline'
        }
      },
      button: {
        _hover: {
          bg: 'initial'
        }
      }
    }
  },
  colors: {
    gray: {
      50: '#f9f8fa',
      100: '#efeef0',
      200: '#dcd6dd',
      300: '#cac0c9',
      400: '#aaa0a9',
      500: '#867a85',
      600: '#695d65',
      700: '#51484d',
      800: '#393135',
      900: '#231c1f',
      1000: '#181114'
    },
    pink: {
      50: '#faf7fc',
      100: '#f7e9fc',
      200: '#f1c9f7',
      300: '#f4a3f1',
      400: '#e470df',
      500: '#bf42b5',
      600: '#9c268a',
      700: '#7f1964',
      800: '#5b143f',
      900: '#390a25',
      1000: '#280218'
    },
    red: {
      50: '#fef6f7',
      100: '#ffe8ea',
      200: '#ffc7ce',
      300: '#fca6b0',
      400: '#f96d84',
      500: '#db2f58',
      600: '#ad1a38',
      700: '#871829',
      800: '#5d171c',
      900: '#3a0c0b',
      1000: '#250800'
    },
    orange: {
      50: '#fbf7f0',
      100: '#faebd3',
      200: '#f5ce9f',
      300: '#f7ab67',
      400: '#ee8026',
      500: '#c55511',
      600: '#a23600',
      700: '#842000',
      800: '#5d1d00',
      900: '#361407',
      1000: '#240901'
    },
    yellow: {
      50: '#f8f9f2',
      100: '#f0f2c3',
      200: '#ddda83',
      300: '#cac24a',
      400: '#aaa128',
      500: '#857a11',
      600: '#675c13',
      700: '#544800',
      800: '#3d3300',
      900: '#251e00',
      1000: '#181200'
    },
    green: {
      50: '#f4faea',
      100: '#e1f4c7',
      200: '#bbe28f',
      300: '#94cf60',
      400: '#66af2e',
      500: '#438728',
      600: '#21692a',
      700: '#0a5423',
      800: '#0a3c18',
      900: '#06240d',
      1000: '#031707'
    },
    blue: {
      50: '#f0faf7',
      100: '#ccf6ed',
      200: '#98e2d7',
      300: '#6ecbc3',
      400: '#3caeac',
      500: '#1a858d',
      600: '#076474',
      700: '#0d4e60',
      800: '#0d3848',
      900: '#07212e',
      1000: '#03141d'
    },
    purple: {
      50: '#f5f6ff',
      100: '#eceeff',
      200: '#d0d1ff',
      300: '#b9b8fc',
      400: '#9f8eff',
      500: '#8163e2',
      600: '#6b42bb',
      700: '#5c2e92',
      800: '#432068',
      900: '#2a123f',
      1000: '#1b0829'
    },
    brand: {
      link: 'blue.500',
      cordovan: '#904E55',
      skyBlue: '#32CBFF',
      pictonBlue: '#00A5E0',
      vistaBlue: '#89A1EF',
      plum: '#EF9CDA',
      //mimiPink: "#FECEF1",
      mimiPink: '#EACBD2',

      white: '#FFF',
      turquoise: '#81F3E1',
      cyclamen: '#DF1D5B',
      thistle: '#D3C3D1'
    },
    platform: {
      meta: '#0272ef',
      facebook: '#0165E1',
      instagram: '#833AB4',
      kick: '#53fc18',
      spotify: '#1DB954',
      snapchat: '#FFFC00',
      tiktok: '#000000',
      twitch: '#9146FF',
      // twitter: '#1D9BF0', legacy twitter color
      twitter: '#000000',
      youtube: '#FF0000'
    }
  }
});

const App = () /*: JSX.Element */ => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState('');

  return (
    <AppContextProvider
      onLoaded={() => setLoaded(true)}
      onFailed={setError}
    >
      <NavProvider>
        <ChakraProvider theme={theme}>
          {error ? (
            <Center height="100vh">
              <VStack>
                <Image
                  src={logoPng}
                  m="15"
                  objectFit="cover"
                  width="400px"
                  display="inline-block"
                />
                <Text>{error}</Text>
              </VStack>
            </Center>
          ) : (
            <Loader
              isLoaded={loaded}
              height="100vh"
              showLogo={true}
            >
              <Layout>{routes}</Layout>
            </Loader>
          )}
        </ChakraProvider>
      </NavProvider>
    </AppContextProvider>
  );
};

export default App;
