import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  AvatarBadge,
  Avatar,
  Center,
  Grid,
  GridItem,
  Image,
  Text,
  Heading
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';

import AlertPopOver from '../components/AlertDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faExternalLink, faLineChart } from '@fortawesome/free-solid-svg-icons';
import Footer from '../containers/Footer';
import ConnectionsTable from '../containers/ConnectionsTableContainer';
import helpers from '../utils/helpers';

import CreateNewConnectionButtons from '../containers/CreateNewConnectionButtons';
import { fetchConnections } from '../actions/connections';
import { getAgencyByToken } from '../api/agency';
import Header from '../containers/Header';
import Nav from '../containers/Nav';
import { useToastHook } from '../components/Toast';

const AgencyPlatformsRequest = () => {
  const [_, newToast] = useToastHook();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [agencyRequestToken, setAgencyRequestToken] = useState(
    searchParams.get('token') || localStorage.getItem('invite-token') || ''
  );
  const [requestingAgency, setRequestingAgency] = useState({});
  const [userConnections, setUserConnections] = useState([]);

  const [connectionToDelete, setConnectionToDelete] = useState();
  const [tableData, setDataTable] = useState([]);

  useEffect(() => {
    if (!agencyRequestToken) {
      const inviteToken = localStorage.getItem('invite-token');
      if (!inviteToken) {
        return navigate('/', { replace: true });
      }
    }
    localStorage.setItem('invite-token', agencyRequestToken);

    try {
      getAgencyByToken(agencyRequestToken).then((agency) => {
        setRequestingAgency(agency);
      });
      getConnections({ token: agencyRequestToken });
    } catch (err) {
      newToast({
        status: 'error',
        message: 'There was an issue getting your connections' + err
      });
    }
  }, []);

  const getConnections = ({ token }) => {
    fetchConnections({ token })
      .then((connections) => {
        setUserConnections([...connections]);
      })
      .catch((err) => {
        newToast({
          status: 'error',
          message: 'Something went wrong fetching connections',
          duration: 5000
        });
      });
  };

  return (
    <Center p={0}>
      <Box
        borderRadius="lg"
        bg="white"
        maxW="100vw"
        justify="center"
        p={3}
      >
        <Center>
          <Box m={3}>
            {requestingAgency?.logo && (
              <Image
                width="sm"
                m="0 auto"
                mb="5"
                src={requestingAgency?.logo}
              />
            )}
            <Text>
              Provide&nbsp;
              <Text
                color="blue.700"
                as="b"
              >
                {requestingAgency.name}
              </Text>
              &nbsp;with read-only access to your social media analytics.
            </Text>
          </Box>
        </Center>

        {userConnections.length > 0 && (
          <ConnectionsTable
            search={false}
            token={agencyRequestToken}
            connections={userConnections}
            connectionsLength={userConnections.length}
          />
        )}
        <Center>
          <Heading
            fontSize="xl"
            mb="20px"
          >
            Click on a platform to connect:
          </Heading>
        </Center>
        <CreateNewConnectionButtons
          fetchConnections={getConnections}
          token={agencyRequestToken}
        />
        <Center>
          <Text
            fontSize="sm"
            my="5"
          >
            <Text
              color="blue.700"
              as="b"
            >
              {requestingAgency.name}
            </Text>
            &nbsp; will only have read-access to your stats.
          </Text>
        </Center>
        <Center>
          <Text fontSize="sm">
            If you have questions you can read our&nbsp;
            <Link
              to="http://trymediasweet.com/privacypolicy.php"
              target="_blank"
            >
              <Text
                as="u"
                color="blue.500"
              >
                Privacy Policy
              </Text>
            </Link>
          </Text>
        </Center>
        <Box mt="4">
          <Footer />
        </Box>
      </Box>
    </Center>
  );
};

export default AgencyPlatformsRequest;
