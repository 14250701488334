import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Box, Card, Stat, StatLabel, StatNumber, StatHelpText, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faChartSimple, faVenusMars, faEarthAmericas } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

const ChartCard = (props) => {
  const { panel, colors = {primary: "blue", secondary: "red"}, connection, panelIndex, totalCards } = props;

  const [icon, setIcon] = useState(faPlay);
  const [labelValues, setLabelValues] = useState();
  const [dataValues, setDataValues] = useState();
  const [label, setLabel] = useState('Loading...');
  const [helperLabel, setHelperLabel] = useState('');

  useEffect(() => {
    let runningLabels = [],
      runningValues = [];
    switch (panel?.label) {
      case 'age_dist':
        setLabel('Age Distribution');
        setHelperLabel('Last 28 days (%)');
        setIcon(faChartSimple);

        connection?.insights?.age_percent?.rows
          .sort((a, b) => parseFloat(a.name) - parseFloat(b.name))
          .some((row) => {
            runningLabels.push(row.name);
            runningValues.push(parseFloat(row.value));
          });
        break;
      case 'gender_dist':
        setLabel('Gender Distribution');
        setHelperLabel('Last 28 days (%)');
        setIcon(faVenusMars);
        connection?.insights?.gender_percent?.rows
          ?.sort((a, b) => parseFloat(b.value) - parseFloat(a.value))
          ?.some((row) => {
            let rowName = row.name;
            switch (row.name.toLowerCase()) {
              case 'm':
              case 'male':
                rowName = 'Male';
                break;
              case 'f':
              case 'female':
                rowName = 'Female';
                break;
              default:
                rowName = 'Undisclosed';
                break;
            }
            runningLabels.push(rowName);
            runningValues.push(parseFloat(row.value));
          });
        break;
      case 'country_dist':
        setLabel('Country Distribution');
        setHelperLabel('Last 28 days');
        setIcon(faEarthAmericas);
        const rows = connection?.insights?.country_followers?.rows || connection?.insights?.country_views?.rows;
        let totals = 0;
        rows
          ?.sort((a, b) => parseFloat(b.value) - parseFloat(a.value))
          ?.some((row, i) => {
            if (i >= 6) return;
            totals += parseFloat(row.value);
          });
        rows?.some((row, i) => {
          if (i >= 6) return;
          runningLabels.push(row.name);
          runningValues.push(parseFloat((parseFloat(row.value) / totals) * 100));
        });
        break;
      default:
        setLabel(panel.title || panel.label.replace('_', ' '));
        setHelperLabel('Last 28 days');
        break;
    }

    setLabelValues(runningLabels);
    setDataValues(runningValues);
  }, [connection]);

  const data = {
    labels: labelValues,
    datasets: [
      {
        //label: '%',
        data: dataValues, //[12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(228, 112, 223, 0.2)',
          'rgba(249, 109, 132, 0.2)',
          'rgba(238, 128, 38, 0.2)',
          'rgba(170, 161, 40, 0.2)',
          'rgba(102, 175, 46, 0.2)',
          'rgba(60, 174, 172, 0.2)'
        ],
        borderColor: [
          'rgba(228, 112, 223, 1)',
          'rgba(249, 109, 132, 1)',
          'rgba(238, 128, 38, 1)',
          'rgba(170, 161, 40, 1)',
          'rgba(102, 175, 46, 1)',
          'rgba(60, 174, 172, 1)'
        ],
        borderWidth: 1
      }
    ]
  };

  let panelWidth = '345px';
  if (
    totalCards === 2 ||
    totalCards === 4 ||
    (totalCards === 5 && panelIndex <= 2) ||
    (totalCards === 7 && panelIndex <= 4)
  ) {
    panelWidth = '525px';
  }

  return (
    <Card
      minH="125px"
      minW={panelWidth}
      width="100%"
      height="100%"
      as="span"
      p={4}
      color={`gray.1000`}
      position="relative"
    >
      <Stat>
        <StatLabel fontSize="md">{label}:</StatLabel>
        <StatNumber
          fontSize="3xl"
          color={`${colors.primary}.800`}
        >
          <Bar
            height="120px"
            data={data}
            
            options={{
              maintainAspectRatio: false,
              scales: {
                y: {
                  ticks: {
                    precision: 0
                  }
                }
              }
              }}
          />
        </StatNumber>
        {helperLabel && (
          <StatHelpText>
            <Text
              fontSize="xs"
              as="span"
              ml="1"
            >
              {helperLabel}
            </Text>
          </StatHelpText>
        )}
      </Stat>
      {icon && (
        <Box
          position="absolute"
          color={`${colors.primary}.300`}
          top="3"
          right="5"
          fontSize="xl"
        >
          <FontAwesomeIcon icon={icon} />
        </Box>
      )}
    </Card>
  );
};

export default ChartCard;
