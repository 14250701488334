import React, { createContext, useContext, useState } from 'react';
import { getMe } from '../api/user';
import { getAgencyById } from '../api/agency';
import { getAgencyConnections } from '../api/connection';
import { getProductById } from '../api/product';
import { useNavigate } from 'react-router-dom';

export const AppContext = createContext({
  setUser: () => {},
  user: {},
  userMediaKits: [],
  setUserMediaKits: () => {},
  userAgency: {},
  userAgencyConnections: [],
  setUserAgencyConnections: () => {}
});

const AppContextProvider = ({ children, onLoaded, onFailed }) => {
  const [user, setUser] = useState();
  const [userMediaKits, setUserMediaKits] = useState([]);
  const [userAgency, setUserAgency] = useState();
  const [userAgencyProduct, setUserAgencyProduct] = useState();
  const [userAgencyConnections, setUserAgencyConnections] = useState();

  const navigate = useNavigate();

  const jwt = localStorage.getItem('jwt');
  if (typeof user === 'undefined' && jwt) {
    if (JSON.parse(atob(localStorage.getItem('jwt').split('.')[1])).exp < new Date().getTime() / 1000) {
      localStorage.removeItem('jwt');
      return navigate('/login', { replace: true });
    }
    (async () => {
      const loggedUser = await getMe(jwt);
      if (loggedUser?.type === 'tokenExpired') {
        localStorage.removeItem('jwt');
        return navigate('/login', { replace: true });
      } else {
        localStorage.setItem('jwt', jwt);
        setUser(loggedUser);
        if (loggedUser.agencyId) {
          const userAgency = await getAgencyById(loggedUser.agencyId);
          if (userAgency) {
            setUserAgency(userAgency);

            const agencyConnections = await getAgencyConnections(loggedUser.agencyId);
            if (agencyConnections && agencyConnections.length) {
              setUserAgencyConnections(agencyConnections);
            }

            if (userAgency.product) {
              const product = await getProductById(userAgency.product);
              setUserAgencyProduct(product);
            }
          }
        }
        onLoaded();
      }
    })().catch((err) => onFailed(err.message));
  } else {
    onLoaded();
  }

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        userMediaKits,
        setUserMediaKits,
        userAgency,
        userAgencyProduct,
        setUserAgency,
        setUserAgencyProduct,
        userAgencyConnections,
        setUserAgencyConnections
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppContextProvider, useAppContext };
