import { useState } from 'react';
import { useAppContext } from '../hooks/useAppContext';

import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  VStack,
  Select,
  Stack,
  StackDivider,
  HStack,
  Text,
  Textarea,
  Heading
} from '@chakra-ui/react';

const Step1 = (props) => {
  const { userAgency } = useAppContext();
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [displayName, setDisplayName] = useState(props.newMediaKit.displayName);
  const [slug, setSlug] = useState(props.newMediaKit.slug);
  const [bio, setBio] = useState(props.newMediaKit.bio);
  const [headshotObject, setHeadshotObject] = useState(props.newMediaKit.headshot || '');
  const [primaryColor, setColorPrimary] = useState(props.newMediaKit.theme.colors.primary || 'blue');
  const [secondaryColor, setColorSecondary] = useState(props.newMediaKit.theme.colors.secondary || 'pink');
  //const [tags, setTags] = useState(props.newMediaKit.tags);
  // const [contactText, setContactText] = useState(props.newMediaKit.contactText);
  // const [contactLink, setContactLink] = useState(props.newMediaKit.contactLink);

  const setHeadshotImage = (image) => {
    if (!image) return;
    if (image.size / 1000 / 1000 > 25) {
      setFormErrors({
        ...formErrors,
        headshot: "Image can't be larger than 25mb"
      });
    } else {
      setFormErrors({
        ...formErrors,
        headshot: ''
      });
    }
    setHeadshotObject(image);
    props.setHeadshotBlob(URL.createObjectURL(image));
  };

  /**
   * submitForm
   * called when the user is ready for the next step
   */
  const submitForm = async () => {
    setIsLoading(true);
    let errors = {};

    if (!displayName) {
      errors = {
        ...errors,
        displayName: "This display name won't cut it!"
      };
    }
    if (!slug) {
      errors = {
        ...errors,
        slug: 'You forgot to set a URL!'
      };
    } else if (slug.includes(' ')) {
      errors = {
        ...errors,
        slug: "URL slug can't have spaces"
      };
    }
    setFormErrors({ ...errors });

    if (Object.keys(errors).length === 0) {
      props.setNewMediaKit({
        theme: {
          name: 'default',
          colors: {
            primary: primaryColor,
            secondary: secondaryColor
          }
        },
        background: { type: 'image' },
        displayName,
        slug,
        bio,
        //tags,
        headshot: headshotObject,
        pageDisplay: {
          main: [],
          secondary: []
        }
      });
      props.changeStep(1);
    }
    setIsLoading(false);
  };

  const fullUrl = `mediasweet.com/${userAgency?.slug}/`;

  return (
    <VStack divider={<StackDivider borderColor="blue.100" />}>
      <Box>
        <Heading
          mt={4}
          textAlign={['left']}
          fontSize={'2xl'}
        >
          About You
        </Heading>
      </Box>

      <HStack
        align="start"
        mb={3}
        width={'1xl'}
      >
        <VStack>
          <Box
            width="225px"
            height="225px"
            overflow="hidden"
            mb={1}
            borderRadius="xl"
            borderColor={props.headshotError ? 'red.500' : 'none'}
            display="flex"
            alignItems="center"
          >
            <Image
              minW="100%"
              src={props.headshotBlob}
              fallbackSrc="https://via.placeholder.com/225"
            />
          </Box>

          <Box>
            <FormControl isInvalid={formErrors.headshot}>
              <Input
                position="absolute"
                height="0px"
                overflow="hidden"
                width="0px"
                display="none"
                type="file"
                accept="image/png, image/jpeg"
                name="headshot-image-upload"
                id="headshot-image-upload"
                onChange={(e) => {
                  setHeadshotImage(e.target.files[0]);
                }}
              ></Input>

              <FormLabel
                mx="auto"
                width="fit-content"
                cursor="pointer"
                htmlFor="headshot-image-upload"
                fontWeight="bold"
                borderRadius="42px"
                boxShadow="inset 0px -1px 7px rgba(93,86,73, 0.06)"
                background="gray.200"
                padding="8px 12px"
                mt="18px"
                lineHeight="16px"
                color="gray.700"
                fontSize="15px"
              >
                <Text
                  fontWeight="bold"
                  position="relative"
                  bottom="1px"
                >
                  Change Photo
                </Text>
              </FormLabel>
              <FormErrorMessage
                float="right"
                mt={0}
              >
                {formErrors.headshot}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </VStack>

        <VStack>
          <Box>
            <HStack my={3}>
              <Stack spacing={4}>
                <FormControl isInvalid={formErrors.slug}>
                  <InputGroup width={'md'}>
                    <InputLeftAddon children={fullUrl} />
                    <Input
                      value={slug}
                      onChange={(e) => setSlug(e.target.value)}
                      type="text"
                      placeholder="melodysweets"
                    />
                  </InputGroup>
                  <FormErrorMessage
                    float="right"
                    mt={0}
                  >
                    {formErrors.slug}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </HStack>
          </Box>
          <Box>
            <HStack mb={3}>
              <Stack spacing={4}>
                <FormControl
                  isRequired
                  isInvalid={Boolean(formErrors.displayName)}
                >
                  <InputGroup width={'md'}>
                    <InputLeftAddon children="Display Name" />
                    <Input
                      value={displayName}
                      onChange={(e) => setDisplayName(e.target.value)}
                      type="text"
                      placeholder="Melody Sweets"
                    />
                  </InputGroup>
                  <FormErrorMessage
                    float="right"
                    mt={0}
                  >
                    This display name is a no go 
                  </FormErrorMessage>
                </FormControl>
              </Stack>
            </HStack>
          </Box>
          {/*
          <Box>
            <HStack mb={3}>
              <Stack spacing={4}>
                <FormControl isInvalid={false}>
                  <InputGroup width={'md'}>
                    <InputLeftAddon children="Tags" />
                    <Input
                      value={tags}
                      onChange={(e) => setTags(e.target.value)}
                      type="text"
                      placeholder="#gamer, #streamer, #artist, #tech"
                    />
                  </InputGroup>
                </FormControl>
              </Stack>
            </HStack>
          </Box>
          */}
          <Box>
            <HStack mb={3}>
              <Text>Theme: </Text>
              <Stack>
                <Select
                  value={primaryColor}
                  bg={`${primaryColor}.200` || 'white'}
                  onChange={(e) => setColorPrimary(e.target.value)}
                  placeholder="Primary Color"
                >
                  <option value="gray">gray</option>
                  <option value="pink">pink</option>
                  <option value="red">red</option>
                  <option value="orange">orange</option>
                  <option value="yellow">yellow</option>
                  <option value="green">green</option>
                  <option value="blue">blue</option>
                  <option value="purple">purple</option>
                </Select>
              </Stack>
              <Stack>
                <Select
                  value={secondaryColor}
                  bg={`${secondaryColor}.100` || 'white'}
                  onChange={(e) => setColorSecondary(e.target.value)}
                  placeholder="Secondary Color"
                >
                  <option value="gray">gray</option>
                  <option value="pink">pink</option>
                  <option value="red">red</option>
                  <option value="orange">orange</option>
                  <option value="yellow">yellow</option>
                  <option value="green">green</option>
                  <option value="blue">blue</option>
                  <option value="purple">purple</option>
                </Select>
              </Stack>
            </HStack>
          </Box>
        </VStack>
      </HStack>

      <Box>
        <Text fontSize="xl">Biography:</Text>
        <Textarea
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          width={'xl'}
          height={'3xs'}
          type="bio"
          placeholder="Melody Sweet is an accomplished artist, skilled gamer, and engaging streamer with a growing online presence. Her unique combination of talents and interests make her a must-watch for anyone interested in gaming, art, or online communities. Melody's streams are a perfect blend of creativity, entertainment, and community building, and her dedicated fanbase speaks to her ability to connect with viewers on a personal level. She also collaborates with other creators and participates in local events, ensuring that she stays on the cutting edge of the art and gaming worlds."
        />
      </Box>
      {/*<Box>
        <Text fontSize="xl">Contact:</Text>
        <HStack mb={3}>
          <Stack spacing={4}>
            <InputGroup width={'xl'}>
              <InputLeftAddon children="Button name" />
              <Input
                value={contactText}
                onChange={(e) => setContactText(e.target.value)}
                type="text"
                placeholder="Business Contact"
              />
            </InputGroup>
          </Stack>
        </HStack>
        <HStack mb={3}>
          <Stack spacing={4}>
            <InputGroup width={'xl'}>
              <InputLeftAddon children="Contact email" />
              <Input
                value={contactLink}
                onChange={(e) => setContactLink(e.target.value)}
                type="text"
                placeholder="cynthia+melodysweet@agencyventure.com"
              />
            </InputGroup>
          </Stack>
        </HStack>
      </Box>*/}

      <Box mt={6}>
        <Button
          isLoading={isLoading}
          onClick={submitForm}
          background="blue.500"
          color="white"
        >
          Next Step
        </Button>
      </Box>
    </VStack>
  );
};

export default Step1;
