import { Spinner, Center, Text, Image } from '@chakra-ui/react';
import logoPng from '../assets/logos/media-sweet-logo.png';

const Loader = ({ children, isLoaded, text, height, size, showLogo }) => {
  return isLoaded ? (
    children
  ) : (
    <Center
      height={height ?? '100%'}
      display="flex"
      flexDir={'column'}
    >
      {showLogo && (
        <Image
          src={logoPng}
          m="15"
          objectFit="cover"
          width="400px"
          display="inline-block"
        />
      )}
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size={size ?? 'xl'}
      />
      {text && (
        <Text
          mt={4}
          size={size}
        >
          {text}
        </Text>
      )}
    </Center>
  );
};

export default Loader;
