import { useState } from 'react';
import { Box, Container, Editable, EditablePreview, EditableInput, SkeletonText, Heading } from '@chakra-ui/react';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DisplayName = (props) => {
  const { editMode } = props;
  const [textAreaFocus, setTextAreaFocus] = useState(false);
  const [displayName, setDisplayName] = useState(props?.displayName || '');

  const textInput = (e) => {
    if (!props.hasChanged) {
      props.setHasChanged(true);
    }
    setDisplayName(e.target.value);
  };

  const onBlur = () => {
    props.setNewDisplayName(displayName);
    setTextAreaFocus(false);
  };

  return (
    <SkeletonText
      noOfLines={1}
      skeletonHeight={9}
      isLoaded={typeof props.displayName !== 'undefined'}
    >
      <Container
        width="100%"
        position="relative"
        m={0}
        p={0}
        textAlign="left"
      >
        {!editMode ? (
          <Heading
            m={0}
            size="xl"
          >
            {props.displayName}
          </Heading>
        ) : (
          <Editable
            defaultValue={props.displayName}
            fontSize="4xl"
            fontFamily={`"Uncut Sans", sans-serif`}
          >
            <EditablePreview
              id="displayname-textarea"
              cursor="pointer"
              m={0}
              p={0}
            />
            <EditableInput
              fontSize="4xl"
              height="100%"
              m={0}
              p={0}
              onChange={(e) => textInput(e)}
              onFocus={() => setTextAreaFocus(true)}
              onBlur={() => onBlur()}
            />
            {!textAreaFocus && (
              <Box
                cursor="pointer"
                as="span"
                ml="2"
                color="blue.800"
                fontSize="xl"
                onClick={() => document.getElementById('displayname-textarea').focus()}
              >
                <FontAwesomeIcon icon={faPencil} />
              </Box>
            )}
          </Editable>
        )}
      </Container>
    </SkeletonText>
  );
};

export default DisplayName;
