import { Box, Center, Grid, GridItem, Text, Heading } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Header from '../containers/Header';

const NotFound = () => {
  return (
    <Center>
      <Box
        borderRadius="lg"
        maxW="8xl"
        minW="60rem"
        bg="white"
        m="4"
      >
        <Grid
          templateAreas={`"header header""main main""footer footer"`}
          gridTemplateRows={'75px 1fr 30px'}
          gridTemplateColumns={'250px 1fr'}
          gap="0"
          m="4"
          fontWeight="bold"
        >
          <Header />

          <GridItem
            pl="2"
            bg="brand.white"
            area={'main'}
          >
            <Center>
              <Box
                mb={3}
                textAlign="center"
              >
                <Heading
                  mt="2"
                  fontSize="6xl"
                >
                  404 not found
                </Heading>
                <Text my="3">How did we get here?</Text>
                <Link to="/">
                  <Text
                    as="u"
                    color="blue.500"
                  >
                    Go home
                  </Text>
                </Link>
              </Box>
            </Center>
          </GridItem>

          <GridItem
            bg="brand.white"
            area={'footer'}
          >
            <Text
              mt="4"
              fontSize="xs"
              color="gray.500"
              align="center"
            >
              Copyright Media Sweet 2023
            </Text>
          </GridItem>
        </Grid>
      </Box>
    </Center>
  );
};

export default NotFound;
