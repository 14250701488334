// @ts-nocheck
import { get, post, patch } from '../utils/HttpsHandlers';

export const loginAttempt = (email, password) => {
  return post(`/user/login`, { email, password });
};

export const getUploadUrl = (path, filename) => {
  return new Promise(async (resolve, reject) => {
    const { urls } = await post(`/user/file`, { path, filename }, localStorage.getItem('jwt'));
    if (!urls) {
      reject();
    }
    const { signedUrl, publicUrl } = urls;
    if (signedUrl && publicUrl) {
      resolve({ signedUrl, publicUrl });
    } else {
      reject();
    }
  });
};

export const getUserList = () => {
  const token = localStorage.getItem('jwt');
  return get(`/user`, token);
};

export const getUserById = (id) => {
  const token = localStorage.getItem('jwt');
  return get(`/user?id=${id}`, token);
};

export const getMe = (token) => {
  let tokenParts = token.split('.');
  let decoded = atob(tokenParts[1]);
  let userId = decoded.id || JSON.parse(decoded).id;

  return get(`/user?id=${userId}`, token);
};

export const update = (id, params, token) => {
  return patch(`/user/${id}`, { ...params }, token);
};

export const registerAgent = (password, token) => {
  return post(`/user/agent`, { password, token });
};
