import { Route, Routes, Navigate } from 'react-router-dom';
import { useAppContext } from './hooks/useAppContext';
import Home from './pages/Home';
import MediaKit from './pages/MediaKit';
import CreateMediaKit from './pages/CreateMediakit';
import Connections from './pages/Connections';
import ConnectionReport from './pages/ConnectionReport';
import VerifyFacebook from './pages/oauth/facebook';
import VerifyTwitch from './pages/oauth/twitch';
import VerifyYoutube from './pages/oauth/youtube';
import VerifyTiktok from './pages/oauth/tiktok';
import VerifyInstagram from './pages/oauth/instagram';
import VerifyTwitter from './pages/oauth/twitter';
import Account from './pages/Account';
import Reports from './pages/Reports';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import AdminUsers from './pages/admin/Users';
import AdminConnections from './pages/admin/Connections';
import AdminAgencies from './pages/admin/Agencies';
import AdminProducts from './pages/admin/Products';
import EditAgency from './pages/admin/EditAgency';
import EditProduct from './pages/admin/EditProduct';
import EditUser from './pages/admin/EditUser';
import Invites from './pages/admin/Invites';
import EmailTemplates from './pages/admin/EmailTemplates';
import Branding from './pages/AgencyBranding';
import List from './pages/List';
import AgencyPlatformsRequest from './pages/AgencyPlatformsRequest';
import AgencyRegister from './pages/AgencyRegister.js';

function useIsUserLoggedIn() {
  const jwt = localStorage.getItem('jwt');
  if (!jwt) return false;
  const jwtObject = JSON.parse(atob(jwt?.split('.')[1]));
  return Boolean(jwtObject?.id);
}

function useIsUserAdmin() {
  const jwt = localStorage.getItem('jwt');
  if (!jwt) return false;
  const jwtObject = JSON.parse(atob(jwt?.split('.')[1]));
  return Boolean(jwtObject?.isMediasweetAdmin);
}

function PrivateRoute({ children }) {
  const isAuthenticated = useIsUserLoggedIn();
  return isAuthenticated ? children : <Navigate to="/login" />;
}

function AdminRoute({ children }) {
  const isAuthenticated = useIsUserAdmin();
  return isAuthenticated ? children : <Navigate to="/login" />;
}

export default (
  <Routes>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      }
    />
    <Route
      path="/:agencySlug/lists/:listSlug"
      element={<List />}
    />
    <Route
      path="/admin/products/email"
      element={
        <AdminRoute>
          <EmailTemplates />
        </AdminRoute>
      }
    />
    <Route
      path="/admin/agencies"
      element={
        <AdminRoute>
          <AdminAgencies />
        </AdminRoute>
      }
    />
    <Route
      path="/admin/agencies/:id"
      element={
        <AdminRoute>
          <EditAgency />
        </AdminRoute>
      }
    />
    <Route
      path="/admin/products"
      element={
        <AdminRoute>
          <AdminProducts />
        </AdminRoute>
      }
    />
    <Route
      path="/admin/products/:id"
      element={
        <AdminRoute>
          <EditProduct />
        </AdminRoute>
      }
    />
    <Route
      path="/admin/users"
      element={
        <AdminRoute>
          <AdminUsers />
        </AdminRoute>
      }
    />
    <Route
      path="/admin/users/:id"
      element={
        <AdminRoute>
          <EditUser />
        </AdminRoute>
      }
    />
    <Route
      path="/admin/connections"
      element={
        <AdminRoute>
          <AdminConnections />
        </AdminRoute>
      }
    />
    <Route
      path="/admin/invites"
      element={
        <AdminRoute>
          <Invites />
        </AdminRoute>
      }
    />
    <Route
      path="/mediakits/create"
      element={
        <PrivateRoute>
          <CreateMediaKit />
        </PrivateRoute>
      }
    />
    <Route
      path="/:agencySlug/:slug"
      element={<MediaKit />}
    />
    <Route
      path="/mediakits/:slug"
      element={<MediaKit />}
    />

    <Route
      path="/branding"
      element={
        <PrivateRoute>
          <Branding />
        </PrivateRoute>
      }
    />
    <Route
      path="/connections"
      element={
        <PrivateRoute>
          <Connections />
        </PrivateRoute>
      }
    />
    <Route
      path="/reports"
      element={
        <PrivateRoute>
          <Reports/>
        </PrivateRoute>
      }
    />
    <Route
      path="/connections/:connectionId"
      element={
        <PrivateRoute>
          <ConnectionReport />
        </PrivateRoute>
      }
    />
    <Route
      path="/oauth/facebook/verify"
      element={<VerifyFacebook />}
    />
    <Route
      path="/oauth/twitch/verify"
      element={<VerifyTwitch />}
    />
    <Route
      path="/oauth/instagram/verify"
      element={<VerifyInstagram />}
    />
    <Route
      path="/oauth/tiktok/verify"
      element={<VerifyTiktok />}
    />
    <Route
      path="/oauth/youtube/verify"
      element={<VerifyYoutube />}
    />
    <Route
      path="/oauth/twitter/verify"
      element={<VerifyTwitter />}
    />
    <Route
      path="/account"
      element={
        <PrivateRoute>
          <Account />
        </PrivateRoute>
      }
    />
    <Route
      path="/login"
      element={<Login />}
    />
    <Route
      path="/platforms/agency-request"
      element={<AgencyPlatformsRequest />}
    />
    <Route
      path="/agency/register"
      element={<AgencyRegister />}
    />
    <Route
      path="*"
      element={<NotFound />}
    />
  </Routes>
);
