import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  HStack,
  InputGroup,
  InputLeftAddon,
  FormControl,
  FormErrorMessage,
  Input,
  ModalFooter,
  Text,
  Heading
} from '@chakra-ui/react';
import { useToastHook } from '../components/Toast';
import { createConnection } from '../api/connection';
import helpers from '../utils/helpers';

const CreateNewConnectionModal = ({
  isOpen = false,
  setIsOpen = () => {},
  onClose = () => {},
  platform = undefined,
  fetchConnections = () => {},
  token = undefined,
  userAgency = undefined
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState('');
  const [_, newToast] = useToastHook();

  const closeModal = () => {
    setUserName('');
    onClose(false);
  };

  const submitForm = async () => {
    setIsLoading(true);
    try {
      await createConnection({ userName, platform, token });
      await fetchConnections({ userAgency, token });
      newToast({
        status: 'success',
        message: `${helpers.getSocialName(
          platform
        )} connection for ${userName} has been created. It may take a moment to collect your data`,
        duration: 5000
      });
      closeModal();
    } catch (err) {
      newToast({
        status: 'error',
        message: `There was a problem creating this connection, please try again`,
        duration: 5000
      });
    } finally {
      setIsLoading(false);
    }
  };

  const profileUrl = (platform) => {
    switch (platform) {
      case 'instagram':
        return 'https://www.instagram.com/';
      case 'kick':
        return 'https:/kick.com/';
      default:
        return '';
    }
  };

  return (
    <Modal
      isCentered
      width="2xl"
      size="4xl"
      isOpen={isOpen}
      onClose={closeModal}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Connection</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <HStack>
            <Box fontSize="4xl">{helpers.getSocialIcon(platform, `platform.${platform}`)}</Box>
            <Box>
              <Heading
                fontSize="xl"
                mt="2"
                mb="2"
              >
                Enter your {helpers.getSocialName(platform)} username
              </Heading>
            </Box>
          </HStack>
          <Box>
            <FormControl
              mb="3"
              position="relative"
              isInvalid={false}
            >
              <InputGroup>
                <InputLeftAddon>{profileUrl(platform)}</InputLeftAddon>
                <Input
                  width="100%"
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                  value={userName}
                  type="text"
                  alt="creator name"
                  placeholder="Creator Name"
                />
              </InputGroup>
              <Box
                height="auto"
                display="block"
                mb="5"
                mt="2"
              >
                <Text
                  as="span"
                  mr="1"
                >
                  Confirm your url before submitting
                </Text>
                {userName && (
                  <Link
                    to={profileUrl(platform) + userName}
                    target="_blank"
                  >
                    <Text
                      as="u"
                      color="blue.500"
                    >
                      {profileUrl(platform) + userName}
                    </Text>
                  </Link>
                )}
              </Box>
              <Box>
                <Text
                  as="span"
                  mr="1"
                >
                  If you change your {helpers.getSocialName(platform)} username, you will need to update this
                  connection.
                </Text>
              </Box>
              <FormErrorMessage
                position="absolute"
                bottom="-20px"
              >
                {false}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            isDisabled={!userName}
            onClick={() => submitForm()}
            variant="solid"
            colorScheme="blue"
            mb="2"
            isLoading={isLoading}
          >
            Submit
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateNewConnectionModal;
