// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyTikTokOauth } from '../../api/connection';
import VerifyOauth from '../../components/VerifyOauth';

const VerifyTiktok = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(searchParams.get('error_description'));
  const scopes = searchParams.get('scopes');
  const state = searchParams.get('state');
  const code = searchParams.get('code');

  useEffect(() => {
    if (code && state && !verified) {
      verifyTikTokOauth(code, state).then((tiktokResults) => {
        setVerified(true);
        window.opener.postMessage(
          {
            data: tiktokResults
          },
          '*'
        );
        window.close();
      });
    }

    if (!scopes || !state) {
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <VerifyOauth
      error={error}
      title="TikTok Verification"
    />
  );
};

export default VerifyTiktok;
