import { Box, HStack } from '@chakra-ui/react';
import Nav from '../containers/SliderNav';

const DashboardLayout = ({ page, children }) => {
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      bg="white"
      minH="100vh"
    >
      <Box height="100%">
        <HStack
          align="top"
          spacing="0"
        >
          <Box>
            <Nav page={page} />
          </Box>

          <Box
            width="100%"
            maxH="100vh"
            overflow="scroll"
            p="3"
          >
            {children}
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
