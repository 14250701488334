import { get, post, patch, remove } from '../utils/HttpsHandlers';

export const updateProduct = (id, body) => {
  return patch(`/product/${id}`, body, localStorage.getItem('jwt'));
};

export const getProductById = (id) => {
  const token = localStorage.getItem('jwt');
  return get(`/product?id=${id}`, token);
};

export const getProductList = () => {
  const token = localStorage.getItem('jwt');
  return get(`/product`, token);
};

export const createProduct = (newProduct) => {
  const token = localStorage.getItem('jwt');
  return post(`/product`, newProduct, token);
};

export const deleteProduct = (productId) => {
  return remove(`/product/${productId}`, localStorage.getItem('jwt'));
};

export const getEmailTemplates = () => {
  const token = localStorage.getItem('jwt');
  return get(`/product/email`, token);
};

export const createEmailTemplate = (emailTemplate) => {
  const token = localStorage.getItem('jwt');
  return post(`/product/email`, emailTemplate, token);
};

export const updateEmailTemplate = (id, emailTemplate) => {
  return patch(`/product/email/${id}`, emailTemplate, localStorage.getItem('jwt'));
};
