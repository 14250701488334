import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
  Button,
  Center,
  Container,
  Input,
  Image,
  InputGroup,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Table,
  TableContainer,
  TableCaption,
  Th,
  Thead,
  Tbody,
  Tr,
  Td,
  Select,
  Text
} from '@chakra-ui/react';
import { useAppContext } from '../../hooks/useAppContext';
import DashboardLayout from '../../containers/DashboardLayout';
import PageHeader from '../../containers/PageHeader';
import { update } from '../../api/agency';
import { getUploadUrl } from '../../api/user';
import { uploadS3Image } from '../../api/s3';
import { useToastHook } from '../../components/Toast';
import { getAgencyById } from '../../api/agency';
import { getProductList } from '../../api/product';

const EditAgency = () => {
  const { user, setUserAgency } = useAppContext();
  const isAdmin = user?.isMediasweetAdmin;

  const agencyId = window.location.pathname.split('/')[3];
  const [editAgency, setEditAgency] = useState([]);

  const [agencyName, setAgencyName] = useState(editAgency.name);
  const [agencySlug, setAgencySlug] = useState(editAgency.slug);
  const [agencyLogoBlob, setAgencyLogoBlob] = useState();
  const [agencyLogoObject, setAgencyLogoObject] = useState();
  const [agencyProductId, setAgencyProductId] = useState();
  const [agencyProduct, setAgencyProduct] = useState();

  const [productList, setProductList] = useState([]);

  const [agencyFormError, setAgencyFormError] = useState('');
  const [agencyNameError, setAgencyNameError] = useState('');
  const [agencySlugError, setAgencySlugError] = useState('');
  const [agencyLogoError, setAgencyLogoError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [state, newToast] = useToastHook();

  const resetState = () => {
    setAgencyNameError('');
    setAgencySlugError('');
    setAgencyLogoError('');
  };

  const setAgencyLogoImage = (image) => {
    if (image.size / 1000 / 1000 > 25) {
      setAgencyLogoError("Image can't be larger than 25mb");
    } else {
      setAgencyLogoError('');
    }
    setAgencyLogoObject(image);
    setAgencyLogoBlob(URL.createObjectURL(image));
  };

  useEffect(() => {
    getAgencyById(agencyId).then((agency) => {
      setEditAgency(agency);
      setAgencyName(agency.name);
      setAgencySlug(agency.slug);
      getProductList().then((productList) => {
        setProductList(productList);
        setAgencyProduct(productList.filter((product) => product._id === agency.product)[0]);
      });
    });
  }, []);

  const productColumns = ['description', 'mediakits', 'members', 'branding', 'cohorts'];

  const uploadImage = async () => {
    if (agencyLogoObject) {
      try {
        const urls = await getUploadUrl(`/agencies/${agencyId}/branding`, agencyLogoObject.name);
        const { signedUrl, publicUrl } = urls;
        const uploadImage = await uploadS3Image(signedUrl, agencyLogoObject);
        if (uploadImage) {
          return publicUrl;
        }
      } catch (err) {
        //setHeadshotError(true);
        setAgencyLogoError('There was an issue uploading your image');
        setIsLoading(false);
        return false;
      }
    }
  };

  const submitForm = async () => {
    setIsLoading(true);
    let errors = false;

    if (!agencyName || agencyName === '') {
      setAgencyNameError("Agency name can't be blank");
      errors = true;
    }
    if (!agencySlug || !agencySlug === '') {
      setAgencySlugError("Agency slug can't be blank");
      errors = true;
    }

    if (!errors && !agencyLogoError) {
      setIsLoading(false);
      const updateBody = {
        name: agencyName,
        slug: agencySlug,
        product: agencyProductId
      };
      if (agencyLogoObject) {
        const newLogo = await uploadImage();
        if (newLogo) {
          updateBody.logo = newLogo;
        }
      }

      try {
        const result = await update(editAgency._id, updateBody, localStorage.getItem('jwt'));
        if (result?.acknowledged) {
          setEditAgency({ ...editAgency, ...updateBody });
          newToast({
            status: 'success',
            message: 'Agency details have been saved'
          });
          resetState();
        }
      } catch (error) {
        const errorMessage = error?.message || 'An unknown error occurred';
        setAgencyFormError(errorMessage);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout page="agencies">
      <PageHeader
        title="Agencies"
        body={
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/admin/agencies">
                <Text
                  color="blue.500"
                  as="u"
                >
                  agencies
                </Text>
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <Link
                color="blue.500"
                to="#"
              >
                <Text
                  color="blue.500"
                  as="u"
                >
                  {editAgency._id}
                </Text>
              </Link>
            </BreadcrumbItem>
          </Breadcrumb>
        }
      />
      <Box maxW="650px">
        <Box
          mt="10"
          mx="auto"
          width="fit-content"
        >
          {agencyFormError && <Text color="red.500">{agencyFormError}</Text>}
          <Image
            mx="auto"
            borderRadius="lg"
            boxSize="450px"
            height="150px"
            objectFit="cover"
            src={agencyLogoBlob || editAgency.logo}
            fallbackSrc="https://via.placeholder.com/450x150"
          ></Image>
          <FormControl isInvalid={agencyLogoError}>
            <Input
              position="absolute"
              height="0px"
              overflow="hidden"
              width="0px"
              display="none"
              type="file"
              accept="image/png, image/jpeg"
              name="headshot-image-upload"
              id="headshot-image-upload"
              onChange={(e) => {
                setAgencyLogoImage(e.target.files[0]);
              }}
            ></Input>
            <FormLabel
              width="fit-content"
              mx="auto"
              cursor="pointer"
              htmlFor="headshot-image-upload"
              fontWeight="bold"
              borderRadius="42px"
              boxShadow="inset 0px -1px 7px rgba(93,86,73, 0.06)"
              background="gray.200"
              padding="8px 12px"
              mt="18px"
              lineHeight="16px"
              color="gray.700"
              fontSize="15px"
            >
              <Text
                fontWeight="bold"
                position="relative"
                bottom="1px"
              >
                Change Logo
              </Text>
            </FormLabel>
            <FormErrorMessage
              float="right"
              mt={0}
            >
              {agencyLogoError}
            </FormErrorMessage>
          </FormControl>
        </Box>

        <Box mb={8}>
          <FormControl
            mt="6"
            isInvalid={agencyNameError}
          >
            <FormLabel>Company Name:</FormLabel>
            <Input
              value={agencyName}
              onChange={(e) => {
                setAgencyNameError('');
                setAgencyName(e.target.value);
              }}
            />
            <FormErrorMessage>{agencyNameError}</FormErrorMessage>
          </FormControl>

          <FormControl
            mt="6"
            isInvalid={agencySlugError}
          >
            <FormLabel>URL Slug:</FormLabel>
            <InputGroup width={'md'}>
              <Input
                value={agencySlug}
                onChange={(e) => {
                  setAgencySlugError('');
                  setAgencySlug(e.target.value.replace(' ', '-'));
                }}
                type="agency-name"
              />
            </InputGroup>
            <FormErrorMessage
              float="left"
              mt={0}
            >
              {agencySlugError}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            mt="6"
            isInvalid={agencySlugError}
          >
            <FormLabel>Product:</FormLabel>
            <Select
              placeholder="Select product by name"
              value={agencyProductId}
              onChange={(e) => {
                setAgencyProductId(e.target.value);
                setAgencyProduct(productList.filter((product) => product._id === e.target.value)[0]);
              }}
            >
              {productList.map((product) => {
                return (
                  <option
                    selected={product?._id === agencyProduct?._id}
                    value={product?._id}
                  >
                    {product?.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>

          <TableContainer>
            <Table
              size="sm"
              variant="striped"
            >
              <TableCaption></TableCaption>
              <Thead>
                <Tr>
                  {agencyProduct &&
                    productColumns.map((column) => {
                      return <Th key={column}>{column}</Th>;
                    })}
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  {agencyProduct &&
                    productColumns.map((key) => {
                      return <Td>{String(agencyProduct[key])}</Td>;
                    })}
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <Box mt={6}>
          <Button
            isDisabled={
              agencyName === editAgency.name &&
              agencySlug === editAgency.slug &&
              !agencyLogoBlob &&
              editAgency.product === agencyProductId
            }
            isLoading={isLoading}
            onClick={submitForm}
            background="blue.500"
            color="white"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default EditAgency;
