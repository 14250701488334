// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyTwitchOauth } from '../../api/connection';
import VerifyOauth from '../../components/VerifyOauth';

const VerifyTwitch = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const twitchCode = searchParams.get('code');

  useEffect(() => {
    if (twitchCode && !verified) {
      verifyTwitchOauth(twitchCode).then((twitchResults) => {
        setVerified(true);
        window.opener.postMessage(
          {
            data: twitchResults
          },
          '*'
        );
        window.close();
      });
    }

    if (!twitchCode) {
      navigate('/', { replace: true });
    }
  }, []);

  return <VerifyOauth title="Twitch Verification" />;
};

export default VerifyTwitch;
