import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import { Box, Card, Stat, StatLabel, StatNumber, StatHelpText, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip);

const LineChartCard = (props) => {
  const {
    label = 'Loading...',
    helperLabel,
    dataSet = [],
    labels = [],
    colors = { primary: 'blue', secondary: 'red' }
  } = props;

  const [icon, setIcon] = useState(props.icon || faPlay);
  const [labelValues, setLabelValues] = useState([]);
  const [dataValues, setDataValues] = useState([]);

  const data = {
    labels: labels,
    datasets: [
      {
        //label: '%',
        data: dataSet, //[12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(228, 112, 223, 0.2)',
          'rgba(249, 109, 132, 0.2)',
          'rgba(238, 128, 38, 0.2)',
          'rgba(170, 161, 40, 0.2)',
          'rgba(102, 175, 46, 0.2)',
          'rgba(60, 174, 172, 0.2)'
        ],
        borderColor: [
          'rgba(228, 112, 223, 1)',
          'rgba(249, 109, 132, 1)',
          'rgba(238, 128, 38, 1)',
          'rgba(170, 161, 40, 1)',
          'rgba(102, 175, 46, 1)',
          'rgba(60, 174, 172, 1)'
        ],
        borderWidth: 3
      }
    ]
  };

  return (
    <Card
      width="100%"
      height="100%"
      as="span"
      p={4}
      color={`gray.1000`}
      position="relative"
    >
      <Stat>
        <StatLabel
          mb="3"
          fontSize="md"
        >
          {label}
        </StatLabel>
        <StatNumber
          fontSize="3xl"
          color={`${colors.primary}.800`}
        >
          <Line
            height="120px"
            data={data}
            options={{
              interaction: {
                mode: 'index',
                intersect: false
              },
              maintainAspectRatio: false,
              responsive: true,
              scales: {
                y: {
                  ticks: {
                    precision: 0
                  }
                }
              }
            }}
          />
        </StatNumber>
        {helperLabel && (
          <StatHelpText>
            <Text
              fontSize="xs"
              as="span"
              ml="1"
            >
              {helperLabel}
            </Text>
          </StatHelpText>
        )}
      </Stat>
      {icon && (
        <Box
          position="absolute"
          color={`${colors.primary}.300`}
          top="3"
          right="5"
          fontSize="xl"
        >
          <FontAwesomeIcon icon={icon} />
        </Box>
      )}
    </Card>
  );
};

export default LineChartCard;
