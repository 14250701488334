// @ts-nocheck

import { useState } from 'react';
import { Box, Container, FormControl, FormLabel, Input, FormErrorMessage, Image, Skeleton } from '@chakra-ui/react';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const HeadshotAvatar = (props) => {
  const { editMode } = props;

  const [headshotBlob, setHeadshotBlob] = useState(props.headshot);
  const [formErrors, setFormErrors] = useState({});

  const setHeadshotImage = (image) => {
    if (image.size / 1000 / 1000 > 25) {
      setFormErrors({
        ...formErrors,
        headshot: "Image can't be larger than 25mb"
      });
    } else {
      setFormErrors({
        ...formErrors,
        headshot: ''
      });
    }
    props.setNewHeadshot(image);
    props.setNewHeadshotObject(URL.createObjectURL(image));
    props.setHasChanged(true);

    setHeadshotBlob(URL.createObjectURL(image));
  };

  return (
    <Skeleton isLoaded={typeof props.headshot !== 'undefined'}>
      <Container position="relative">
        <Image
          borderRadius="lg"
          boxSize="250px"
          objectFit="cover"
          src={headshotBlob || props.headshot}
        ></Image>

        {editMode && (
          <Box
            top="-10px"
            right="0px"
            position="absolute"
          >
            <FormControl isInvalid={formErrors.headshot}>
              <Input
                position="absolute"
                height="0px"
                overflow="hidden"
                width="0px"
                display="none"
                type="file"
                accept="image/png, image/jpeg"
                name="headshot-image-upload"
                id="headshot-image-upload"
                onChange={(e) => {
                  setHeadshotImage(e.target.files[0]);
                }}
              ></Input>

              <FormLabel
                mx="auto"
                cursor="pointer"
                htmlFor="headshot-image-upload"
              >
                <Box
                  py="2"
                  px="3"
                  boxShadow="base"
                  borderRadius="xl"
                  bg="gray.100"
                  color="blue.800"
                >
                  <FontAwesomeIcon icon={faPencil} />
                </Box>
              </FormLabel>
              <FormErrorMessage
                float="right"
                mt={0}
              >
                {formErrors.headshot}
              </FormErrorMessage>
            </FormControl>
          </Box>
        )}
      </Container>
    </Skeleton>
  );
};

export default HeadshotAvatar;
