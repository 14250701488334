import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../hooks/useAppContext';
import {
  Box,
  Card,
  HStack,
  Avatar,
  Divider,
  Stat,
  StatLabel,
  StatNumber,
  Spinner,
  Center,
  Text,
  Tag,
  TagLabel,
  Skeleton,
  Heading,
  SimpleGrid,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import { fetchConnections } from '../actions/connections';
import DashboardLayout from '../containers/DashboardLayout';
import PageHeader from '../containers/PageHeader';
import { useToastHook } from '../components/Toast';
import StatCard from '../components/StatCard';
import helpers from '../utils/helpers';
import Loader from '../components/Loader';
import ConnectionsTable from '../containers/ConnectionsTableContainer';
import LineChartMultiCard from '../components/LineChartMultiCard';
import { Link } from 'react-router-dom';
import { faUserGroup, faHeart, faBell } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Reports = () => {
  const { user, userAgency, userAgencyConnections } = useAppContext();
  const [state, newToast] = useToastHook();
  const isAdmin = user?.isMediasweetAdmin || false;
  const agencyId = userAgency?._id || false;
  const [range, setRange] = useState('28d');
  const [loadingConnection, setLoadingConnection] = useState(true);
  const [connectionList, setConnectionList] = useState({});

  const [platformFollowers, setPlatformFollowers] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);
  const [lineChartLabels, setLineChartLabels] = useState([]);

  useEffect(() => {
    try {
      setLoadingConnection(true);
      fetchConnections({ userAgency, range }).then((connections) => {
        setConnectionList(connections);
        calcLineChartData(connections);
      });
    } catch (err) {
      newToast({
        status: 'error',
        message: 'Unable to obtain reports at this time, please try again later'
      });
    } finally {
      setLoadingConnection(false);
    }
  }, [userAgency]);

  useEffect(() => {
    if (userAgencyConnections && userAgencyConnections.length > 1) {
      setFollowersByPlatform(userAgencyConnections);
    }
  }, [userAgencyConnections]);

  const setFollowersByPlatform = (connections) => {
    const platforms = {};
    connections.forEach((connection) => {
      platforms[connection.platform] = platforms[connection.platform]
        ? platforms[connection.platform] + (connection.stats[0]?.followers || connection.stats[0]?.subscribers)
        : connection.stats[0]?.followers || connection.stats[0]?.subscribers || 0;
    });
    setPlatformFollowers(platforms);
  };

  const calcLineChartData = (connections) => {
    let data = [];
    let labels = [];
    connections.forEach((connection) => {
      let followers = [];
      const stats = connection.stats.sort((a, b) => new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime());

      stats.map((stat, i) => {
        if (i > labels.length) {
          labels.push(helpers.formatDate(stat.createdAt, false));
        }
        if (stat.followers) {
          followers.push(stat.followers);
        } else if (stat.subscribers) {
          followers.push(stat.subscribers);
        } else {
          followers.push(undefined);
        }
      });
      data.push({
        label: connection.userName,
        platform: connection.platform,
        data: followers
      });
    });
    setLineChartData(data);
    setLineChartLabels(labels);
  };

  return (
    <DashboardLayout page="reports">
      <PageHeader
        title={
          <Box>
            Reports
            <Tag
              ml="3"
              top="2"
              position="relative"
              size={'md'}
              variant="subtle"
              color={`blue.700`}
              bg={`blue.100`}
            >
              <TagLabel>BETA</TagLabel>
            </Tag>
          </Box>
        }
      />

      <Loader
        text="Loading report"
        isLoaded={true}
      >
        <Box>
          <Heading fontSize="23px">All Platform Followers</Heading>
          <SimpleGrid
            minChildWidth="200px"
            spacing="4"
          >
            {Object.keys(platformFollowers).map((platform, idx) => {
              return (
                <Card
                  minH="125px"
                  width="100%"
                  height="100%"
                  as="span"
                  p={4}
                  color={`gray.1000`}
                  position="relative"
                >
                  <Stat>
                    <StatLabel fontSize="md">{helpers.getSocialName(platform)}</StatLabel>
                    <StatNumber
                      fontSize="3xl"
                      color={`gray.800`}
                    >
                      <Box
                        mr="2"
                        as="span"
                      >
                        {helpers.getSocialIcon(platform?.toLowerCase())}
                      </Box>
                      {helpers.formatNumber(platformFollowers[platform])}
                    </StatNumber>
                    {/* helperKey && (
                  <StatHelpText>
                    {Boolean(helperValue) && <Text as="span">{parseInt(helperValue).toLocaleString()}</Text>}
                    <Text
                      fontSize="xs"
                      as="span"
                      ml="1"
                    >
                      {helperKey}
                    </Text>
                  </StatHelpText>
                )*/}
                  </Stat>
                </Card>
              );
            })}
          </SimpleGrid>
        </Box>
        <Divider my="4" />
        <Box>
          <Skeleton
            height="400px"
            isLoaded={lineChartData.length > 0}
          >
            {lineChartData.length > 0 && (
              <LineChartMultiCard
                icon={faUserGroup}
                label="Followers by Connection (28 days)"
                labels={lineChartLabels}
                dataSet={lineChartData}
              />
            )}
          </Skeleton>
        </Box>

        <Divider my="4" />

        <Box minHeight="400px">
          {userAgencyConnections && userAgencyConnections.length > 0 && (
            <ConnectionsTable connections={userAgencyConnections} />
          )}
        </Box>
      </Loader>
    </DashboardLayout>
  );
};

export default Reports;
