import { Box, Text } from '@chakra-ui/react';

const Footer = () => {
  return (
    <Box>
      <Text
        fontSize="xs"
        color="gray.500"
        align="center"
      >
        Copyright Media Sweet Inc {new Date().getFullYear()}
      </Text>
    </Box>
  );
};

export default Footer;
