import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  InputGroup,
  ListItem,
  Text,
  UnorderedList
} from '@chakra-ui/react';
import { useAppContext } from '../hooks/useAppContext';
import { useToastHook } from '../components/Toast';
import DashboardLayout from '../containers/DashboardLayout';
import PageHeader from '../containers/PageHeader';
import { update } from '../api/user';

const Account = () => {
  const { user, setUser, setUserMediaKits, setUserAgency } = useAppContext();
  const isAdmin = user?.isMediasweetAdmin;
  const navigate = useNavigate();

  const [formInput, setFormInput] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [_, newToast] = useToastHook();

  const changeText = (key, value) => {
    setFormInput({ ...formInput, [key]: value });
  };

  const submitForm = async () => {
    setIsLoading(true);
    let errors = {};

    if (!formInput?.password) {
      errors = {
        ...errors,
        password: 'Please enter a password'
      };
    }

    if (!formInput?.password2) {
      errors = {
        ...errors,
        password2: 'Please enter a password'
      };
    }

    if (formInput?.password !== formInput.password2) {
      errors = {
        ...errors,
        password: 'Passwords must match'
      };
    }

    if (formInput?.password.length < 8) {
      errors = {
        ...errors,
        password: 'Password minimum length is 8'
      };
    }

    if (!/\d/.test(formInput?.password)) {
      errors = {
        ...errors,
        password: 'Password must contain at least one number'
      };
    }

    setFormErrors({ ...errors });
    if (Object.keys(errors).length === 0) {
      update(user._id, formInput, localStorage.getItem('jwt'))
        .then((res) => {
          if (res?.acknowledged) {
            newToast({
              status: 'success',
              message: "We've updated your account for you."
            });
            setFormInput({ password: '', password2: '' });
          }
        })
        .catch((err) => {
          console.error(err);
          newToast({
            status: 'error',
            message: 'There was an error updating your account.'
          });
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout page="account">
      <PageHeader
        title="Account"
        body={
          <Text
            textAlign={['left']}
            fontSize={'md'}
          >
            Email: {user?.email}
          </Text>
        }
      />

      <Box maxW="450px">
        <Box mb={3}>
          <Heading
            mt={5}
            fontSize={'2xl'}
          >
            Change Password:
          </Heading>
        </Box>

        <Box my={3}>
          <Text
            textAlign={['left']}
            fontSize={'md'}
          >
            Password Requirements:
            <UnorderedList>
              <ListItem>8 character minimum length</ListItem>
              <ListItem>Must include at least one number</ListItem>
            </UnorderedList>
          </Text>
        </Box>

        <Box mb={3}>
          Password:
          <FormControl isInvalid={formErrors.password}>
            <InputGroup width={'md'}>
              <Input
                value={formInput.password}
                onChange={(e) => changeText('password', e.target.value)}
                type="password"
                placeholder=""
              />
            </InputGroup>
            <FormErrorMessage
              float="right"
              mt={0}
            >
              {formErrors.password}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box mb={3}>
          Password (again):
          <FormControl isInvalid={formErrors.password2}>
            <InputGroup width={'md'}>
              <Input
                value={formInput.password2}
                onChange={(e) => changeText('password2', e.target.value)}
                type="password"
                placeholder=""
              />
            </InputGroup>
            <FormErrorMessage
              float="right"
              mt={0}
            >
              {formErrors.password2}
            </FormErrorMessage>
          </FormControl>
        </Box>
        <Box mt={6}>
          <Button
            isDisabled={!formInput?.password || !formInput.password2}
            isLoading={isLoading}
            onClick={submitForm}
            background="blue.500"
            color="white"
          >
            Submit
          </Button>
        </Box>
        <Divider my="6" />
        <Box mt="12">
          <Button
            colorScheme="red"
            variant="outline"
            onClick={() => {
              localStorage.removeItem('jwt');
              localStorage.removeItem('invite-token');
              setUser();
              setUserAgency();
              setUserMediaKits([]);
              navigate('/login', { replace: true });
            }}
          >
            logout
          </Button>
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default Account;
