import { getAgencyConnections, getUserConnections, getUserConnectionsByToken } from '../api/connection';

export const fetchConnections = async ({ userAgency, user, token, range }) => {
  let connections = [];
  try {
    if (userAgency?._id) {
      connections = await getAgencyConnections(userAgency._id, range);
    } else if (user && !user.agencyId) {
      connections = await getUserConnections(range);
    } else if (token) {
      connections = await getUserConnectionsByToken(token);
    }
  } catch (err) {
    throw err
  }
  return connections;
};
