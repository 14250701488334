import { useState } from 'react';
import { Box, Button, VStack, Wrap, WrapItem } from '@chakra-ui/react';
import { useToastHook } from '../components/Toast';
import MediaKitCard from '../components/DashboardMediaKitCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const MediaKitCards = (
  props = {
    canCreate: true,
    agencySlug: '',
    mediakits: [],
    mediaKitsFilter: {},
    connections: [],
    public: false,
    toggleMediaKitSelect: () => {},
    mode: 'view' // or 'create-list'
  }
) => {
  const navigate = useNavigate();
  const [_, newToast] = useToastHook();
  const [mediakits, setMediaKits] = useState(props.mediakits ?? []);

  const removeMediaKit = (mediaKitId) => {
    let slug = '';
    const removedKits = mediakits.filter((mediaKit) => {
      slug = mediaKit.slug;
      return mediaKit._id !== mediaKitId;
    });
    newToast({
      status: 'success',
      message: `Your media kit ${slug} has been deleted`,
      duration: 5000
    });
    setMediaKits(removedKits);
  };

  return (
    <Wrap
      mt="6"
      spacing="4"
    >
      {mediakits.length > 0 &&
        mediakits?.map((mediakit) => {
          if (
            (props.mediaKitsFilter?.ids?.length && props.mediaKitsFilter?.ids?.includes(mediakit._id)) ||
            !props.mediaKitsFilter?.ids
          ) {
            return (
              <WrapItem key={mediakit.slug}>
                <MediaKitCard
                  key={props.connections?.length}
                  public={props.public}
                  toggleMediaKitSelect={props.toggleMediaKitSelect}
                  mode={props.mode}
                  agencySlug={props.agencySlug}
                  connections={props.connections}
                  removeMediaKit={removeMediaKit}
                  mediakit={mediakit}
                />
              </WrapItem>
            );
          }
        })}

      {props.canCreate && (
        <WrapItem>
          <Button
            onClick={() => navigate('/mediakits/create')}
            minW="300px"
            width="100%"
            height="330px"
            color="blue.500"
            borderWidth="1px"
            cursor="pointer"
            borderRadius="lg"
            overflow="hidden"
            position="relative"
            bg="gray.100"
          >
            <VStack>
              <Box fontSize="4xl">
                <FontAwesomeIcon icon={faPlus} />
              </Box>
              <Box fontSize="sm">Create media kit</Box>
            </VStack>
          </Button>
        </WrapItem>
      )}
    </Wrap>
  );
};

export default MediaKitCards;
