import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Center, Avatar, Text } from '@chakra-ui/react';
import Loader from '../../components/Loader';
import { useAppContext } from '../../hooks/useAppContext';
import DashboardLayout from '../../containers/DashboardLayout';
import PageHeader from '../../containers/PageHeader';
import { getAgencyList, createAgency } from '../../api/agency';
import CreateAgencyModal from '../../components/CreateAgency';
import helpers from '../../utils/helpers';
import SortedTable from '../../components/SortingTable';

const AdminAgencies = () => {
  const { user } = useAppContext();
  const isAdmin = user?.isMediasweetAdmin || false;
  const [agencyList, setAgencyList] = useState([]);
  const [isCreateAgencyOpen, setIsCreateAgencyOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAgencyList().then((agencys) => {
      agencys = agencys.map((agency) => {
        return {
          actions: (
            <Link to={`/admin/agencies/${agency._id}`}>
              <Text as="u">Edit</Text>
            </Link>
          ),
          logo: agency.logo ? (
            <Avatar
              height="20px"
              src={String(agency.logo)}
            />
          ) : (
            <></>
          ),
          id: agency._id,
          name: agency.name,
          slug: agency.slug,
          product: (
            <Link to={`/admin/products/${agency.product}`}>
              <Text as="u">{agency.product}</Text>
            </Link>
          ),
          createdAt: agency.createdAt ? helpers.formatDate(agency.createdAt) : ''
        };
      });

      setAgencyList(agencys);
      setIsLoading(false);
    });
  }, []);

  const submitNewAgency = async (newAgency) => {
    try {
      const agency = await createAgency(newAgency);
      const newAgencyList = [...agencyList, agency];
      setAgencyList(newAgencyList);
      setIsCreateAgencyOpen(false);
      setErrorMessage('');
    } catch (error) {
      if (error?.message) {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <DashboardLayout page="agencies">
      <PageHeader
        title="Agencies"
        body={
          <Button
            colorScheme="blue"
            p="2"
            mb="3"
            height="fit-content"
            onClick={() => setIsCreateAgencyOpen(true)}
          >
            Create Agency
          </Button>
        }
      />

      <Box>
        <CreateAgencyModal
          isOpen={isCreateAgencyOpen}
          onSubmit={async (agency) => await submitNewAgency(agency)}
          onClose={() => setIsCreateAgencyOpen(false)}
          errorMessage={errorMessage}
        />
        <Loader
          text="Loading Agencies..."
          isLoaded={!isLoading && agencyList.length != 0}
        >
          <SortedTable rows={agencyList} />
        </Loader>
      </Box>
    </DashboardLayout>
  );
};

export default AdminAgencies;
