import helpers from '../utils/helpers';
import { useState, useEffect } from 'react';
import { Avatar, AvatarBadge, Box, Button, Center, Container, Divider, Text, Tooltip, Wrap } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { deleteConnectionByUser, deleteConnectionByToken } from '../api/connection';
import AlertPopOver from '../components/AlertDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faLineChart } from '@fortawesome/free-solid-svg-icons';
import SortedTable from '../components/SortingTable';
import { useToastHook } from '../components/Toast';

const ConnectionsTable = ({ connections, connectionsLength, token }) => {
  const [_, newToast] = useToastHook();
  const [deleteDialog, setDeleteDialog] = useState();
  const [connectionToDelete, setConnectionToDelete] = useState();
  const [connectionList, setConnectionList] = useState(connections || []);
  const [tableData, setTableData] = useState([]);

  const discardConnectionDelete = () => {
    setConnectionToDelete();
    setDeleteDialog(false);
  };

  const deleteConnection = async ({ token, connectionId }) => {
    try {
      if (token) {
        await deleteConnectionByToken(connectionId, token);
      } else {
        await deleteConnectionByUser(connectionId);
      }
      newToast({
        status: 'success',
        message: 'Connection sucessfully deleted',
        duration: 5000
      });
      const newConnections = connectionList.filter((connection) => connection._id !== connectionId);
      setConnectionList([...newConnections]);
      discardConnectionDelete();
      createTableData([...newConnections]);
    } catch (err) {
      newToast({
        status: 'error',
        message: 'Something went wrong deleting this connection.',
        duration: 5000
      });
    }
  };

  useEffect(() => {
    createTableData(connections);
    setConnectionList([...connections]);
  }, [connections, connectionsLength]);

  const createTableData = (connections) => {
    const tableRows = connections?.map((connection) => {
      return {
        '': (
          <Center>
            <Link
              to={'/connections/' + connection._id}
              padding="0"
              variant="outline"
              color="blue.500"
            >
              <Tooltip
                hasArrow
                label={'View reports'}
                bg="gray.300"
                color="black"
                placement="top"
              >
                <FontAwesomeIcon icon={faLineChart} />
              </Tooltip>
            </Link>
          </Center>
        ),
        username: connection.userName,
        profile: (
          <Link
            to={connection.profileUrl}
            target="_blank"
          >
            <Text
              mr="8px"
              fontSize="lg"
              height="15px"
              position="relative"
              display="inline-block"
            >
              {helpers.getSocialIcon(connection.platform, undefined, 'lg')}
            </Text>
            <Text
              as="span"
              decoration="underline"
              fontSize="xs"
            >
              {connection.profileUrl}
            </Text>
          </Link>
        ),
        followers: Number(connection?.stats[0]?.followers)
          ? Number(connection?.stats[0]?.followers).toLocaleString()
          : Number(0),
        subscribers: Number(connection.stats[0]?.subscribers)
          ? Number(connection?.stats[0]?.subscribers).toLocaleString()
          : Number(0),
        views: Number(connection?.stats[0]?.views) ? Number(connection?.stats[0]?.views).toLocaleString() : Number(0),
        //'connected on': helpers.formatDate(connection.createdAt),
        'data updated': helpers.formatDate(connection.updatedAt),
        delete: (
          <Button
            onClick={() => {
              setDeleteDialog(true);
              setConnectionToDelete(connection);
            }}
            p="1"
            height="auto"
            alt="close button"
            colorScheme="red"
          >
            <FontAwesomeIcon icon={faClose} />
          </Button>
        )
      };
    });
    setTableData(tableRows);
  };

  return (
    <>
      <AlertPopOver
        title={`Delete Connection: ${connectionToDelete?.userName}`}
        type="delete"
        isOpen={deleteDialog}
        onClose={() => discardConnectionDelete()}
        onConfirm={async () => await deleteConnection({ token, connectionId: connectionToDelete?._id })}
        dialogContent={
          <Container>
            <Center mb="6">
              <Avatar
                size="xl"
                name={`${connectionToDelete?.userName}`}
                src={connectionToDelete?.thumbnailUrl}
                borderWidth="5px"
              >
                <AvatarBadge
                  borderColor="white"
                  bg={`${connectionToDelete?.platform == 'kick' ? '#000' : `platform.${connectionToDelete?.platform}`}`}
                  boxSize="1.25em"
                >
                  <Text fontSize="lg"> {helpers.getSocialIcon(connectionToDelete?.platform, '#FFFFFF', 'lg')}</Text>
                </AvatarBadge>
              </Avatar>
            </Center>
            <Box
              mx="0"
              my="3"
            >
              <Text as="paragraph">
                Deleting this connection will remove it from all media kits, and permanently delete all historical
                stats.
              </Text>
            </Box>
            <Box
              mx="0"
              my="3"
            >
              <Text color="red.500">
                If there are any media kits where this is the <Text as="u">only</Text> connection, those media kits will
                be deleted.
              </Text>
            </Box>
          </Container>
        }
      />
      {tableData.length !== 0 && (
        <SortedTable
          rows={tableData}
          length={tableData.length}
        />
      )}
    </>
  );
};

export default ConnectionsTable;
