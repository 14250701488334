import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../hooks/useAppContext';
import {
  Box,
  Card,
  Button,
  HStack,
  Avatar,
  Divider,
  Center,
  Text,
  Heading,
  Tag,
  TagLabel,
  Table,
  TableContainer,
  TableCaption,
  SimpleGrid,
  Tr,
  Th,
  Td,
  Tbody,
  Wrap,
  WrapItem
} from '@chakra-ui/react';
import PlatformsContainer from '../containers/PlatformsContainer';
import { getAgencyConnections, getConnectionById } from '../api/connection';
import DashboardLayout from '../containers/DashboardLayout';
import PageHeader from '../containers/PageHeader';
import helpers from '../utils/helpers';
import Loader from '../components/Loader';
import ChartCard from '../components/ChartCard';
import ContentCard from '../components/ContentCard';
import LineChartCard from '../components/LineChartCard';
import StatCard from '../components/StatCard';
import { Link } from 'react-router-dom';
import { faUserGroup, faHeart, faBell } from '@fortawesome/free-solid-svg-icons';

const Connections = () => {
  const { user, userAgency, userAgencyConnections } = useAppContext();
  const isAdmin = user?.isMediasweetAdmin || false;
  const agencyId = userAgency?._id || false;

  const [range, setRange] = useState('28d');

  const [loadingConnection, setLoadingConnection] = useState(true);

  const [connection, setConnection] = useState();
  const [error, setError] = useState('');
  const { connectionId } = useParams();

  const [subscribersData, setSubscribersData] = useState([]);
  const [subscribersLabels, setSubscribersLabels] = useState([]);
  const [followersData, setFollowersData] = useState([]);
  const [followersLabels, setFollowersLabels] = useState([]);
  const [viewsData, setViewsData] = useState([]);
  const [viewsLabels, setViewsLabels] = useState([]);
  const [postsData, setPostsData] = useState([]);
  const [postsLabels, setPostsLabels] = useState([]);
  const [likesData, setLikesData] = useState([]);
  const [likesLabels, setLikesLabels] = useState([]);

  const [latestFollowers, setLatestFollowers] = useState(undefined);
  const [latestSubscribers, setLatestSubscribers] = useState(undefined);
  const [latestLikes, setLatestLikes] = useState(undefined);
  const [latestViews, setLatestViews] = useState(undefined);
  const [latestPosts, setLatestPosts] = useState(undefined);

  useEffect(() => {
    setLoadingConnection(true);
    getConnectionById([connectionId], range)
      .then((connection) => {
        setLoadingConnection(false);
        setConnection(connection[0]);

        let followers = [];
        let folLabels = [];
        let subscribers = [];
        let subLabels = [];
        let views = [];
        let viewLabels = [];
        let posts = [];
        let postLabels = [];
        let likes = [];
        let likeLabels = [];
        const stats = connection[0].stats.sort(
          (a, b) => new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime()
        );
        stats.map((stat) => {
          if (stat.followers) {
            if (!latestFollowers) {
              setLatestFollowers(stat.followers);
            }
            followers.push(parseInt(stat.followers));
            folLabels.push(helpers.formatDate(stat.createdAt, false));
          }
          if (stat.subscribers) {
            if (!latestSubscribers) {
              setLatestFollowers(stat.subscribers);
            }
            subscribers.push(parseInt(stat.subscribers));
            subLabels.push(helpers.formatDate(stat.createdAt, false));
          }
          if (stat.views) {
            if (!latestViews) {
              setLatestFollowers(stat.views);
            }
            views.push(parseInt(stat.views));
            viewLabels.push(helpers.formatDate(stat.createdAt, false));
          }
          if (stat.posts) {
            if (!latestPosts) {
              setLatestPosts(stat.posts);
            }
            posts.push(parseInt(stat.posts));
            postLabels.push(helpers.formatDate(stat.createdAt, false));
          }
          if (stat.likes) {
            if (!latestLikes) {
              setLatestLikes(stat.likes);
            }
            likes.push(parseInt(stat.likes));
            likeLabels.push(helpers.formatDate(stat.createdAt, false));
          }
        });

        setFollowersData(followers);
        setFollowersLabels(folLabels);
        setSubscribersData(subscribers);
        setSubscribersLabels(subLabels);
        setViewsData(views);
        setViewsLabels(viewLabels);
        setPostsData(posts);
        setPostsLabels(postLabels);
        setLikesData(likes);
        setLikesLabels(likeLabels);
      })
      .catch((err) => {
        setLoadingConnection(false);
        setError('Unable to obtain reports at this time, please try again later');
      });
  }, []);

  return (
    <DashboardLayout page="reports">
      <PageHeader
        title={
          <Box>
            Reports
            <Tag
              ml="3"
              top="2"
              position="relative"
              size={'md'}
              variant="subtle"
              color={`blue.700`}
              bg={`blue.100`}
            >
              <TagLabel>BETA</TagLabel>
            </Tag>
          </Box>
        }
      />

      <Loader
        text="Loading report"
        isLoaded={true}
      >
        <Box>
          <Box m={3}>
            <Link to="/connections">
              <Text
                color="blue.500"
                as="u"
              >
                Back to connections
              </Text>
            </Link>
            <Center>
              <Box m={3}>
                <Heading fontSize={'3xl'}>
                  {connection
                    ? `${connection?.userName}'s ${helpers.getSocialName(connection?.platform)} Report`
                    : `Report`}
                </Heading>
              </Box>
            </Center>

            <Loader
              text="Loading report"
              isLoaded={!loadingConnection && connection}
            ></Loader>
            {error && (
              <Center>
                <Text
                  mt="20px"
                  color="red.500"
                >
                  {error}
                </Text>
              </Center>
            )}

            {!loadingConnection && connection && connection.stats.length > 0 && (
              <>
                <Center>
                  <Card
                    width={'500px'}
                    p={3}
                    m={30}
                  >
                    <HStack>
                      <Box>
                        <Avatar
                          mx="5"
                          size="xl"
                          src={connection?.thumbnailUrl}
                        />
                      </Box>
                      <Box>
                        <Table
                          variant="simple"
                          width="100%"
                        >
                          <Tbody>
                            <Tr>
                              <Th p={2}>Username</Th>
                              <Td p={2}>{connection?.userName}</Td>
                            </Tr>
                            <Tr>
                              <Th p={2}>Url</Th>
                              <Td p={2}>
                                <Link to={connection?.profileUrl}>
                                  <Text
                                    color="blue.500"
                                    as="u"
                                    fontSize="xs"
                                  >
                                    {connection?.profileUrl.replace('https://', '').replace('http://', '')}
                                  </Text>
                                </Link>
                              </Td>
                            </Tr>
                            {latestFollowers > 0 && (
                              <Tr>
                                <Th p={2}>Followers</Th>
                                <Td p={2}>{helpers.formatNumber(latestFollowers)}</Td>
                              </Tr>
                            )}
                            {connection?.stats[0].subscribers > 0 && (
                              <Tr>
                                <Th p={2}>Subscribers</Th>
                                <Td p={2}>
                                  {Number(
                                    connection?.stats[connection?.stats?.length - 1].subscribers
                                  ).toLocaleString()}
                                </Td>
                              </Tr>
                            )}
                            {connection?.stats[0]?.posts > 0 && (
                              <Tr>
                                <Th p={2}>Posts</Th>
                                <Td p={2}>
                                  {Number(connection?.stats[connection?.stats?.length - 1].posts || 0).toLocaleString()}
                                </Td>
                              </Tr>
                            )}
                            {connection?.stats[0].views > 0 && (
                              <Tr>
                                <Th p={2}>Views</Th>
                                <Td p={2}>
                                  {Number(connection?.stats[connection?.stats.length - 1].views).toLocaleString()}
                                </Td>
                              </Tr>
                            )}
                            {connection?.stats[0].likes > 0 && (
                              <Tr>
                                <Th p={2}>Likes</Th>
                                <Td p={2}>
                                  {Number(connection?.stats[connection?.stats.length - 1].likes).toLocaleString()}
                                </Td>
                              </Tr>
                            )}
                            {Object.keys(connection?.insights).length > 0 && (
                              <Tr>
                                <Th p={2}>Audience</Th>
                                <Td p={2}>
                                  {connection.insights?.gender_percent?.rows.length > 0 &&
                                    connection.insights?.gender_percent?.rows?.sort((a, b) => a.value < b.value)[0]
                                      .name}

                                  {connection.insights?.age_percent?.rows.length > 0 &&
                                    ', ' +
                                      connection.insights?.age_percent?.rows?.sort((a, b) => a.value < b.value)[0].name}

                                  {connection.insights?.country_views?.rows.length > 0 &&
                                    ' from ' +
                                      connection.insights?.country_views?.rows?.sort((a, b) => a.value < b.value)[0]
                                        .name}

                                  {connection.insights?.country_followers?.rows.length > 0 &&
                                    ' from ' +
                                      connection.insights?.country_followers?.rows?.sort((a, b) => a.value < b.value)[0]
                                        .name}
                                </Td>
                              </Tr>
                            )}
                          </Tbody>
                        </Table>
                      </Box>
                    </HStack>
                    <Center my="3">
                      <Text
                        fontSize="xs"
                        color="gray.500"
                      >
                        This data was updated on {helpers.formatDate(connection.updatedAt)}
                      </Text>
                    </Center>
                  </Card>
                </Center>

                <Heading fontSize="2xl">Stats</Heading>
                <SimpleGrid
                  minChildWidth="400px"
                  spacing="4"
                  mb="2"
                >
                  {followersData.length > 1 && (
                    <WrapItem>
                      <LineChartCard
                        icon={faUserGroup}
                        label="Followers"
                        labels={followersLabels}
                        dataSet={followersData}
                      />
                    </WrapItem>
                  )}
                  {subscribersData.length > 1 && (
                    <WrapItem>
                      <LineChartCard
                        icon={faUserGroup}
                        label="Subscribers"
                        labels={subscribersLabels}
                        dataSet={subscribersData}
                      />
                    </WrapItem>
                  )}
                  {viewsData.length > 1 && (
                    <WrapItem>
                      <LineChartCard
                        label={connection?.platform === 'kick' ? 'Average Viewers' : 'Views'}
                        labels={viewsLabels}
                        dataSet={viewsData}
                      />
                    </WrapItem>
                  )}
                  {postsData.length > 1 && (
                    <WrapItem>
                      <LineChartCard
                        icon={faBell}
                        label="Posts"
                        labels={postsLabels}
                        dataSet={postsData}
                      />
                    </WrapItem>
                  )}
                  {likesData.length > 1 && (
                    <WrapItem>
                      <LineChartCard
                        icon={faHeart}
                        label="Likes"
                        labels={likesLabels}
                        dataSet={likesData}
                      />
                    </WrapItem>
                  )}
                </SimpleGrid>

                <SimpleGrid
                  minChildWidth="300px"
                  spacing="4"
                >
                  {connection.insights.reach && (
                    <WrapItem>
                      <StatCard
                        panel={{
                          label: 'impressions_28'
                        }}
                        connection={connection}
                      />
                    </WrapItem>
                  )}
                  {connection.insights.reach && (
                    <WrapItem>
                      <StatCard
                        panel={{
                          label: 'reach_28'
                        }}
                        connection={connection}
                      />
                    </WrapItem>
                  )}
                </SimpleGrid>

                {Object.keys(connection?.insights).length > 0 && (
                  <>
                    <Divider my={'40px'} />
                    <Heading fontSize="2xl">Insights</Heading>
                    <SimpleGrid
                      minChildWidth="300px"
                      spacing="4"
                    >
                      {['age_dist', 'gender_dist', 'country_dist'].map((label) => {
                        return (
                          <WrapItem>
                            <ChartCard
                              panel={{
                                label: label
                              }}
                              connection={connection}
                            />
                          </WrapItem>
                        );
                      })}
                    </SimpleGrid>
                  </>
                )}

                {connection?.content.length > 0 && (
                  <>
                    <Divider my={'40px'} />
                    <Heading fontSize="2xl">Latest Posts</Heading>
                    <Wrap
                      p="3"
                      spacing="4"
                    >
                      {connection?.content
                        .sort((a, b) => new Date(a.createdAt).getTime() < new Date(b.createdAt).getTime())
                        .map((content, idx) => {
                          const contentData = connection;
                          return (
                            <WrapItem width="220px">
                              <ContentCard
                                key={`${content._id}-${idx}`}
                                avatar={contentData?.thumbnailUrl || ''}
                                username={contentData?.userName || ''}
                                platform={contentData?.platform}
                                content={content}
                              />
                            </WrapItem>
                          );
                        })}
                    </Wrap>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Loader>
    </DashboardLayout>
  );
};

export default Connections;
