import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  Button,
  Text,
  SimpleGrid,
  Heading,
  Stack
} from '@chakra-ui/react';
import { faPaintBrush } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChangeColors = (props) => {
  const colors = ['gray', 'pink', 'red', 'orange', 'yellow', 'green', 'blue', 'purple'];

  return (
    <Popover>
      <PopoverTrigger>
        <Button
          background="blue.300"
          p="1"
          py="2"
          px="3"
          mt="3"
          boxShadow="base"
          borderRadius="xl"
          bg="gray.100"
          color="blue.800"
        >
          <Text
            mx="1"
            as="span"
          >
            Colors
          </Text>
          <Box
            mx="1"
            as="span"
          >
            <FontAwesomeIcon icon={faPaintBrush} />
          </Box>
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <Box m="3">
            <Heading
              fontSize="lg"
              mb="3"
            >
              Primary:
            </Heading>
            <SimpleGrid
              columns={4}
              spacing={3}
            >
              {colors.map((color) => {
                return (
                  <Button
                    onClick={() => {
                      props.setHasChanged(true);
                      props.setNewPrimaryColor(color);
                    }}
                    px="5px"
                    py="20px"
                    width="fit-content"
                    _hover={{
                      background: color + '.200'
                    }}
                    borderRadius="xl"
                    bg={(color === props.newPrimaryColor ? color : 'grey') + '.100'}
                  >
                    <Box
                      borderRadius="lg"
                      bg={`${color}.200`}
                      width="25px"
                      height="25px"
                    ></Box>
                  </Button>
                );
              })}
            </SimpleGrid>
          </Box>
          <Box m="3">
            <Heading
              fontSize="lg"
              mb="3"
            >
              Secondary:
            </Heading>
            <SimpleGrid
              columns={4}
              spacing={3}
            >
              {colors.map((color) => {
                return (
                  <Button
                    onClick={() => {
                      props.setHasChanged(true);
                      props.setNewSecondaryColor(color);
                    }}
                    px="5px"
                    py="20px"
                    width="fit-content"
                    _hover={{
                      background: color + '.200'
                    }}
                    borderRadius="xl"
                    bg={(color === props.newSecondaryColor ? color : 'grey') + '.100'}
                  >
                    <Box
                      borderRadius="lg"
                      bg={`${color}.200`}
                      width="25px"
                      height="25px"
                    ></Box>
                  </Button>
                );
              })}
            </SimpleGrid>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ChangeColors;
