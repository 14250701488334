import { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyFacebookOauth } from '../../api/connection';
import VerifyOauth from '../../components/VerifyOauth';

const VerifyFacebook = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [verified, setVerified] = useState(false);
  const facebookCode = searchParams.get('code');

  useEffect(() => {
    if (facebookCode && !verified) {
      verifyFacebookOauth(facebookCode).then((facebookResults) => {
        setVerified(true);
        window.opener.postMessage(
          {
            data: facebookResults
          },
          '*'
        );
        window.close();
      });
    }

    if (!facebookCode) {
      navigate('/', { replace: true });
    }
  }, []);

  return <VerifyOauth title="Facebook Verification" />;
};

export default VerifyFacebook;
