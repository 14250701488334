import { useState, useEffect } from 'react';

import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Button,
  ModalFooter
} from '@chakra-ui/react';
import PlatformsContainer from '../containers/PlatformsContainer';
import { useAppContext } from '../hooks/useAppContext';

const MediaKitAddComponentModal = (props) => {
  const {
    isOpen = false,
    setIsOpen = () => {},
    onClose = () => {},
    title,
    onSubmit,
    selectedConnections,
    setSelectedConnections,
    disabledConnections = []
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { userAgencyConnections, setUserAgencyConnections } = useAppContext();

  const closeModal = () => {
    setIsOpen(false);
  };

  const submitNewConnections = async () => {
    setIsSubmitting(true);
    await onSubmit();
    setIsOpen(false);
    setIsSubmitting(false);
    setSelectedConnections([]);
  };

  const returnDisabledIds = () => {
    return Object.keys(disabledConnections)?.map((id) => id);
  };

  return (
    <Modal
      isCentered
      width="2xl"
      size="5xl"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title || ''}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {userAgencyConnections?.length && (
            <PlatformsContainer
              disabledConnections={returnDisabledIds()}
              view={'icons'}
              selectable={true}
              userConnections={userAgencyConnections}
              setUserConnections={setUserAgencyConnections}
              selectedConnections={selectedConnections}
              setSelectedConnections={setSelectedConnections}
              token={''}
            />
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            variant="outline"
            onClick={onClose}
          >
            Close
          </Button>
          {!!selectedConnections.length && (
            <Button
              isLoading={isSubmitting}
              colorScheme="blue"
              onClick={async () => await submitNewConnections()}
            >
              Add {selectedConnections.length} connection(s) to media kit
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MediaKitAddComponentModal;
